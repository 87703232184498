import texts from '../common/texts.json'
import constants from '../common/constants'

const setCSSVariables = (theme) => {
  for (const key in theme) {
    const value = theme[key]
    if (value) {
      document.documentElement.style.setProperty(`--${key}`, value)
    }
  }
}

export const setHeaderCampaignTheme = (campaignStyle) => {
  const headerConfig = campaignStyle?.active ? campaignStyle?.header : {}
  const headerMinimalConfig = campaignStyle?.active ? campaignStyle?.headerMinimal : {}
  const headerButton = headerConfig?.headerButton || {}
  const headerInfoConfig = headerConfig?.headerInfo || {}
  const headerMinimalButtons = headerMinimalConfig?.buttons || {}
  const headerTheme = {
    headerBackgroundColor: headerConfig?.backgroundColor,
    categoryMenuColor: headerConfig?.categoryMenu?.color,
    categoryMenuBgdColor: headerConfig?.categoryMenu?.backgroundColor,
    categoryMenuBgdHoverColor: headerConfig?.categoryMenu?.backgroundHoverColor,
    headerButtonColor: headerButton?.color,
    headerButtonBackgroundColor: headerButton?.backgroundColor,
    headerButtonBackgroundHoverColor: headerButton?.backgroundHoverColor,
    headerInfoColor: headerInfoConfig?.color,
    headerInfoBackgroundColor: headerInfoConfig?.backgroundColor,
    loginMenuColor: headerConfig?.loginMenu?.color,
    loginMenuBgdColor: headerConfig?.loginMenu?.backgroundColor,
    loginMenuBgdHoverColor: headerConfig?.loginMenu?.backgroundHoverColor,
    deliveryTypeMenuColor: headerConfig?.deliveryTypeMenu?.color,
    deliveryTypeMenuBgdColor: headerConfig?.deliveryTypeMenu?.backgroundColor,
    deliveryTypeMenuBgdHoverColor: headerConfig?.deliveryTypeMenu?.backgroundHoverColor,
    headerMinimalBackgroundColor: headerMinimalConfig?.backgroundColor,
    sodButtonColor: headerMinimalButtons?.sodButton?.color,
    sodButtonBackgroundColor: headerMinimalButtons?.sodButton?.backgroundColor,
    sodButtonBackgroundHoverColor: headerMinimalButtons?.sodButton?.backgroundHoverColor,
    catexButtonColor: headerMinimalButtons?.catexButton?.color,
    catexButtonBackgroundColor: headerMinimalButtons?.catexButton?.backgroundColor,
    catexButtonBackgroundHoverColor: headerMinimalButtons?.catexButton?.backgroundHoverColor,
  }

  setCSSVariables(headerTheme)
}

export const setFooterCampaignTheme = (campaignStyle) => {
  const footerConfig = campaignStyle?.active ? campaignStyle?.footer : {}
  const footerTheme = {
    footerColor: footerConfig?.color,
    footerBackgroundColor: footerConfig?.backgroundColor,
  }

  setCSSVariables(footerTheme)
}

export const setCampaignSeal = (campaignStyle) => {
  const footerConfig = campaignStyle?.active ? campaignStyle?.footer : {}
  const campaignSeal = {
    campaignSealDesktopWidth: footerConfig?.campaignSeal?.desktop?.width,
    campaignSealDesktopHeight: footerConfig?.campaignSeal?.desktop?.height,
    campaignSealMobileWidth: footerConfig?.campaignSeal?.mobile?.width,
    campaignSealMobileHeight: footerConfig?.campaignSeal?.mobile?.height,
  }

  setCSSVariables(campaignSeal)
}

export const getHeaderCampaignStyle = (campaignStyle) => {
  const headerConfig = campaignStyle?.active ? campaignStyle?.header : {}

  const menuText = headerConfig?.categoryMenu?.text || texts.HEADER.MENU_TEXT

  const headerButton = headerConfig?.headerButton || {}
  const headerButtonIcon = headerButton?.icon || '/catalogo/images/tvIconCatalogo.svg'

  const logoSOD = headerConfig?.logo?.sod || `${constants.STATIC_PATH}${'logo_lider_white_sod.svg'}`
  const logoCATEX = headerConfig?.logo?.catex || `${constants.STATIC_PATH}${'logo_mundo_lider.svg'}`
  const logoPDC = headerConfig?.logo?.walstore || `${constants.STATIC_PATH}${'logo_lider_walstore.svg'}`
  const logo404PDC = headerConfig?.logo?.walstore || '/catalogo/images/lider-logo.svg'

  return {
    menuText,
    headerButton,
    headerButtonIcon,
    logoSOD,
    logoCATEX,
    logoPDC,
    logo404PDC,
  }
}

export const getHeaderMinimalCampaignStyle = (campaignStyle) => {
  const headerMinimalConfig = campaignStyle?.active ? campaignStyle?.headerMinimal : {}
  const headerMinimalLogo = headerMinimalConfig?.logo || `${constants.STATIC_PATH}logo_lider_white.svg`

  return {
    headerMinimalLogo,
  }
}

export const getFooterCampaignSeal = (campaignStyle) => {
  const footerConfig = campaignStyle?.active ? campaignStyle?.footer : {}

  return {
    campaignSeal: footerConfig?.campaignSeal || {},
  }
}
