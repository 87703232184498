import React from 'react'
import { useCampaignStyleConfig } from '../../../contexts/CampaignStyleConfigContext'
import { getHeaderCampaignStyle } from '../../../Helpers/getCampaignStyleConfig'
import { Button } from '@walmart-web/livingdesign-components'
import GroceryIcon from '../components/GroceryIcon'
import getConfig from '../../../Helpers/getConfig'
import texts from '../../../common/texts.json'

const GroceryHeaderButton = () => {
  const { campaignStyle } = useCampaignStyleConfig()
  const { headerButton } = getHeaderCampaignStyle(campaignStyle)

  const handleGoToGrocery = () => {
    window.open(getConfig('liderGrocery'), '_blank')
  }

  return (
    <div>
      <Button
        data-testid="gorecery-header-button"
        size="large"
        variant="primary"
        leading={<GroceryIcon size="medium" />}
        onClick={handleGoToGrocery}
      >
        {headerButton?.text || texts.STATIC_PAGES.SOD_DESKTOP}
      </Button>
    </div>
  )
}

export default GroceryHeaderButton
