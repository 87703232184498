/* eslint-disable no-case-declarations */
import {
  ADD_TO_CART,
  REMOVE_ONE_FROM_CART,
  DELETE_FROM_CART,
  ADD_ONE_TO_CART,
  SET_CART,
  SET_SHOPPING_CART,
  REMOVE_ALL_FROM_CART,
  INCREASE_PROMOENGINE_RETRIES,
  RESET_PROMOENGINE_RETRIES,
  SET_IS_LOADING_PRICES,
} from '../types'

const initState = {
  shoppingCart: [],
  promoEngineRetries: 0,
  isLoadingPrices: false,
}

const sortShoppingCart = (a, b) => {
  if (!a.promotion?.isCombinable && !b.promotion?.isCombinable) {
    return 0
  }
  if (a.promotion?.isCombinable && !b.promotion?.isCombinable) {
    return -1
  }
  if (!a.promotion?.isCombinable && b.promotion?.isCombinable) {
    return 1
  }
  return parseInt(a.promotion?.id, 16) - parseInt(b.promotion?.id, 16)
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_CART:
      return Object.assign({}, state, { shoppingCart: action.payload.cart.sort(sortShoppingCart) })

    case ADD_TO_CART:
      let found = false
      const newAddToCartArray = state.shoppingCart.map((product) => {
        const newProduct = { ...product }
        if (
          newProduct.ID === action.payload.item.ID &&
          (newProduct.winningOffer
            ? newProduct.winningOffer.sellerId === action.payload.item.winningOffer.sellerId
            : true)
        ) {
          found = true
          newProduct.quantity += 1
          return newProduct
        }
        return newProduct
      })
      if (!found) {
        newAddToCartArray.push(action.payload.item)
      }

      newAddToCartArray.sort(sortShoppingCart)
      localStorage.setItem(state.shoppingCartName, JSON.stringify(newAddToCartArray))
      return Object.assign({}, state, { shoppingCart: newAddToCartArray })

    case ADD_ONE_TO_CART:
      const newAddOneToCartArray = state.shoppingCart.map((item) => {
        let product = { ...item }
        if (
          product.ID === action.payload.item.ID &&
          (product.winningOffer ? product.winningOffer.sellerId === action.payload.item.winningOffer.sellerId : true)
        ) {
          product = action.payload.item
        }
        return product
      })
      localStorage.setItem(state.shoppingCartName, JSON.stringify(newAddOneToCartArray))
      return Object.assign({}, state, { shoppingCart: newAddOneToCartArray })

    case REMOVE_ONE_FROM_CART:
      const newRemoveOneFromCartArray = state.shoppingCart.map((item) => {
        let product = { ...item }
        if (
          product.ID === action.payload.item.ID &&
          (product.winningOffer ? product.winningOffer.sellerId === action.payload.item.winningOffer.sellerId : true)
        ) {
          product = action.payload.item
        }
        return product
      })
      localStorage.setItem(state.shoppingCartName, JSON.stringify(newRemoveOneFromCartArray))
      return Object.assign({}, state, { shoppingCart: newRemoveOneFromCartArray })

    case DELETE_FROM_CART:
      const newDeleteFromCartArray = state.shoppingCart.filter(
        (product) =>
          !(
            product.ID === action.payload.item.ID &&
            (product.winningOffer ? product.winningOffer.sellerId === action.payload.item.winningOffer.sellerId : true)
          )
      )
      localStorage.setItem(state.shoppingCartName, JSON.stringify(newDeleteFromCartArray))
      return Object.assign({}, state, { shoppingCart: newDeleteFromCartArray })

    case REMOVE_ALL_FROM_CART:
      const emptyCart = []
      localStorage.setItem(state.shoppingCartName, JSON.stringify(emptyCart))
      return Object.assign({}, state, { shoppingCart: emptyCart })

    case SET_SHOPPING_CART:
      let shoppingCartName = 'shoppingCart'
      const { tenant } = action.payload
      if (tenant === 'supermercado') {
        shoppingCartName = `${tenant}_${shoppingCartName}`
      }
      return { ...state, shoppingCartName }

    case INCREASE_PROMOENGINE_RETRIES:
      return Object.assign({}, state, { promoEngineRetries: (state.promoEngineRetries || 0) + 1 })

    case RESET_PROMOENGINE_RETRIES:
      return Object.assign({}, state, { promoEngineRetries: 0 })

    case SET_IS_LOADING_PRICES:
      return Object.assign({}, state, { isLoadingPrices: action.payload.isLoadingPrices })
    default:
      return state
  }
}
