import React from 'react'

import LoadingLayout from '../functional-components/LoadingLayout'
import LoadingProductDetail from '../functional-components/LoadingProductDetail'

const ProductDetailFallback = () => {
  return (
    <LoadingLayout>
      <LoadingProductDetail />
    </LoadingLayout>
  )
}

export { ProductDetailFallback }
