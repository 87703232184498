import styled, { keyframes } from 'styled-components'

const entranceKeyframesDesktop = keyframes`
  0% {
    top: -30px;
  }
  100% {
    top: 0px;
  }
`

const exitKeyframesDesktop = keyframes`
  0% {
    top: 0px;
  }
  100% {
    top: -30px;
  }
`

const entranceKeyframesMobile = keyframes`
  0% {
    bottom: -50px;
  }
  100% {
    bottom: 0px;
  }
`

const exitKeyframesMobile = keyframes`
  0% {
    bottom: 0px;
  }
  100% {
    bottom: -50px;
  }
`
export const Alert = styled.div`
  position: fixed;
  top: 0;
  background: red;
  background: ${({ backgroundColor }) => backgroundColor};
  z-index: 6;
  width: 100%;
  text-align: center;
  animation: ${({ show }) => (show ? entranceKeyframesDesktop : exitKeyframesDesktop)} 300ms ease-out forwards;
  a {
    color: #5D92F4;
  }
  a:visited {
    color: #5D92F4;
  }
  a:hover {
    color: #5D92F4;
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: unset;
    bottom: 0;
    animation: ${({ show }) => (show ? entranceKeyframesMobile : exitKeyframesMobile)} 300ms ease-out forwards;
  }
`

export const CloseAlert = styled.i`
  float: right;
  cursor: pointer;
  align-self: center;
  font-size: 20px;
  padding-right: 5px;
   @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 5px;
  }
`

export const Content = styled.div`
  height: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  display:flex;
   @media (max-width: 768px) {
    text-align: start;
    padding: 10px;
    font-size: 11px;
    line-height: 15px;
    justify-content: space-around;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`
