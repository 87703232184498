import getConfig from '../../Helpers/getConfig'
import { getFlowId } from '../../Helpers'

const getPickupOrS2SStoresClient = async (tenant) => {
  const baseURLbff = getConfig('baseURLbff')
  try {
    const pickupStoresRes = await fetch(`${baseURLbff}stores/pickup-s2s`, {
      headers: { tenant, 'X-FlowId': getFlowId() },
    })

    const pickupStores = await pickupStoresRes.json()
    return pickupStores
  } catch (_error) {
    return []
  }
}

export default getPickupOrS2SStoresClient
