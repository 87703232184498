import React from 'react'
import PropTypes from 'prop-types'
import { useIsTenant } from '../../../../../contexts/TenantContext'
import constants from '../../../../../common/constants'
import {
  SecondLevelHeader,
  SecondLevelHeaderIcon,
  SecondLevelHeaderLink,
  SecondLevelHeaderTitle, SecondLevelMobileHeader,
  SecondLevelSection,
} from '../../../styled'
import ThirdLevelCategories from '../ThirdLevelCategories'
import './index.css'

const configData = window.__ENV__ || { storageBaseUrl: '' }

const SecondLevelCategories = ({ ...props }) => {
  const {
    currentCategory, activeCategory2, activeCategory3, isMobile, hideCategories, handleClose, 
  } = props

  const isTenantSod = useIsTenant(constants.tenant.SOD)

  const selectedBackgroundColor = currentCategory.indicatorColor || '#FFF'
  const selectedTextColor = selectedBackgroundColor !== '#FFF' ? '#FFF' : '#0071ce'
  const applyFilter = selectedBackgroundColor !== '#FFF'

  const shadowColor = applyFilter ? currentCategory.indicatorColor : '#cdcdcd'

  const icon = currentCategory.icon || `${configData.storageBaseUrl}/menuIcons/categoria_default.svg`

  if (currentCategory && currentCategory.categoriesLevel2 && currentCategory.categoriesLevel2.length > 0) {
    return (
      <SecondLevelSection
        selectedBackgroundColor={selectedBackgroundColor}
      >
        {!isMobile && (
          <SecondLevelHeader>
            <SecondLevelHeaderIcon applyFilter={applyFilter} alt="second-level-icon" src={icon} />
            <SecondLevelHeaderTitle selectedTextColor={selectedTextColor}>{currentCategory.label}</SecondLevelHeaderTitle>
            { !isTenantSod &&
              <SecondLevelHeaderLink data-testid="second-level-header-link-test-renderer" shadowColor={shadowColor} href={`/catalogo/category/${currentCategory.label.replace(/ /g, '_')}`}>ver todo</SecondLevelHeaderLink>
            }
          </SecondLevelHeader>
        )}
        {isMobile && (
          <div className="second-level-mobile-header-container">
            <SecondLevelMobileHeader
              onClick={hideCategories}
              onKeyPress={hideCategories}
            >
              <SecondLevelHeaderIcon applyFilter={applyFilter} alt="second-level-icon" src={`${configData.storageBaseUrl}/menuIcons/atras_flecha.svg`} />
              <SecondLevelHeaderTitle selectedTextColor={selectedTextColor}>
                {currentCategory.label}
              </SecondLevelHeaderTitle>
              { !isTenantSod &&
                  <SecondLevelHeaderLink data-testid="second-level-header-link-test-renderer" shadowColor={shadowColor} href={`/catalogo/category/${currentCategory.label.replace(/ /g, '_')}`}>ver todo</SecondLevelHeaderLink>
              }
            </SecondLevelMobileHeader>
            <div>
              <SecondLevelHeaderLink shadowColor={shadowColor} href={`/catalogo/category/${currentCategory.label.replace(/ /g, '_')}`}>ver todo</SecondLevelHeaderLink>
            </div>
          </div>
        )}
        <ThirdLevelCategories activeCategory2={activeCategory2} activeCategory3={activeCategory3} currentCategory={currentCategory} handleClose={handleClose} />
      </SecondLevelSection>
    )
  }
  return null
}

export default SecondLevelCategories

SecondLevelCategories.defaultProps = {
  activeCategory2: '',
  activeCategory3: ''
}

SecondLevelCategories.propTypes = {
  activeCategory2: PropTypes.string,
  activeCategory3: PropTypes.string,
  currentCategory: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  hideCategories: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}
