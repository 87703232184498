import React from 'react'

const CustomSearchIcon = () => {
  return (
    <svg
      data-testid="customSearchIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <rect x="0.414642" width="28" height="28" rx="14" fill="#FFC220" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9146 7C15.9522 7 18.4146 9.46243 18.4146 12.5C18.4146 13.8388 17.9363 15.0659 17.1412 16.0196L21.2682 20.1464C21.4635 20.3417 21.4635 20.6583 21.2682 20.8536C21.088 21.0338 20.8043 21.0477 20.6082 20.8951L20.5611 20.8536L16.4343 16.7266C15.4805 17.5217 14.2534 18 12.9146 18C9.87708 18 7.41464 15.5376 7.41464 12.5C7.41464 9.46243 9.87708 7 12.9146 7ZM12.9146 8C10.4294 8 8.41464 10.0147 8.41464 12.5C8.41464 14.9853 10.4294 17 12.9146 17C15.3999 17 17.4146 14.9853 17.4146 12.5C17.4146 10.0147 15.3999 8 12.9146 8Z"
        fill="#74767C"
      />
    </svg>
  )
}

export default CustomSearchIcon
