import { SET_CATEGORIES_MENU } from '../types'

const initState = {
  links: [],
  categories: [],
}
export default (state = initState, action) => {
  switch (action.type) {
    case SET_CATEGORIES_MENU:
      return {
        ...state,
        ...action.payload.menu,
      }
    default:
      return state
  }
}
