import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner, Alert } from '@walmart-web/livingdesign-components'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'
import { useDeliveryContext } from '../context/DeliveryContext'
import { useLateralPanelContext } from '../../LateralPanel/context/LateralPanelContext'
import getHDStoreFromCoordinatesClient from '../../../common/clients/getHDStoreFromCoordinatesClient'
import DeliveryAddressItem from './DeliveryAddressItem'
import { useTenant } from '../../../contexts/TenantContext'
import { makeAddressObject } from './addressFormatter'
import texts from '../../../common/texts.json'
import './DeliveryAddresses.css'

const DeliveryAddresses = ({ deliveryAddresses: remoteCustomerAddresses, onSaveStore }) => {
  const { saveStore } = useShoppingCartContext()
  const { saveAddress, saveHomeDelivery, address } = useDeliveryContext()
  const [selectedAddress, setSelectedAddress] = useState(address)
  const [loading, setLoading] = useState(false)
  const [errorGettingStoreId, setErrorGettingStoreId] = useState(false)
  const { tenant } = useTenant()
  const { showDeliveryAddressForm } = useLateralPanelContext()

  const getHomeDeliveryStoreInfo = async () => {
    const { latitude, longitude, location } = selectedAddress
    const coordinates = location ? { latitude: location.lat, longitude: location.lng } : { latitude, longitude }
    const homeDeliveryStore = await getHDStoreFromCoordinatesClient(coordinates, tenant)
    if (homeDeliveryStore.message) {
      setErrorGettingStoreId(true)
      return undefined
    }
    return homeDeliveryStore
  }

  const makeAddressObjectAndSave = (homeDeliveryStore) => {
    const addressObj = makeAddressObject(
      {
        street: selectedAddress.street,
        regionName: selectedAddress.commune.region.name,
        provinceName: selectedAddress.commune.name,
        communeName: selectedAddress.commune.name,
        location: {
          lat: selectedAddress.latitude ? selectedAddress.latitude : 0,
          lng: selectedAddress.longitude ? selectedAddress.longitude : 0,
        },
      },
      selectedAddress.number,
      homeDeliveryStore
    )

    addressObj.apartment = selectedAddress.info ? selectedAddress.info : ''
    addressObj.comments = selectedAddress.comments ? selectedAddress.comments : ''
    addressObj.id = selectedAddress.id
    saveAddress(addressObj)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const homeDeliveryStore = await getHomeDeliveryStoreInfo()
    if (homeDeliveryStore) {
      if (selectedAddress.streetInput) {
        saveAddress(selectedAddress)
      } else {
        makeAddressObjectAndSave(homeDeliveryStore)
      }
      saveStore({ id: homeDeliveryStore.storeId })
      onSaveStore()
      saveHomeDelivery()
    }
    setLoading(false)
  }

  const isButtonDisabled = () => !selectedAddress || !selectedAddress.id

  return (
    <React.Fragment>
      {errorGettingStoreId && (
        <Alert className="delivery-form__alert" actionButtonProps={{}} variant="error">
          {texts.DELIVERY_FORM.ERROR_IN_SELECTED_ADDRESS}
        </Alert>
      )}
      <Button className="delivery-address__link" onClick={showDeliveryAddressForm}>
        {texts.DELIVERY_FORM.ADD_NEW_ADDRESS}
      </Button>
      <div className="delivery-address-container">
        <form data-testid="delivery-address-container__form" onSubmit={onSubmit}>
          {remoteCustomerAddresses.map((address, index, arr) => (
            <DeliveryAddressItem
              key={address.id}
              address={address}
              showDivider={!(arr.length - 1 === index)}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
            />
          ))}
          <div className="delivery-address-container__form__sticky-division">{texts.COMMON.SPACE}</div>
          <div className="delivery-address-container__form__footer">
            <div className="delivery-address-container__form__submit-button">
              <Button
                className="delivery-address-container__form__submit-button__saveAddress"
                disabled={isButtonDisabled()}
                type="submit"
                isFullWidth
                size="medium"
                variant="primary"
              >
                {loading ? (
                  <Spinner className="delivery-address-container__form__spinner" />
                ) : (
                  <span>{texts.COMMON.SAVE}</span>
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

DeliveryAddresses.propTypes = {
  deliveryAddresses: PropTypes.array.isRequired,
  onSaveStore: PropTypes.func.isRequired,
}

export default DeliveryAddresses
