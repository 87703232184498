import React from 'react'

const CartIcon = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03531 12.9436L8.42687 9.23295H26.0918C26.3349 9.23295 26.5651 9.33961 26.7211 9.52628C26.882 9.71072 26.9469 9.95561 26.9078 10.1943L25.5495 18.4694C25.4798 18.8729 25.1371 19.1658 24.7291 19.1658H10.0575L9.03531 12.9436ZM25.3411 24.9712C25.3411 25.7565 24.7029 26.3969 23.9175 26.3969C23.1318 26.3969 22.494 25.7565 22.494 24.9712C22.494 24.1854 23.1318 23.5454 23.9175 23.5454C24.7029 23.5454 25.3411 24.1854 25.3411 24.9712ZM12.8713 24.9712C12.8713 25.7565 12.2335 26.3969 11.4478 26.3969C10.6624 26.3969 10.0242 25.7565 10.0242 24.9712C10.0242 24.1854 10.6624 23.5454 11.4478 23.5454C12.2335 23.5454 12.8713 24.1854 12.8713 24.9712ZM26.8646 18.6885L28.2273 10.4094C28.3273 9.78228 28.1491 9.14628 27.7371 8.66228C27.3246 8.17828 26.7255 7.9005 26.0918 7.9005H8.20642L7.80198 5.43961C7.74553 5.11872 7.46776 4.88184 7.14242 4.88184H4.6682C4.29931 4.88184 3.99976 5.17917 3.99976 5.54806C3.99976 5.91695 4.29931 6.21428 4.6682 6.21428H6.57798L6.98242 8.67384C6.98242 8.67428 6.98153 8.67517 6.98153 8.67517L8.83087 19.9405C8.83131 19.9436 8.83442 19.9445 8.83442 19.9472L9.33398 22.9876C9.34464 23.0476 9.39487 23.0836 9.41976 23.1369C8.97664 23.6263 8.68731 24.2605 8.68731 24.9712C8.68731 26.4921 9.92864 27.7294 11.4478 27.7294C12.9669 27.7294 14.2082 26.4921 14.2082 24.9712C14.2082 24.4378 14.0158 23.9676 13.7522 23.5454H21.6135C21.35 23.9676 21.1571 24.4378 21.1571 24.9712C21.1571 26.4921 22.3984 27.7294 23.9175 27.7294C25.4366 27.7294 26.678 26.4921 26.678 24.9712C26.678 23.4498 25.4366 22.2125 23.9175 22.2125H10.558L10.2766 20.4983H24.7291C25.7882 20.4983 26.6864 19.7387 26.8646 18.6885Z"
        fill="white"
      />
    </svg>
  )
}

export default CartIcon
