import { SET_WINDOW_SIZE } from '../types'

const initState = {
  windowSize: {
    width: 0,
    height: 0,
  },
}
export default (state = initState, action) => {
  switch (action.type) {
    case SET_WINDOW_SIZE:
      return { ...state, windowSize: action.payload.windowSize }
    default:
      return state
  }
}
