import React from 'react'
import PropTypes from 'prop-types'
import ServiceInfoLink from './ServiceInfoLink'
import constants from '../../../common/constants'
import './HeaderInfoCATEX.css'

const HeaderInfoCATEX = ({ showCustomerServiceInfo }) => {
  const {
    INFO_HEADER_CATEX: { TEXTS, LINKS },
  } = constants
  const { SELL_WITH_US, BCI_LIDER_CARD, HELP_CENTER } = TEXTS
  const { SELL_WITH_US_LINK, BCI_LIDER_CARD_LINK, HELP_CENTER_LINK } = LINKS

  return (
    <>
      {showCustomerServiceInfo && (
        <div id="header" className="header-info" data-testid="customer-service-info-component">
          <div className="header-info__wrapper header-info__expanded">
            <div className="service-link-container">
              <ServiceInfoLink text={SELL_WITH_US} link={SELL_WITH_US_LINK} />
              <ServiceInfoLink text={BCI_LIDER_CARD} link={BCI_LIDER_CARD_LINK} />
              <ServiceInfoLink text={HELP_CENTER} link={HELP_CENTER_LINK} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

HeaderInfoCATEX.propTypes = {
  showCustomerServiceInfo: PropTypes.bool,
}

HeaderInfoCATEX.defaultProps = {
  showCustomerServiceInfo: true,
}

export default HeaderInfoCATEX
