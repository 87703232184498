import { SET_PICKUP_STORE } from '../types'

export default (pickupStore) => (dispatch) => {
  dispatch({
    type: SET_PICKUP_STORE,
    payload: {
      pickupStore,
    },
  })
}
