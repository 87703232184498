import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import windowSizeReducer from './reducers/windowSize'
import shoppingCartReducer from './reducers/shoppingCart'
import isCartOpenReducer from './reducers/isCartOpen'
import supermarketItemsReducer from './reducers/supermarketItems'
import campaignConfigReducer from './reducers/campaignConfig'
import lastCategoryDataReducer from './reducers/lastCategoryData'
import mondayDiscountReducer from './reducers/mondayDiscount'
import pickupStore from './reducers/pickupStore'
import categoryPageConfig from './reducers/categoryPageConfig'
import homePageConfig from './reducers/homePageConfig'
import categoriesMenu from './reducers/categoriesMenu'
import selectedWalstoreStore from './reducers/selectedWalstoreStore'
import promotionsReducer from './reducers/promotionsReducer'
import advertisingDisplay from './reducers/advertisingDisplay'
import isRedirectToCheckoutReducer from './reducers/isRedirectToCheckout'

export const reducer = combineReducers({
  shoppingCartReducer,
  windowSizeReducer,
  isCartOpenReducer,
  supermarketItemsReducer,
  campaignConfigReducer,
  lastCategoryDataReducer,
  mondayDiscountReducer,
  pickupStore,
  categoryPageConfig,
  homePageConfig,
  categoriesMenu,
  selectedWalstoreStore,
  isRedirectToCheckoutReducer,
  promotionsReducer,
  advertisingDisplay,
})

const middleware = applyMiddleware(thunk)
const store = createStore(reducer, middleware)
export default store
