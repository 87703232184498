import axios from 'axios'
import getConfig from '../../Helpers/getConfig'
import { getFlowId } from '../../Helpers'

const getDiscountClient = async (tenant) => {
  const baseURLbff = getConfig('baseURLbff')
  const TIMEOUT = 4000
  const clientInstance = axios.create({
    baseURL: baseURLbff,
    timeout: TIMEOUT,
    headers: {
      tenant,
      'X-FlowId': getFlowId(),
    },
  })

  try {
    const response = await clientInstance.request({
      url: 'discounts',
      method: 'get',
    })
    return response.data
  } catch (_error) {
    return {}
  }
}

export default getDiscountClient
