import React from 'react'
import PropTypes from 'prop-types'

import StoreSelectorWidget, { STRIP_TYPE } from '../../../StoreSelector/components/StoreSelectorWidget'

import './index.css'

const FullfillmentDetail = ({ onClick }) => (
  <div role="presentation" className="fullfillment-detail__container">
    <StoreSelectorWidget onClick={onClick} widgetType={STRIP_TYPE} />
  </div>
)

FullfillmentDetail.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default FullfillmentDetail
