import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Divider, Spinner } from '@walmart-web/livingdesign-components'
import { useTenant, useIsTenant } from '../../../../contexts/TenantContext'
import { priceEnhancer } from '../../../../Helpers'
import getDiscountClient from '../../../../common/clients/getDiscountClient'
import { getSubTotal, getLiderCardTotal, getTLMCSubTotal } from './Utils'
import texts from '../../../../common/texts.json'
import constants from '../../../../common/constants'
import './index.css'

const Summary = ({ productList, onClick, isButtonGoToPayDisabled, isButtonGoToPayLoading }) => {
  const [discount, setDiscount] = useState({})
  const promotionsData = useSelector((state) => state.promotionsReducer.data)
  const isLoadingPrices = useSelector((state) => state.shoppingCartReducer.isLoadingPrices)
  const isPromotionEngineEnabled = useSelector((s) => s.campaignConfigReducer.isPromotionEngineEnabled)
  const displayFreeDeliveryInfo = useSelector((s) => s.campaignConfigReducer.displayFreeDeliveryInfo)
  const freeDeliveryThreshold = useSelector((s) => s.campaignConfigReducer.freeDeliveryThreshold)

  const { tenant } = useTenant()
  const isSOD = useIsTenant(constants.tenant.SOD)

  const subTotal = isPromotionEngineEnabled ? promotionsData.total : getSubTotal(productList, isSOD)

  const getTotalTLMC = () => {
    const subTotalTLMC = isSOD ? subTotal : getTLMCSubTotal(productList)
    return isPromotionEngineEnabled ? promotionsData.totalLiderBCI : getLiderCardTotal(subTotalTLMC, discount)
  }

  const getFreeDeliveryDiff = () => {
    return freeDeliveryThreshold - subTotal
  }

  useEffect(() => {
    const setDiscountWrapper = async () => {
      setDiscount(await getDiscountClient(tenant))
    }
    setDiscountWrapper()
  }, [])

  return (
    <div className="summary">
      <div className="summary__divider">
        <Divider />
      </div>
      <div className="summary__subtotal">
        <div className="summary__subtotal-label">{texts.COMMON.SUBTOTAL}</div>
        {isLoadingPrices ? (
          <Spinner className="summary__loading-prices-subtotal__spinner" />
        ) : (
          <div className="summary__subtotal-value">{priceEnhancer(subTotal)}</div>
        )}
      </div>
      <div className="summary__tarjeta-lider">
        <div className="summary__tarjeta-lider-label">{texts.SHOPPING_CART.SUBTOTAL_TARJETA_LIDER}</div>
        {isLoadingPrices ? (
          <Spinner className="summary__loading-prices-liderbci__spinner" />
        ) : (
          <div className="summary__tarjeta-lider-value">{priceEnhancer(getTotalTLMC())}</div>
        )}
      </div>
      {displayFreeDeliveryInfo && !isLoadingPrices && (
        <>
          {getFreeDeliveryDiff() > 0 ? (
            <>
              <div className="delivery__label">
                ¡Te faltan <b className="blue">{priceEnhancer(getFreeDeliveryDiff())}</b> para conseguir despacho
                gratis!
              </div>
            </>
          ) : (
            <span id="delivery-completed__label">¡Conseguiste tu despacho gratis en esta Compra!</span>
          )}
        </>
      )}
      <div className="summary__continue-button">
        <Button
          disabled={isButtonGoToPayDisabled || isButtonGoToPayLoading || isLoadingPrices}
          isFullWidth
          variant="primary"
          size="large"
          onClick={onClick}
        >
          {isButtonGoToPayLoading || isLoadingPrices ? (
            <Spinner className="summary__continue-button__spinner" />
          ) : (
            <span>{texts.SHOPPING_CART.GO_TO_PAY}</span>
          )}
        </Button>
      </div>
    </div>
  )
}

Summary.propTypes = {
  productList: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  isButtonGoToPayDisabled: PropTypes.bool,
  isButtonGoToPayLoading: PropTypes.bool,
}

export default Summary
