import React from 'react'
import { Button } from '@walmart-web/livingdesign-components'
import PropTypes from 'prop-types'

import texts from '../../../../common/texts.json'

import './index.css'

const EmptyCart = ({ onClick }) => (
  <div className="empty-cart">
    <img
      className="empty-cart__img"
      alt={texts.SHOPPING_CART.ALT_WALMART_CART}
      src="/catalogo/images/shoppingCart/cart.svg"
    />
    <span className="empty-cart__info">{texts.SHOPPING_CART.EMPTY_CART}</span>
    <span className="empty-cart__suggestion">{texts.SHOPPING_CART.SUGGESTION}</span>
    <div className="empty-cart__button">
      <Button variant="primary" size="medium" onClick={onClick}>
        {texts.SHOPPING_CART.SEARCH_PRODUCTS}
      </Button>
    </div>
  </div>
)

EmptyCart.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EmptyCart
