import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@walmart-web/livingdesign-components'
import { Location } from '@walmart-web/livingdesign-icons'
import './index.css'

const PickupChip = ({ children, showPickup, ...rest }) => (
  <Chip
    UNSAFE_className={showPickup ? 'pickup-chip--enabled' : 'pickup-chip--disabled'}
    size="large"
    variant="primary"
    leading={<Location />}
    {...rest}
  >
    {children}
  </Chip>
)

PickupChip.propTypes = {
  children: PropTypes.node.isRequired,
  showPickup: PropTypes.bool.isRequired,
}

export default PickupChip
