import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@walmart-web/livingdesign-components'
import texts from '../../../common/texts.json'
import LazyLoadImageContainer from '../../../components/LazyLoadImageContainer'
import AlternativeProductPrices from './AlternativeProductPrices'
import { productUrl } from '../../../Helpers'
import { useTenant } from '../../../contexts/TenantContext'

const ProductOutStock = ({ item, isMobile }) => {
  const { sku, displayName, brand, images } = item
  const { tenant } = useTenant()
  const itemUrl = productUrl(tenant, item)

  return (
    <div className="alternative-item__container alternative-item__sold_out">
      <div className="alternative-item__item">
        <div className="alternative-item__image">
          <Link href={itemUrl}>
            <LazyLoadImageContainer
              id="lazy-img"
              className="img-fluid m-auto"
              alt={`${sku} - ${displayName}`}
              sku={sku}
              hitImage={images?.mediumImage}
            />
          </Link>
        </div>
        <div className={`alternative-item__description--${isMobile ? 'mobile' : 'desktop'}`}>
          <a className="alternative-item__description-text" href={itemUrl}>
            <strong>{brand}</strong>, {displayName}
          </a>
          <AlternativeProductPrices item={item} />
        </div>
        <div className="alternative-item-button__container">
          <span className="alternative-item__sold_out_button text-center">{texts.PRODUCT_DETAIL.SOLD_OUT}</span>
        </div>
      </div>
    </div>
  )
}

ProductOutStock.propTypes = {
  item: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
}

export default ProductOutStock
