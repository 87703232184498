import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

const LoadingSkeleton = ({ type = 'default', spaceBottom }) => (
  <div className={`loading-skeleton loading-skeleton--${type}${spaceBottom ? ' loading-skeleton--space-bottom' : ''}`} />
)

LoadingSkeleton.defaultProps = {
  type: 'default',
  spaceBottom: false,
}

LoadingSkeleton.propTypes = {
  type: PropTypes.string,
  spaceBottom: PropTypes.bool,
}

export default LoadingSkeleton
