import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert, CloseAlert, Content } from './styled'
import { TenantContext } from '../../contexts/TenantContext'
import constants from '../../common/constants'

class AlertInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAlert: null,
    }
    const { COOKIE_NAME, COOKIE_VALUE, COOKIE_EXPIRATION_HOURS } = constants.CONTINGENCY_ALERT
    this.COOKIE_NAME = COOKIE_NAME
    this.COOKIE_VALUE = COOKIE_VALUE
    this.COOKIE_EXPIRATION_HOURS = COOKIE_EXPIRATION_HOURS
  }

  createAlertInfoStyleSod() {
    const { showAlert } = this.state
    const { tenant } = this.context
    const isSod = tenant === constants.tenant.SOD
    if (showAlert && isSod) {
      document.documentElement.style.setProperty('--topheaderDesktop', '30px')
      document.documentElement.style.setProperty('--topAppContainerProductDetail', '109px')
      document.documentElement.style.setProperty('--topAppContainerProductDetailLoader', '92px')
      document.documentElement.style.setProperty('--topAppContainerHome', '30px')
    }
  }

  componentDidMount() {
    this.setState({
      showAlert: document.cookie.includes(`${this.COOKIE_NAME}=${this.COOKIE_VALUE}`) ? null : true,
    })
  }

  componentDidUpdate() {
    this.createAlertInfoStyleSod()
  }

  removeAlertInfoStyle() {
    document.documentElement.style.setProperty('--topheaderDesktop', '0')
    document.documentElement.style.setProperty('--topAppContainerProductDetail', '78px')
    document.documentElement.style.setProperty('--topAppContainerProductDetailLoader', '62px')
    document.documentElement.style.setProperty('--topAppContainerHome', '0')
  }

  closeHandler = () => {
    const expireDate = new Date()
    expireDate.setHours(expireDate.getHours() + this.COOKIE_EXPIRATION_HOURS)
    document.cookie = `${this.COOKIE_NAME}=${this.COOKIE_VALUE} ; expires = ${expireDate.toUTCString()}`
    this.setState({ showAlert: false }, () =>
      setTimeout(() => {
        this.setState({ showAlert: null })
      }, 500)
    )
    this.removeAlertInfoStyle()
  }

  render() {
    const { showAlert } = this.state
    const { children, backgroundColor } = this.props
    if (showAlert === null) {
      return null
    }
    return (
      <Alert show={showAlert} backgroundColor={backgroundColor}>
        <Content>
          {children}
          <CloseAlert data-testid="zmdi-close" className="zmdi zmdi-close" onClick={this.closeHandler} />
        </Content>
      </Alert>
    )
  }
}

AlertInfo.contextType = TenantContext

export default AlertInfo

AlertInfo.defaultProps = {
  children: null,
  backgroundColor: '#fff9c9',
}

AlertInfo.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
}
