import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import Header from '../../components/Header'
import RenderLoader from '../Home/components/RenderLoader'
import { useInView } from 'react-intersection-observer'
const LazyFooter = lazy(() => import('../../components/Footer'))

const CommonLayout = (props) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 })

  return (
    <>
      <Header {...props}></Header>
      {props.children}
      <Suspense fallback={<RenderLoader />}>
        <div ref={ref}> {inView && <LazyFooter {...props} />} </div>
      </Suspense>
    </>
  )
}

CommonLayout.propTypes = {
  children: PropTypes.array.isRequired,
}

export default CommonLayout
