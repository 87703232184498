import { createLogger, INFO } from 'browser-bunyan'
import { ServerStream } from '@browser-bunyan/server-stream'
import getConfig from '../Helpers/getConfig'

const loggerPromise = async () => {
  const { logger = false } = typeof window.__ENV__ !== 'undefined' ? window.__ENV__ : {}
  const baseURLbff = getConfig('baseURLbff')

  if (!logger) return Promise.resolve({ info: () => {}, error: () => {} })

  return createLogger({
    name: 'buysmart-landing',
    streams: [
      {
        level: INFO,
        stream: new ServerStream({
          url: `${baseURLbff}logs`, // baseURLbff includes the slash
          method: 'POST',
        }),
      },
    ],
  })
}

export default loggerPromise
