export const getDiscount = (normalPrice, actualPrice) => {
  if (normalPrice > 0 && actualPrice > 0) {
    return 100 - Math.round((actualPrice * 100) / normalPrice)
  }
  return 0
}

export const getItemSalePriceDiscount = (price) => {
  const normalPrice = Number(price.BasePriceReference)
  const actualPrice = Number(price.BasePriceSales)
  return getDiscount(normalPrice, actualPrice)
}

export const getItemSaleDiscount = (product) => getItemSalePriceDiscount(product.price)

export const getTotalItemPrice = ({ price, quantity }, isSod = false) => {
  if (isSod) {
    const packPrice = price.packPrice && !Number.isNaN(price.packPrice) ? Number(price.packPrice) : 0
    const packSize = price.packSize && !Number.isNaN(price.packSize) ? Number(price.packSize) : 0
    const isPack = packPrice > 0 && packSize > 0
    const addedPacks = isPack ? Math.floor(quantity / packSize) : 0
    const addedIndividualItems = isPack ? quantity % packSize : quantity
    const totalPacks = addedPacks * packPrice
    const totalIndividualItems = addedIndividualItems * price.BasePriceSales
    return totalPacks + totalIndividualItems
  }
  return quantity * price.BasePriceSales
}
