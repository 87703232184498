import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import useWindowSize from '../../hooks/useWindowSize'
import { useIsTenant } from '../../contexts/TenantContext'
import getConfig from '../../Helpers/getConfig'

import getCategoriesMenu from '../../common/clients/getCategoriesMenuClient'
import CategoriesMenu from '../../components/CategoriesMenu'
import CategoriesSlider from '../../components/CategoriesSlider'
import DesktopHeader from './components/DesktopHeader'
import MobileHeader from './components/MobileHeader'

import LateralPanel from '../LateralPanel'

import DeliveryContextProvider from '../StoreSelector/context/DeliveryContext'
import HeaderContextProvider from './context/HeaderContext'
import constants from '../../common/constants'

const DisplayHeader = ({
  categoriesMenu,
  setCategoriesMenu,
  location,
  history,
  campaignConfig,
  selectedWalstoreStore,
}) => {
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [categoryMenuSpinner, setCategoryMenuSpinner] = useState(false)

  const { width } = useWindowSize()
  const isDesktop = width > 895
  const { links, categories } = categoriesMenu
  const { headerAlertMessageConfig = {}, categoriesSliderMenuHeader = [] } = campaignConfig
  const windowSize = { width }
  const {
    tenant: { SOD },
  } = constants
  const isSod = useIsTenant(SOD)
  const shouldRenderCategoriesSlider = !isSod && categoriesSliderMenuHeader.length > 0

  const handleCloseSideMenu = () => setShowSideMenu(false)
  const handleToogleSideMenu = async () => {
    if (!categories || categories.length === 0) {
      setCategoryMenuSpinner(true)
      const categoriesMenuResponse = await getCategoriesMenu()
      setCategoriesMenu(categoriesMenuResponse)
      setCategoryMenuSpinner(false)
    }
    setShowSideMenu(!showSideMenu)
  }

  const headerProps = {
    onMenuButtonToggle: handleToogleSideMenu,
    onMenuClose: handleCloseSideMenu,
    isSod,
    isWalstore: getConfig('isWalstore') === 'true',
    walStoreValue: selectedWalstoreStore.value,
    categoryMenuSpinner,
  }

  const headerContent = () => {
    return (
      <>
        <HeaderContextProvider>
          {isDesktop ? (
            <DesktopHeader headerAlertMessageConfig={headerAlertMessageConfig} {...headerProps} />
          ) : (
            <MobileHeader isMenuOpen={showSideMenu} {...headerProps} />
          )}
        </HeaderContextProvider>
        <LateralPanel history={history} />
      </>
    )
  }

  return (
    <Fragment>
      {isSod ? <DeliveryContextProvider>{headerContent()}</DeliveryContextProvider> : headerContent()}
      <CategoriesMenu
        links={links}
        categories={categories}
        location={location}
        visible={showSideMenu}
        windowSize={windowSize}
        handleClose={handleCloseSideMenu}
      />
      {shouldRenderCategoriesSlider && <CategoriesSlider location={location} categories={categoriesSliderMenuHeader} />}
    </Fragment>
  )
}

DisplayHeader.propTypes = {
  categoriesMenu: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  campaignConfig: PropTypes.object,
  selectedWalstoreStore: PropTypes.object,
  setCategoriesMenu: PropTypes.func,
}

DisplayHeader.defaultProps = {
  categoriesMenu: { links: [], categories: [] },
  history: {},
  campaignConfig: {},
  selectedWalstoreStore: {
    value: '',
  },
}

export default DisplayHeader
