import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@walmart-web/livingdesign-components'
import { Truck } from '@walmart-web/livingdesign-icons'
import './index.css'

const DeliveryChip = ({ children, showDelivery, ...rest }) => (
  <Chip
    UNSAFE_className={showDelivery ? 'delivery-chip--enabled' : 'delivery-chip--disabled'}
    size="large"
    variant="primary"
    leading={<Truck />}
    {...rest}
  >
    {children}
  </Chip>
)

DeliveryChip.propTypes = {
  children: PropTypes.node.isRequired,
  showDelivery: PropTypes.bool.isRequired,
}

export default DeliveryChip
