import constants from '../common/constants'
import { DateTime } from 'luxon'
import { EVENTS, FLOW_TYPES } from '../data-tagging/constants'
import { logEventToDataCollection } from '../data-tagging'

export const getCompleteAccountEvaluate = () => {
  return isNaN(window.__ENV__?.completeAccountEvaluation) ? 0 : window.__ENV__.completeAccountEvaluation
}

export const getCompleteAccountDummyList = () => {
  return (
    window.__ENV__?.completeAccountDummyList
      ?.toUpperCase?.()
      .split?.(',')
      .map((item) => item.trim?.()) || []
  )
}

export const fixBirthDate = (date) => {
  if (typeof date !== 'string' || date?.trim() == 0) {
    return ''
  }

  const result = DateTime.fromFormat(date, 'dd-MM-yyyy')
  return result.isValid ? result.toFormat('yyyy-MM-dd') : ''
}

export const shouldCheckAccountComplete = () => {
  const evaluate = getCompleteAccountEvaluate()

  return !(
    evaluate <= constants.COMPLETE_ACCOUNT_OPTIONS.CHECK_SKIP || evaluate > constants.COMPLETE_ACCOUNT_OPTIONS.CHECK_ALL
  )
}

export const processFields = (customer) => {
  const dummyList = getCompleteAccountDummyList()

  const firstName = customer?.firstName?.trim() || ''
  const lastName = customer?.lastName?.trim() || ''
  const motherSurname = customer?.motherSurname?.trim() || ''
  const phoneNumber = customer?.phoneNumber?.trim() || ''
  const birthDate = customer?.birthDate?.trim() || ''

  const fields = {
    firstName: dummyList.includes(firstName?.toUpperCase()) ? '' : firstName,
    lastName: dummyList.includes(lastName?.toUpperCase()) ? '' : lastName,
    motherSurname,
    phoneNumber,
    birthDate: fixBirthDate(birthDate),
    genderId: isNaN(customer?.genderId) ? null : customer?.genderId,
  }

  if (Object.values(fields).some((item) => item === '' || item === null)) {
    return Object.assign({ ...customer }, fields)
  }

  return null
}

export const processCompleteAccount = (customer) => {
  if (typeof customer !== 'object') {
    return null
  }

  const evaluate = getCompleteAccountEvaluate()

  if (
    [constants.COMPLETE_ACCOUNT_OPTIONS.CHECK_REQUIRED, constants.COMPLETE_ACCOUNT_OPTIONS.CHECK_ALL].includes(evaluate)
  ) {
    return processFields(customer)
  }

  return null
}

export const checkIfCustomer = (data) => {
  const requiredFields = ['walmartId', 'rut', 'email']
  const inputFields = Object.keys(data || {})

  return requiredFields.every((item) => inputFields.includes(item))
}

export const goToLandingOrCompleteAccount = (history, customer, origin) => {
  if (!shouldCheckAccountComplete()) {
    history.push('/')
    return
  }

  const output = processCompleteAccount(customer)
  if (output !== null) {
    tagEvent(origin, EVENTS.COMPLETE_ACCOUNT.INVOKE, { email: customer?.email })
    history.replace('/completa-tu-cuenta', output)
    return
  }

  history.push('/')
}

export const tagEvent = (origin, eventName, data) => {
  logEventToDataCollection({
    origin: origin,
    flowType: FLOW_TYPES.LOGIN,
    eventName,
    data,
  })
}
