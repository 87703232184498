import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'
import PropTypes from 'prop-types'
import AutoComplete from './Autocomplete'
import ClientAPI from '../../../common/ClientAPI'
import { getTenant, getConfigData, cleanSpecialCharsfromQuery } from '../../../Helpers'
import useWindowSize from '../../../hooks/useWindowSize'
import { Close as CloseIcon } from '@walmart-web/livingdesign-icons'
import CustomSearchIcon from '../components/SearchIcon'
import { getPlaceHolder, searchLink } from './utils'

const clientAPI = new ClientAPI()

const DebouncedSearchBox = ({ closeSearch, delay, supermarketItems }) => {
  const [hasResponseFromSuggestionService, setHasResponseFromSuggestionService] = useState(false)
  const [value, setValue] = useState('')
  const [overlay, setOverlay] = useState(false)
  const [timerId, setTimerId] = useState(null)
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false)
  const [foundProductsInSearch, setFoundProductsInSearch] = useState(false)
  const [showResultsInGlobalSearch, setShowResultsInGlobalSearch] = useState(false)
  const tenant = getTenant()

  const initialSuggestionsState = { products: [], categories: [], suggestions: [] }
  const [debouncedState, setDebouncedState] = useState(initialSuggestionsState)

  const { isMobile } = useWindowSize()

  const history = useHistory()

  const handleSearchClick = () => {
    setShowResultsInGlobalSearch(true)
    setOverlay(true)
  }

  const dontShowResultsInGlobalSearch = () => setShowResultsInGlobalSearch(false)

  DebouncedSearchBox.handleClickOutside = () => {
    if (showResultsInGlobalSearch) {
      dontShowResultsInGlobalSearch()
    }
    setOverlay(false)
    closeSearch()
  }

  const getSearchSugggestions = async (searchValue) => {
    if (searchValue) {
      const { products, categories, suggestions } = await clientAPI.getSearchSuggestions({ query: searchValue })
      setHasResponseFromSuggestionService(true)
      setFoundProductsInSearch(Array.isArray(products) && products.length > 0)
      setDebouncedState({ products, categories, suggestions })
    }
    setShowLoadingIndicator(false)
  }

  const debouncedSearch = (searchValue) => {
    clearTimeout(timerId)
    setTimerId(setTimeout(() => getSearchSugggestions(searchValue), delay))
  }

  const handleOnChange = ({ currentTarget: { value: searchValue } }) => {
    setValue(searchValue)
    setDebouncedState(initialSuggestionsState)
    setShowLoadingIndicator(true)
    debouncedSearch(searchValue)
  }

  const clearSearchBox = () => {
    dontShowResultsInGlobalSearch()
    setValue('')
    setDebouncedState(initialSuggestionsState)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const hasGroceryItems = supermarketItems.some((item) => value.toLowerCase().includes(item.toLowerCase()))
    const cleanedSpecialCharsValue = cleanSpecialCharsfromQuery(value)
    const valueUriParam = encodeURIComponent(cleanedSpecialCharsValue)
    if (tenant === 'catalogo' && !foundProductsInSearch && hasGroceryItems && hasResponseFromSuggestionService) {
      window.open(`${getConfigData().liderGrocery}/search?Ntt=${valueUriParam}&ost=${valueUriParam}`, '_blank')
    } else {
      history.push(searchLink(cleanedSpecialCharsValue))
      clearSearchBox()
    }
  }

  return (
    <div className="ais-InstantSearch__root">
      {overlay && (
        <div role="presentation" className="search-overlay" onClick={DebouncedSearchBox.handleClickOutside} />
      )}
      <div className="ais-SearchBox">
        <form noValidate="" className="ais-SearchBox-form" role="search" onSubmit={(event) => handleSubmit(event)}>
          <input
            type="search"
            placeholder={getPlaceHolder(tenant)}
            autoFocus={isMobile || overlay}
            maxLength={512}
            className="ais-SearchBox-input"
            value={value}
            onChange={(event) => handleOnChange(event)}
            onFocus={() => handleSearchClick()}
          />
          <button type="submit" title="Submit your search query." className="ais-SearchBox-submit search-input-icons">
            <CustomSearchIcon />
          </button>
          <button
            type="button"
            title="Clear the search query."
            className="ais-SearchBox-reset search-input-icons"
            hidden={showLoadingIndicator || !value}
            onClick={() => clearSearchBox()}
          >
            <CloseIcon size="medium" />
          </button>
          <span className="ais-SearchBox-loadingIndicator" hidden={!showLoadingIndicator}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 38 38"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#444"
              className="ais-SearchBox-loadingIcon"
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                  <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(215.328 18 18)">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g>
            </svg>
          </span>
          <AutoComplete
            inputValue={value}
            products={debouncedState.products}
            categories={debouncedState.categories}
            suggestions={debouncedState.suggestions}
            handleClickOutside={DebouncedSearchBox.handleClickOutside}
            showResultsInGlobalSearch={showResultsInGlobalSearch}
            dontShowResultsInGlobalSearch={dontShowResultsInGlobalSearch}
          />
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  supermarketItems: state.supermarketItemsReducer.supermarketItems,
})

DebouncedSearchBox.defaultProps = {
  supermarketItems: [],
  delay: 0,
  closeSearch: () => null,
}

DebouncedSearchBox.propTypes = {
  supermarketItems: PropTypes.array,
  delay: PropTypes.number,
  closeSearch: PropTypes.func,
}

const clickOutsideConfig = {
  handleClickOutside: () => DebouncedSearchBox.handleClickOutside,
}

export default connect(mapStateToProps)(onClickOutside(DebouncedSearchBox, clickOutsideConfig))
