import { getConfigData } from './index'
import constants from '../common/constants'

const getCookie = (name) => document.cookie.split(';').some((c) => c.trim().startsWith(`${name}=`))

const createSessionCookie = (response) => {
  const { isCookieSecure } = getConfigData()
  const jwt = response.token
  const expiresAt = new Date(response.expiration_at * 1000).toUTCString()
  document.cookie = `${constants.BS_SOD_SESSION_TOKEN}=${jwt}; path=/; expires=${expiresAt}; ${
    isCookieSecure ? `secure=true` : ``
  }`
}

const getSODSessionToken = () => {
  const match = document.cookie.match(new RegExp(`(^| )${constants.BS_SOD_SESSION_TOKEN}=([^;]+)`))

  if (match) {
    return match[2]
  }

  return null
}

const deleteSODSessionToken = () => {
  if (getCookie(constants.BS_SOD_SESSION_TOKEN)) {
    const expiresAt = new Date(+0)
    document.cookie = `${constants.BS_SOD_SESSION_TOKEN}= ; path=/; expires=${expiresAt};`
    document.cookie = `${constants.BS_SOD_SESSION_TOKEN}.sig= ; path=/; expires=${expiresAt};`
  }
}

export { createSessionCookie, getSODSessionToken, deleteSODSessionToken }
