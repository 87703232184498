import React from 'react'
import PropTypes from 'prop-types'
import texts from '../../../../../../common/texts.json'
import './index.css'

function PromotionLabel({ isCombinable, isPack, isExclusivoInternet }) {
  if (!isCombinable && !isPack && !isExclusivoInternet) {
    return null
  }

  const msg = isCombinable ? texts.PROMOTION_LABELS.COMBINABLE : texts.PROMOTION_LABELS.PACK
  const spanClass = isCombinable ? 'tag-module_tag tag-module_secondary' : 'tag-module_tag tag-module_primary'

  return (
    <div className="promotion-label">
      {(isCombinable || isPack) && (
        <span className={spanClass}>
          <strong>{msg}</strong>
        </span>
      )}
      {!isCombinable && isExclusivoInternet && (
        <span className={'tag-module_tag tag-module_secondary'}>{texts.PROMOTION_LABELS.EXCLUSIVO_INTERNET}</span>
      )}
    </div>
  )
}

PromotionLabel.propTypes = {
  isCombinable: PropTypes.bool.isRequired,
  isPack: PropTypes.bool.isRequired,
  isExclusivoInternet: PropTypes.bool.isRequired,
}

export default PromotionLabel
