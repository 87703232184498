import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@walmart-web/livingdesign-components'

import './index.css'

const HeaderButton = ({ variant, size, onClick, headingText, bodyText, ...rest }) => {
  return (
    <div className="header-button">
      <Button className="header-button__button" variant={variant} size={size} onClick={onClick} {...rest}>
        <div className="header-button__text-container">
          <span className="header-button__heading-text">{headingText}</span>
          {bodyText && <span className="header-button__body-text">{bodyText}</span>}
        </div>
      </Button>
    </div>
  )
}

HeaderButton.defaultProps = {
  variant: 'primary',
  size: 'large',
}

HeaderButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  bodyText: PropTypes.string,
  headingText: PropTypes.string.isRequired,
}

export default HeaderButton
