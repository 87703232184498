import { SET_LAST_CATEGORIES, SET_LAST_PAGE, SET_LAST_SKU, SET_LAST_BREADCRUMBS } from '../types'

const initState = {
  lastCategoryData: {
    categories: [],
    lastPage: 1,
    skuClicked: undefined,
    breadcrumbs: undefined,
  },
}

export default (state = initState.lastCategoryData, action) => {
  switch (action.type) {
    case SET_LAST_CATEGORIES:
      return Object.assign({}, state, { categories: action.payload.categories, lastPage: 1, skuClicked: undefined })
    case SET_LAST_PAGE:
      return Object.assign({}, state, { lastPage: action.payload.lastPage, skuClicked: undefined })
    case SET_LAST_SKU:
      return Object.assign({}, state, { skuClicked: action.payload.skuClicked })
    case SET_LAST_BREADCRUMBS:
      return Object.assign({}, state, { breadcrumbs: action.payload.breadcrumbs })
    default:
      return state
  }
}
