import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextField, Button } from '@walmart-web/livingdesign-components'
import { Location as LocationIcon } from '@walmart-web/livingdesign-icons'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'
import { useLateralPanelContext } from '../../LateralPanel/context/LateralPanelContext'
import { CONSTANTS } from '../../LateralPanel/constants'
import texts from '../../../common/texts.json'

const AddressInput = ({ selectProps }) => {
  const {
    addressInputRef,
    addressLineOneValue,
    error,
    setMenuOpen,
    setAddressLineOneValue,
    onInputChange,
    onMenuOpen,
  } = selectProps
  const { activeComponent, setStoreSelectorTabPickup, openStoreSelector } = useLateralPanelContext()
  const { isAddedAddressNumber, setIsAddedAddressNumber } = useShoppingCartContext()

  const handleOpenStoreSelectorTabPickup = () => {
    setStoreSelectorTabPickup()

    if (activeComponent === CONSTANTS.deliveryAddressForm) {
      openStoreSelector()
    }
  }

  const handleAddressOnChange = (value) => {
    setMenuOpen(true)
    onInputChange(value)
    setAddressLineOneValue(value)
    onMenuOpen(true)
  }

  useEffect(() => {
    addressInputRef.current.focus()
  }, [])

  useEffect(() => {
    if (isAddedAddressNumber) {
      handleAddressOnChange(addressLineOneValue)
      setIsAddedAddressNumber(false)
    }
  }, [isAddedAddressNumber])

  return (
    <>
      <label className="delivery-form__input-label">{texts.DELIVERY_FORM.FULL_ADDRESS}</label>
      <TextField
        data-testid="address-input"
        textFieldProps={{ name: 'oneLineAddress', autoComplete: 'off' }}
        placeholder={texts.DELIVERY_FORM.FULL_ADDRESS_EXAMPLE}
        type="text"
        defaultValue=""
        error={
          error === texts.DELIVERY_FORM.REQUEST_FAILED_WITH_STATUS_CODE_404 ||
          error === texts.DELIVERY_FORM.NO_STORES_FOUND_FOR_THE_SUPPLIED_COMMUNA ? (
            <span data-testid="address-input-error-message">
              {error}
              <Button
                className="delivery-form__pickup-button"
                size="small"
                variant="tertiary"
                onClick={handleOpenStoreSelectorTabPickup}
              >
                {texts.DELIVERY_FORM.STORE_SELECTOR_PICKUP}
              </Button>
            </span>
          ) : (
            error
          )
        }
        value={addressLineOneValue}
        leadingIcon={<LocationIcon />}
        onChange={(ev) => {
          handleAddressOnChange(ev.target.value)
        }}
        ref={addressInputRef}
      />
    </>
  )
}

AddressInput.defaultProps = {
  selectProps: {},
  setMenuOpen: () => {},
  setAddressLineOneValue: () => {},
  addressLineOneValue: '',
  error: '',
}

AddressInput.propTypes = {
  selectProps: PropTypes.object,
  setMenuOpen: PropTypes.func,
  setAddressLineOneValue: PropTypes.func,
  addressLineOneValue: PropTypes.string,
  error: PropTypes.string,
  addressInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  onChange: PropTypes.func,
}

export default AddressInput
