import React from 'react'
import PropTypes from 'prop-types'
import { Link, Button, useSnackbar } from '@walmart-web/livingdesign-components'
import LazyLoadImageContainer from '../../../components/LazyLoadImageContainer'
import WlmPrice from '../../ProductPrices/components/WlmPrice'
import RegularUnitPrice from '../../ProductPrices/components/RegularUnitPrice'
import Price from '../../ProductPrices/components/Price'
import useShoppingCart from '../../ShoppingCart/hooks/useShoppingCart'
import { getItemSaleDiscount } from '../../ShoppingCart/price.utils'
import { getItemSavingAmount, isSod, productUrl } from '../../../Helpers'
import { useTenant } from '../../../contexts/TenantContext'
import textTemplate from '../../../common/textTemplate'
import texts from '../../../common/texts.json'
import getPromotionItemsEvaluate from '../../../common/clients/promotionEngineClient'
import './RecommendationItem.css'
import SavingsLabel from '../../../functional-components/SavingsLabel'

const RecommendationItem = ({ index, item, soldOutItem, isMobile, onCloseSubstitutionView }) => {
  const { addSnack } = useSnackbar()
  const { tenant } = useTenant()
  const { sku, displayName, brand, price, maxAvailable, max, images } = item
  const { quantity: soldOutItemQuantity } = soldOutItem
  const availableQuantity = max < maxAvailable ? max : maxAvailable
  const packSize = price.packSize || 0
  const isPack = packSize > 0
  const isSavingPrice = price.BasePriceReference > price.BasePriceSales
  const itemPrice = isPack ? price.packPrice : price.BasePriceSales
  const itemDiscount = isPack ? 0 : getItemSaleDiscount(item)
  const { handleReplaceSelectedItem } = useShoppingCart()
  const showSodSavingsLabel = itemDiscount > 0 && isSod()

  const isEnoughAvailableQuantity = () => availableQuantity && availableQuantity < soldOutItemQuantity

  const displayItemAvailability = () => {
    let availibiltyText = textTemplate(texts.SOLD_OUT_PRODUCTS.UNITS_AVAILABLE, { units: soldOutItemQuantity })
    let className = 'available'

    if (isEnoughAvailableQuantity()) {
      const availabilityText =
        availableQuantity === max
          ? texts.SOLD_OUT_PRODUCTS.UNITS_LEFT_BY_PURCHASE_LIMIT
          : texts.SOLD_OUT_PRODUCTS.UNITS_LEFT
      availibiltyText = textTemplate(availabilityText, { units: availableQuantity })
      className = 'left'
    }

    return <span className={`recommendation-item__units-${className}`}>{availibiltyText}</span>
  }

  const displayRegularPrice = () => {
    if (isPack) {
      return <RegularUnitPrice price={price.BasePriceSales} location="shopping-cart" />
    }
    return isSavingPrice ? (
      <div className="d-flex">
        <Price price={price.BasePriceReference} className="product-item__saving-price" />
      </div>
    ) : null
  }

  const handleReplaceItemFromCart = () => {
    const slodOutQuantity = isEnoughAvailableQuantity() ? availableQuantity : soldOutItemQuantity

    handleReplaceSelectedItem(soldOutItem, { ...item, quantity: slodOutQuantity }, index + 1)
    getPromotionItemsEvaluate()
    onCloseSubstitutionView()
    addSnack({
      message: texts.SOLD_OUT_PRODUCTS.PRODUCT_REPLACED,
    })
  }

  return (
    <div className="recommendation-item__container">
      <div className="recommendation-item__item">
        <div className="recommendation-item__image">
          <Link href={productUrl(tenant, item)}>
            <LazyLoadImageContainer
              id="lazy-img"
              className="img-fluid m-auto"
              alt={`${sku} - ${displayName}`}
              sku={sku}
              hitImage={images?.mediumImage}
            />
          </Link>
        </div>
        <div className={`recommendation-item__description-${isMobile ? 'mobile' : 'desktop'}`}>
          <a className="recommendation-item__description-text" href={productUrl(tenant, item)}>
            {`${brand}, ${displayName}`}
          </a>
          <WlmPrice
            price={itemPrice}
            packQuantity={packSize}
            discount={itemDiscount}
            priceClass="product-item__price-promo-description"
            discountClass="product-item__discount-percentage"
          >
            {showSodSavingsLabel > 0 && <SavingsLabel savings={getItemSavingAmount(price)} classNames="ml-2" />}
          </WlmPrice>
          {displayRegularPrice()}
        </div>
        <div className="recommendation-item__availability">
          {displayItemAvailability()}
          <Button
            variant="secondary"
            size="small"
            onClick={handleReplaceItemFromCart}
            className="recommendation-item__button-choose"
          >
            {texts.COMMON.CHOOSE}
          </Button>
        </div>
      </div>
    </div>
  )
}

RecommendationItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  soldOutItem: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onCloseSubstitutionView: PropTypes.func.isRequired,
}

export default RecommendationItem
