import React from 'react'
import { Radio, Divider } from '@walmart-web/livingdesign-components'
import PropTypes from 'prop-types'
import { useDeliveryContext } from '../context/DeliveryContext'
import texts from '../../../common/texts.json'
import './DeliveryAddressItem.css'

const DeliveryAddressItem = ({ address, showDivider, selectedAddress, setSelectedAddress }) => {
  const { addressesList: localAddresses } = useDeliveryContext()

  const formatAddress = (address) => {
    const label = `${address.street} ${address.number} ${address.info ? `depto ${address.info}` : ''}`.toLowerCase()
    return (
      <React.Fragment>
        <p className="delivery-address-item-form-name__text">{label}</p>
        <p className="delivery-address-item-form__text">
          {address.commune.name ? address.commune.name : ''}, {texts.DELIVERY_FORM.COUNTRY}
        </p>
      </React.Fragment>
    )
  }

  const showHurricaneAddress = () => (
    <React.Fragment>
      <Radio
        UNSAFE_className="delivery-address-item-form__radio"
        checked={selectedAddress && selectedAddress.id === address.id}
        label={formatAddress(address)}
        size="small"
        name={`srsId_${address.commune.srmsId}`}
        id={address.id}
        onChange={() => {
          setSelectedAddress(address)
        }}
      />
      {(showDivider || localAddresses) && <Divider />}
    </React.Fragment>
  )

  const showLocalAddress = () => (
    <React.Fragment>
      <Radio
        UNSAFE_className="delivery-address-item-form__radio"
        checked={selectedAddress && selectedAddress.id === address.id}
        label={formatAddress({
          street: address.streetInput,
          number: address.streetNumber,
          info: address.apartment,
          commune: { name: address.selectedCommune.communeName },
        })}
        size="small"
        name={`srsId_${address.selectedCommune.srsId}`}
        id={address.id}
        onChange={() => {
          setSelectedAddress(address)
        }}
      />
      {showDivider && <Divider />}
    </React.Fragment>
  )

  return address.formattedAddress ? showLocalAddress() : showHurricaneAddress()
}

DeliveryAddressItem.propTypes = {
  address: PropTypes.object.isRequired,
  showDivider: PropTypes.bool.isRequired,
  setSelectedAddress: PropTypes.func.isRequired,
}

export default DeliveryAddressItem
