/* eslint-disable react/no-danger */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { escapeRegexChars, searchLink, productSuggestionLink, categorySuggestionLink, grocerySearchLink } from './utils'

import textTemplate from '../../../common/textTemplate'
import texts from '../../../common/texts.json'

import './SearchSuggestions.css'

const ProductSuggestion = ({ product, inputValue, onClick, onClickLink }) => (
  <div role="presentation" className="search-suggestion__text" onClick={onClick} onKeyPress={onClick}>
    <Link className="search-suggestion__link p-10 w-100" to={productSuggestionLink(product.sku, inputValue)} onClick={onClickLink}>
      {`${product.brand} ${product.displayName}`}
    </Link>
  </div>
)
ProductSuggestion.defaultProps = {
  onClick: () => null,
  onClickLink: () => null,
}

ProductSuggestion.propTypes = {
  product: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onClickLink: PropTypes.func,
}

const CategorySuggestion = ({ category, inputValue, onClick, onClickLink }) => (
  <div role="presentation" className="search-suggestion__text" onClick={onClick} onKeyPress={onClick}>
    <Link className="search-suggestion__link p-10 w-100" to={categorySuggestionLink(category, inputValue)} onClick={onClickLink}>
      <strong>{inputValue}</strong>
      {textTemplate(texts.SEARCH_SUGGESTIONS.CATEGORY_TEXT, { category })}
    </Link>
  </div>
)

CategorySuggestion.defaultProps = {
  onClick: () => null,
  onClickLink: () => null,
}

CategorySuggestion.propTypes = {
  category: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onClickLink: PropTypes.func,
}

const SearchSuggestion = ({ inputValue, onClick }) => (
  <div role="presentation" className="search-word__text" onClick={onClick} onKeyPress={onClick}>
    <Link to={searchLink(inputValue)}>
      <i className="zmdi zmdi-search search-word__icon" />
      <span className="ml-5">
        {texts.SEARCH_SUGGESTIONS.SEARCH_TEXT}
        <strong>{inputValue}</strong>
      </span>
    </Link>
  </div>
)

SearchSuggestion.defaultProps = {
  onClick: () => null,
}

SearchSuggestion.propTypes = {
  inputValue: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

const SearchSuggestionGR = ({ inputValue, onClick, className }) => (
  <div role="presentation" className={className} onClick={onClick} onKeyPress={onClick}>
    <a target="_blank" rel="noopener noreferrer" href={grocerySearchLink(inputValue)}>
      <i className="zmdi zmdi-search search-word__icon" />
      <span className="ml-5">
        {texts.SEARCH_SUGGESTIONS.SEARCH_TEXT}
        <strong>{inputValue}</strong>
        {texts.SEARCH_SUGGESTIONS.GROCERY_TEXT}
      </span>
    </a>
  </div>
)

SearchSuggestionGR.defaultProps = {
  onClick: () => null,
  className: 'search-word__text',
}

SearchSuggestionGR.propTypes = {
  inputValue: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

const getSuggestionHtmlString = (searchTerm, suggestion) => {
  const htmlString = searchTerm
    ? suggestion.replace(
        new RegExp(escapeRegexChars(searchTerm), 'i'),
        (match) => `<span class="font-weight-normal">${match}</span>`
      )
    : `<span class="font-weight-normal">${suggestion}</span>`
  return DOMPurify.sanitize(htmlString)
}

const Suggestion = ({ name, inputValue, onClick, onClickLink }) => {
  const htmlString = getSuggestionHtmlString(inputValue, name)
  return (
    <div role="presentation" className="search-suggestion__text search-suggestion__text--md" onClick={onClick} onKeyPress={onClick}>
      <Link className="search-suggestion__link p-10 w-100" to={searchLink(name)} onClick={onClickLink}>
        <i className="zmdi zmdi-search search-word-icon" />
        <span className="ml-5 font-weight-bold" dangerouslySetInnerHTML={{ __html: htmlString }} />
      </Link>
    </div>
  )
}

Suggestion.defaultProps = {
  onClick: () => null,
  onClickLink: () => null,
}

Suggestion.propTypes = {
  name: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onClickLink: PropTypes.func,
}

export { Suggestion, ProductSuggestion, CategorySuggestion, SearchSuggestion, SearchSuggestionGR }
