import React, { useState } from 'react'
import Loadable from '@loadable/component'
import { useIsTenant } from '../contexts/TenantContext'
import { shouldOpenPromoteLiderAppPopUp } from '../functional-components/PromoteLiderAppPopUp/utils'
import PromoteLiderAppPopUp from '../functional-components/PromoteLiderAppPopUp'
import PromotionalPopUp from '../functional-components/PromotionalPopUp'
import constants from '../common/constants'
import BannersSectionLoader from '../Pages/Home/components/BannersSection/BannersSectionLoader'
import SalesSectionLoader from '../Pages/Home/components/SalesSection/SalesSectionLoader'
import { disableLiderAppPopUpForHomeReplenishment } from '../Helpers'

const Home = (props) => {
  const isTenantSod = useIsTenant(constants.tenant.SOD)
  const isMobile = window.innerWidth <= 768
  const isDisableByUrl = disableLiderAppPopUpForHomeReplenishment(props.location.search)
  const [isPromoteLiderAppPopUpClosed, setIsPromoteLiderAppPopUpClosed] = useState(
    isTenantSod ? !shouldOpenPromoteLiderAppPopUp() : true
  )

  const HomeFallback = () => (
    <>
      <BannersSectionLoader />
      <SalesSectionLoader />
    </>
  )

  const HomeLoader = Loadable(() => import('../Pages/Home'), { fallback: <HomeFallback /> })

  return (
    <>
      {isMobile && isTenantSod && !isDisableByUrl && (
        <PromoteLiderAppPopUp setIsPromoteLiderAppPopUpClosed={setIsPromoteLiderAppPopUpClosed} />
      )}
      <PromotionalPopUp isPromoteLiderAppPopUpClosed={isPromoteLiderAppPopUpClosed} />
      <HomeLoader {...props} />
    </>
  )
}

export default Home
