import { SET_WINDOW_SIZE } from '../types'

export default (windowSize) => (dispatch) => {
  dispatch({
    type: SET_WINDOW_SIZE,
    payload: {
      windowSize,
    },
  })
}
