import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, useSnackbar } from '@walmart-web/livingdesign-components'
import texts from '../../common/texts.json'
import './index.css'
import { useShoppingCartContext } from '../ShoppingCart/context/ShoppingCartContext'
import useShoppingCart from '../ShoppingCart/hooks/useShoppingCart'
import SoldOutItem from './components/SoldOutItem'
import GenericPanelTitle from '../LateralPanel/components/GenericPanelTitle'
import urlToggleHelper from '../../Helpers/urlToggleHelper'
import getPromotionItemsEvaluate from '../../common/clients/promotionEngineClient'
import { Alert } from '@walmart-web/livingdesign-components'

export const SoldOutProductsPanelTitle = ({ onClickBack }) => (
  <GenericPanelTitle onClickBack={onClickBack}>{texts.SOLD_OUT_PRODUCTS.PANEL_TITLE}</GenericPanelTitle>
)

SoldOutProductsPanelTitle.propTypes = {
  onClickBack: PropTypes.func.isRequired,
}

const SoldOutProducts = ({ onCleanSoldOut, showCart }) => {
  const { soldOutProducts, shoppingCartItems } = useShoppingCartContext()
  const { handleRemoveSoldOutUnits } = useShoppingCart()
  const { isOriginIntegrationEnable } = urlToggleHelper()
  const mustDiscardUnits = soldOutProducts.length < shoppingCartItems.length

  useEffect(() => {
    if (soldOutProducts.length > 0) return
    if (mustDiscardUnits) onCleanSoldOut()
    else showCart()
  }, [soldOutProducts])

  const displayDivider = (itemIndex) => (soldOutProducts.length > itemIndex + 1 ? <Divider /> : null)

  const handleClick = () => {
    handleRemoveSoldOutUnits()
    getPromotionItemsEvaluate()
  }

  return (
    <div className="sold-out-products__container">
      <Alert variant="warning">{texts.SOLD_OUT_PRODUCTS.TEXT}</Alert>
      <div className="sold-out-products__list">
        {soldOutProducts.map((item, i) => (
          <Fragment key={`sold-out-item-${item.sku}`}>
            <SoldOutItem item={item} isOriginIntegrationEnable={isOriginIntegrationEnable} />
            {displayDivider(i)}
          </Fragment>
        ))}
      </div>
      <div className="sold-out-products__blank-space" />
      <div className="sold-out-products__bottom">
        <Divider />
        {mustDiscardUnits ? (
          <div className="sold-out-products__button">
            <Button
              isFullWidth
              variant="primary"
              size="large"
              onClick={handleClick}
              data-testid="button-discart-sold-out"
            >
              <span>{texts.SOLD_OUT_PRODUCTS.DISCARD_UNITS}</span>
            </Button>
          </div>
        ) : (
          <div className="product-card-button__container walmart-add-cart-button-container">
            <Button
              isFullWidth
              variant="primary"
              size="large"
              onClick={showCart}
              data-testid="button-back-sold-out"
              class="back-to-cart__button"
            >
              <span>{texts.SOLD_OUT_PRODUCTS.BACK_TO_CART}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

SoldOutProducts.propTypes = {
  onCleanSoldOut: PropTypes.func.isRequired,
}

export default SoldOutProducts
