import React from 'react'

import useWindowSize from '../../../hooks/useWindowSize'
import { useLateralPanelContext } from '../context/LateralPanelContext'

import StoreSelectorWidget, { BUTTON_TYPE, STRIP_TYPE } from '../../StoreSelector/components/StoreSelectorWidget'

const StoreSelectorHeaderButton = () => {
  const { openStoreSelector } = useLateralPanelContext()
  const { width } = useWindowSize()
  const isMobile = width <= 895

  const getButtonType = () => (isMobile ? STRIP_TYPE : BUTTON_TYPE)

  return <StoreSelectorWidget onClick={openStoreSelector} widgetType={getButtonType()} />
}

export default StoreSelectorHeaderButton
