import axios from 'axios'
import getConfig from '../../Helpers/getConfig'
import constants from '../constants'
import { getTenant, getFlowId } from '../../Helpers'

const postLogin = async (username, password, captcha, loginEndPoint) => {
  const baseURLbff = getConfig('baseURLbff')
  const tenant = getTenant()
  const clientInstance = axios.create({
    baseURL: baseURLbff,
    timeout: constants.timeout.TWENTY_SECONDS,
  })

  try {
    const response = await clientInstance.request({
      withCredentials: true,
      url: loginEndPoint,
      method: 'post',
      data: {
        username,
        password,
        captcha,
      },
      headers: {
        tenant,
        'X-FlowId': getFlowId(),
      },
    })
    return response.data
  } catch (error) {
    return error.response ? error.response : error
  }
}

export default postLogin
