import React from 'react'
import PropTypes from 'prop-types'
import DebouncedSearchBox from './components/DebouncedSearchBox'

const GlobalSearch = ({ closeSearch }) => (
  <section>
    <DebouncedSearchBox closeSearch={closeSearch} delay={500} />
  </section>
)

GlobalSearch.defaultProps = {
  closeSearch: () => null,
}

GlobalSearch.propTypes = {
  closeSearch: PropTypes.func,
}

export default GlobalSearch
