import { SET_CATEGORIES_MENU } from '../types'

export default (menu) => (dispatch) => {
  dispatch({
    type: SET_CATEGORIES_MENU,
    payload: {
      menu,
    },
  })
}
