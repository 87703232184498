import { SET_IS_LOADING_PRICES } from '../types'

export default (isLoadingPrices) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_PRICES,
    payload: {
      isLoadingPrices,
    },
  })
}
