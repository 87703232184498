import React from 'react'
import PropTypes from 'prop-types'
import { Button, Link, Badge, Spinner } from '@walmart-web/livingdesign-components'
import QuantityBadge from '../QuantityBadge'
import QuantityStepper from '../../../../functional-components/QuantityStepper'
import LazyLoadImageContainer from '../../../../components/LazyLoadImageContainer'
import WlmPrice from '../../../ProductPrices/components/WlmPrice'
import RegularUnitPrice from '../../../ProductPrices/components/RegularUnitPrice'
import Price from '../../../ProductPrices/components/Price'
import SizeAndColor from '../SizeAndColor'
import MarketPlaceLabel from '../MarketPlaceLabel'
import { useTenant, useIsTenant } from '../../../../contexts/TenantContext'
import { priceEnhancer, productUrl, getItemSavingAmount } from '../../../../Helpers'
import CompleteProductPack from './components/CompleteProductPack'
import texts from '../../../../common/texts.json'
import constants from '../../../../common/constants'
import useShoppingCart from '../../hooks/useShoppingCart'
import textTemplate from '../../../../common/textTemplate'
import { ORIGINS } from '../../../../common/GoogleTagManager'
import './index.css'
import PromotionLabel from './components/PromotionLabel'
import { useSelector } from 'react-redux'
import SavingsLabel from '../../../SavingsLabel'

const itemDisplayName = ({ brand, displayName }) => `${brand}, ${displayName}`
const exclusivoInternet = 'exclusivointernet'

const ProductItemWithPromotions = ({ product, promotion = {} }) => {
  const { sku, displayName, price, quantity, images, promotion: productPromotion } = product
  const { discountPercentage, isSavingPrice, isPack, subTotal, remainingPackSizeQuantity, isPackCompleted } = promotion

  const { handleIncrease, handleRemove, handleDecrease } = useShoppingCart()
  const handleDelete = () => handleRemove(product, ORIGINS.SHOPPING_CART)
  const handleProductDecrease = () => handleDecrease(product, ORIGINS.SHOPPING_CART)
  const handleProductIncrease = () => handleIncrease(product, ORIGINS.SHOPPING_CART)

  const isLoadingPrices = useSelector((state) => state.shoppingCartReducer.isLoadingPrices)

  const { tenant } = useTenant()
  const isSOD = useIsTenant(constants.tenant.SOD)
  const isCatex = useIsTenant(constants.tenant.CATEX)
  const isPDC = useIsTenant(constants.tenant.PUNTO_DE_COMPRA)
  const isExclusivoInternet = product?.tags?.attributeTags?.includes(exclusivoInternet)

  const isCombinable = productPromotion && productPromotion.isCombinable
  const sodPackPrice = Number(price.packPrice)
  const sodPackSize = Number(price.packSize)
  const totalItemPrice = subTotal
  const productPrice = isPack ? sodPackPrice : price.BasePriceSales
  const productPackQuantity = isPack ? sodPackSize : 0
  const discount = !isPack ? discountPercentage : 0
  const isSoldOutProduct = !isSOD && product.maxAvailable >= 0 && product.maxAvailable < product.quantity

  const displayItemAvailability = () => {
    if (!product.maxAvailable) {
      return texts.SHOPPING_CART.SOLD_OUT
    }
    return textTemplate(texts.SHOPPING_CART.UNITS_LEFT, { units: product.maxAvailable })
  }

  const displayQuantityAdded = () =>
    product.maxAvailable > 0 ? textTemplate(texts.SHOPPING_CART.YOU_ADDED, { quantity: product.quantity }) : null

  return (
    <div className="product-item">
      <PromotionLabel isCombinable={isCombinable} isPack={isPack} isExclusivoInternet={isExclusivoInternet} />
      <div className="product-item__description">
        <div className="product-item__img-container">
          <div className="product-item__badge">
            <>{isSoldOutProduct ? <Badge color="red">!</Badge> : <QuantityBadge>{quantity}</QuantityBadge>}</>
          </div>
          <Link href={productUrl(tenant, product)}>
            <div className={`product-item__img${isSoldOutProduct ? '--grayscale' : ''}`}>
              <LazyLoadImageContainer
                id="lazy-img"
                className="img-fluid m-auto"
                alt={`${sku} - ${displayName}`}
                sku={sku}
                hitImage={images?.mediumImage}
              />
            </div>
          </Link>
        </div>
        <div className="product-item__middle-description">
          <a className="product-item__description-text" href={productUrl(tenant, product)}>
            {itemDisplayName(product)}
          </a>
          {(isCatex || isPDC) && (
            <>
              <SizeAndColor product={product} />
              <MarketPlaceLabel product={product} />
            </>
          )}

          {isLoadingPrices ? (
            <Spinner data-testid="loading-spinner" className="product-item__loading-spinner" />
          ) : (
            <WlmPrice
              price={productPrice}
              packQuantity={productPackQuantity}
              discount={discount}
              priceClass="product-item__price-promo-description"
              discountClass="product-item__discount-percentage"
            >
              {isSOD && discount > 0 && <SavingsLabel savings={getItemSavingAmount(price)} classNames="ml-2" />}
            </WlmPrice>
          )}
          {!isLoadingPrices && isPack && <RegularUnitPrice price={price.BasePriceSales} location="shopping-cart" />}
          {!isLoadingPrices && !isPack && isSavingPrice && (
            <div className="d-flex">
              <Price price={price.BasePriceReference} className="product-item__saving-price" />
            </div>
          )}
        </div>

        <div className="product-item__price-container">
          {isSoldOutProduct && (
            <div className="product-item__availability">
              <span className="product-item__available">{displayItemAvailability()}</span>
              <span className="product-item__quantity-added">{displayQuantityAdded()}</span>
            </div>
          )}
          <div className="product-item__total-price">
            {isLoadingPrices ? (
              <Spinner data-testid="loading-spinner-total" className="product-item__loading-spinner" />
            ) : (
              <span aria-hidden="false">{priceEnhancer(totalItemPrice)}</span>
            )}
          </div>
        </div>
      </div>
      <div className="product-item__msg-description">
        {isPack && (
          <CompleteProductPack
            discount={promotion.discount}
            remainingPackSizeQuantity={remainingPackSizeQuantity}
            isPackCompleted={isPackCompleted}
            isCombinable={isCombinable}
            parentComponent="shopping-cart-item"
          />
        )}
      </div>
      <div className="product-item__actions">
        <div className="product-item__remove-link">
          <Button variant="tertiary" size="medium" onClick={handleDelete}>
            {texts.COMMON.DELETE}
          </Button>
        </div>
        <div>
          <QuantityStepper product={product} onIncrease={handleProductIncrease} onDecrease={handleProductDecrease} />
        </div>
      </div>
    </div>
  )
}

ProductItemWithPromotions.propTypes = {
  product: PropTypes.object.isRequired,
  promotion: PropTypes.object,
}

export default ProductItemWithPromotions
