import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from '@walmart-web/livingdesign-components'
import './index.css'

const ShoppingCartError = ({ onClose, title, message, buttonText, isVisible }) => {
  return (
    <Modal isOpen={isVisible} onClose={onClose} size="small" title={<div />}>
      <div className="shoping_cart_error__modal">
        <div className="shoping_cart_error__modal__image-container">
          <img src="/catalogo/images/error-message-media.svg" alt="" />
        </div>
        <div className="shoping_cart_error__modal__head-line">
          <h1>{title}</h1>
        </div>
        <div className="shoping_cart_error__modal__head-line-two">
          <h3>{message}</h3>
        </div>
        <div>
          <Button variant="primary" data-testid="shoppingcart-error-button" size="medium" onClick={onClose}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ShoppingCartError.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  buttonText: PropTypes.string,
}
export default ShoppingCartError
