import postLogin from '../common/clients/postLogin'
import constants from '../common/constants'

const useLogin = () => {
  const onLogin = async (userInput, userInputPass, reCaptchaToken, loginEndPoint, isOriginIntegrationEnable) => {
    const response = await postLogin(userInput, userInputPass, reCaptchaToken, loginEndPoint)
    const success = isOriginIntegrationEnable ? response.success : response.token
    if (success === undefined) {
      return {
        status: response.data ? response.data.status : constants.HTTP_CODES.CODE_500,
        message: response.data ? response.data.message : response.message,
      }
    }
    return response
  }
  return {
    onLogin,
  }
}

export default useLogin
