const pxStyle = `
    .px-captcha-container {
      border-radius: 20px !important;
    }
    .px-captcha-refid {
      display: none;
    }
    .px-captcha-report {
      display: none;
    }
    .px-message {
      margin: 0 15px;
    }
    a {
      display: none !important
    }`

const buttonMessage = `<style>${pxStyle}</style>Mantén presionado unos segundos`

export const setPxConfig = () => {
  window['_' + window._pxAppId] = {
    challenge: {
      context: {
        headerText: '¿Persona o robot?',
        headerColor: '#000',
        headerFontSize: '26px',
        messageText: `<style>${pxStyle}</style><div class="px-message">Para continuar <b>necesitamos confirmar que eres humano.</b> Esto nos <b>ayuda a una navegación más segura para ti</b> y todos nuestros usuarios.</div>`,
        messageColor: '#2E2F32',
        messageFontSize: '16px',
      },
      translation: {
        default: {
          btn: buttonMessage,
        },
        es: {
          btn: buttonMessage,
        },
      },
      view: {
        padding: '10px',
        height: 60,
        width: 380,
        fillColor: '#0071DC',
        backgroundColor: 'white',
        borderColor: '#0071DC',
        borderRadius: 30,
        borderWidth: 4,
        textColor: '#0071DC',
        fontWeight: '500',
        texSize: 18,
      },
    },
  }
}
