import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ProductOutStock from './ProductOutStock'
import AlternativeProduct from './AlternativeProduct'
import useShoppingCart from '../../ShoppingCart/hooks/useShoppingCart'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'
import ErrorMessage from '../../SubstituteProducts/components/ErrorMessage'
import texts from '../../../common/texts.json'

const AlternativeProductsContent = ({ isMobile, onClose }) => {
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)
  const { handleAlternativeToSoldOutItem } = useShoppingCart()
  const { substituteProducts } = useShoppingCartContext()
  const { error, recommendations, soldOutItem, origin, flowType } = substituteProducts
  const errorMessageMediaImage = 'error-message-media.svg'
  const searchInfoErrorImage = 'searchInfo-error-blue.svg'
  const isError = error
  const isEmpty = !recommendations || recommendations.length === 0

  const handleRetry = async () => {
    setIsSpinnerLoading(true)
    await handleAlternativeToSoldOutItem(soldOutItem, origin)
    setIsSpinnerLoading(false)
  }

  if (isError || isEmpty) {
    return (
      <div className="alternatives-product-error">
        <ErrorMessage
          onClick={isError ? handleRetry : onClose}
          errorImage={isError ? errorMessageMediaImage : searchInfoErrorImage}
          errorMessage={
            isError
              ? texts.ERROR_MODAL.ALTERNATIVE_PRODUCTS_SERVICE_ERROR
              : texts.ERROR_MODAL.ALTERNATIVE_PRODUCTS_EMPTY
          }
          buttonText={isError ? texts.ERROR_MODAL.RETRY_BUTTON_TEXT : texts.ERROR_MODAL.BACK_BUTTON_TEXT}
          isSpinnerLoading={isSpinnerLoading}
          isMobile={isMobile}
        />
      </div>
    )
  }

  return (
    <>
      <ProductOutStock item={soldOutItem} isMobile={isMobile} />
      <div className="alternatives-product-list">
        {recommendations.map((recommendation) => (
          <AlternativeProduct
            item={recommendation}
            listProducts={recommendations}
            isMobile={isMobile}
            origin={origin}
            flowType={flowType}
            key={recommendation.sku}
          />
        ))}
      </div>
    </>
  )
}

AlternativeProductsContent.propTypes = {
  isMobile: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default AlternativeProductsContent
