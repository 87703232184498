import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const RenderLoader = () => (
  <div
    data-testid="loader-container"
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: '80px',
    }}
  >
    <CircularProgress
      className="progress-primary"
      size={64}
      mode="determinate"
      value={75}
      style={{ color: '#ffc220' }}
    />
  </div>
)

export default RenderLoader
