import React from 'react'
import PropTypes from 'prop-types'
import { useCampaignStyleConfig } from '../../../contexts/CampaignStyleConfigContext'
import { getHeaderCampaignStyle } from '../../../Helpers/getCampaignStyleConfig'
import { Button, Spinner } from '@walmart-web/livingdesign-components'
import { Grid as GridIcon } from '@walmart-web/livingdesign-icons'
import ShoppingCartHeaderIcon from '../../LateralPanel/header/ShoppingCartHeaderIcon'
import GlobalSearch from '../../GlobalSearch'
import StoreSelectorHeaderButton from '../../LateralPanel/header/StoreSelectorHeaderButton'
import LoginMenu from './LoginMenu'
import LiderLogo from './LiderLogo'
import GroceryHeaderButton from './GroceryHeaderButton'
import HeaderInfoPDC from './HeaderInfoPDC'
import HeaderInfoCATEX from './HeaderInfoCATEX'
import AlertInfoComponent from './AlertInfoComponent'
import WalStoreSelector from './WalStoreSelector'
import './DesktopHeader.css'

const CustomButtonLeading = ({ src }) => {
  return (
    <div>
      <img height="20px" alt="supermercado" src={src} />
    </div>
  )
}

CustomButtonLeading.propTypes = {
  src: PropTypes.string.isRequired,
}

const DesktopHeader = ({
  onMenuButtonToggle,
  onMenuClose,
  isSod,
  isWalstore,
  walStoreValue,
  headerAlertMessageConfig,
  categoryMenuSpinner,
}) => {
  const { campaignStyle } = useCampaignStyleConfig()
  const { menuText, headerButton, headerButtonIcon } = getHeaderCampaignStyle(campaignStyle)
  const showCustomerServiceInfo = !isSod
  const { enabled: shouldRenderAlertInfoComponent } = headerAlertMessageConfig

  const handleCustomButtomOnClick = () => {
    const redirectUrl = headerButton?.redirectUrl || 'https://lider.cl/catalogo'
    window.open(redirectUrl, '_blank')
  }

  const rightOptions = () => {
    return (
      <>
        {isWalstore ? (
          <div className="header__separator-right-content">
            <WalStoreSelector walStoreValue={walStoreValue} className="walstore-selector__container--desktop" />
          </div>
        ) : (
          <div className="header__grocery-button--desktop">
            <GroceryHeaderButton />
          </div>
        )}
      </>
    )
  }

  const rightOptionsSOD = () => {
    return (
      <>
        {headerButton?.active && (
          <div className="header__custom-button--desktop header__separator-right-content">
            <Button
              variant="primary"
              size="large"
              data-testid="custom-button-test-id"
              leading={<CustomButtonLeading src={headerButtonIcon} />}
              onClick={handleCustomButtomOnClick}
            >
              {headerButton?.text}
            </Button>
          </div>
        )}
        <div className={`header__store--desktop ${!headerButton?.active ? 'header__separator-right-content' : ''}`}>
          <StoreSelectorHeaderButton />
        </div>
        <div>
          <LoginMenu />
        </div>
      </>
    )
  }

  return (
    <>
      {shouldRenderAlertInfoComponent && <AlertInfoComponent headerAlertMessageConfig={headerAlertMessageConfig} />}
      {isWalstore && <HeaderInfoPDC isWalstore={isWalstore} showCustomerServiceInfo={showCustomerServiceInfo} />}
      {!isWalstore && !isSod && (
        <HeaderInfoCATEX isWalstore={isWalstore} showCustomerServiceInfo={showCustomerServiceInfo} />
      )}
      <header className="header--desktop" data-testid="desktop-header-test-id">
        <div className="header__center-content--desktop">
          <LiderLogo isSod={isSod} isWalstore={isWalstore} className={!isWalstore ? 'header__logo' : ''} />
          <div className="header__menu--desktop" role="presentation">
            <Button
              className="header__menu-button--desktop"
              variant="primary"
              size="large"
              data-testid="menu-button-test-id"
              leading={categoryMenuSpinner ? <Spinner color="white" size="small" /> : <GridIcon size="medium" />}
              onClick={onMenuButtonToggle}
            >
              {menuText}
            </Button>
          </div>
          <div
            className={`header__search--desktop ${isWalstore && 'header__search--desktop-walstore'}`}
            role="presentation"
            data-testid="global-search-test-id"
            onClick={onMenuClose}
          >
            <GlobalSearch />
          </div>
          {isSod ? rightOptionsSOD() : rightOptions()}
          <div data-testid="header-cart-test-id">
            <ShoppingCartHeaderIcon />
          </div>
        </div>
      </header>
    </>
  )
}

DesktopHeader.propTypes = {
  onMenuButtonToggle: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  isSod: PropTypes.bool.isRequired,
  isWalstore: PropTypes.bool.isRequired,
  walStoreValue: PropTypes.string,
  headerAlertMessageConfig: PropTypes.object,
  categoryMenuSpinner: PropTypes.bool,
}

DesktopHeader.defaultProps = {
  walStoreValue: '',
  headerAlertMessageConfig: {},
  categoryMenuSpinner: false,
}

export default DesktopHeader
