import texts from '../../../common/texts.json'
import { priceFormatter, isToday, getWeekDay, hourFormatter } from '../../../Helpers'

const getNearestWindow = ({ cost, startTime, date }) => {
  const [day, month, year] = date.split('-')
  const slotDate = new Date(+year, +month - 1, +day) // month starts from 0
  const slotDay = isToday(slotDate) ? texts.STORE_SELECTOR.DISPATCH_TODAY : `${getWeekDay(slotDate)} ${day}`
  const slotCost = cost === 0 ? texts.STORE_SELECTOR.DISPATCH_FREE : priceFormatter(cost)

  return `${texts.STORE_SELECTOR.DISPATCH_FROM} ${slotDay} - ${hourFormatter(startTime.toString())} hrs. - ${slotCost}`
}

export default getNearestWindow
