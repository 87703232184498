import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Divider } from '@walmart-web/livingdesign-components'
import { TrashCan as TrashCanIcon } from '@walmart-web/livingdesign-icons'
import { useDebounce } from '../../hooks/useDebounce'
import getPromotionItemsEvaluate from '../../../../common/clients/promotionEngineClient'
import ProductItem from '../ProductItem'
import ProductItemWithPromotions from '../ProductItemWithPromotions'
import ShoppingCartError from '../../components/ShoppingCartError'
import resetPromoEngineRetries from '../../../../../src/actions/resetPromoEngineRetries'
import store from '../../../../../src/store'

import texts from '../../../../common/texts.json'
import './index.css'
import { useLateralPanelContext } from '../../../LateralPanel/context/LateralPanelContext'
import setIsLoadingPrices from '../../../../actions/setIsLoadingPrices'

const ProductsList = ({ cartItems, onClickCleanUp }) => {
  const { promoEngineRetries } = useSelector((state) => state.shoppingCartReducer)
  const debouncedGetPromotionItems = useDebounce(
    () => {
      getPromotionItemsEvaluate()
    },
    500,
    []
  )

  const { closePanel, closeCartModalError, openCartModalError, cartModalErrorIsOpen } = useLateralPanelContext()

  const [prevProp, setPrevProp] = useState(cartItems)

  useEffect(() => {
    if (prevProp !== cartItems) {
      debouncedGetPromotionItems()
    }
    setPrevProp(cartItems)
  }, [cartItems, debouncedGetPromotionItems, prevProp])

  const promotionsData = useSelector((state) => state.promotionsReducer.data)
  const { products: productPromotions = [] } = promotionsData
  const isPromotionEngineEnabled = useSelector((s) => s.campaignConfigReducer.isPromotionEngineEnabled)

  useEffect(() => {
    if (promoEngineRetries >= 1) {
      openCartModalError()
    }
  }, [promoEngineRetries])

  return (
    <div className="product-list" id="product-list-id">
      <div className="product-list__container">
        {cartItems.map((product, i) => {
          const promotion = isPromotionEngineEnabled
            ? productPromotions.find(
                (promotion) =>
                  promotion.sku === product.sku &&
                  (product.winningOffer && promotion.sellerId
                    ? promotion.sellerId === product.winningOffer?.sellerId
                    : true)
              )
            : []
          return (
            <React.Fragment key={`fragment-key-${product.sku}`}>
              {isPromotionEngineEnabled ? (
                <ProductItemWithPromotions key={product.sku} product={product} promotion={promotion} />
              ) : (
                <ProductItem key={product.sku} product={product} />
              )}
              {cartItems.length > i + 1 && <Divider key={`divider-key-${product.sku}`} />}
            </React.Fragment>
          )
        })}
      </div>
      <div className="product-list__actions">
        <div className="product-list__remove_all_link">
          <Button variant="tertiary" size="medium" leading={<TrashCanIcon />} onClick={onClickCleanUp}>
            {texts.SHOPPING_CART.CLEANUP_LABEL}
          </Button>
        </div>
      </div>
      {promoEngineRetries >= 1 && promoEngineRetries <= 3 && (
        <ShoppingCartError
          onClose={() => {
            closeCartModalError()
            getPromotionItemsEvaluate()
          }}
          title={texts.SHOPPING_CART.ERROR_MODAL.TITLE}
          message={texts.SHOPPING_CART.ERROR_MODAL.MESSAGE}
          buttonText={texts.SHOPPING_CART.ERROR_MODAL.RETRY_BUTTON_TEXT}
          isVisible={cartModalErrorIsOpen}
        />
      )}
      {promoEngineRetries > 3 && (
        <ShoppingCartError
          onClose={() => {
            closeCartModalError()
            store.dispatch(resetPromoEngineRetries)
            store.dispatch(setIsLoadingPrices(false))
            closePanel()
          }}
          title={texts.SHOPPING_CART.ERROR_MODAL.TITLE}
          message={texts.SHOPPING_CART.ERROR_MODAL.MESSAGE_COUNT_LIMIT}
          buttonText={texts.SHOPPING_CART.ERROR_MODAL.BACK_BUTTON_TEXT}
          isVisible={cartModalErrorIsOpen}
        />
      )}
    </div>
  )
}

ProductsList.propTypes = {
  onClickCleanUp: PropTypes.func.isRequired,
  cartItems: PropTypes.array.isRequired,
}

export default ProductsList
