import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from '@walmart-web/livingdesign-components'
import { useLateralPanelContext } from '../../../LateralPanel/context/LateralPanelContext'
import './index.css'

const PolygonCartWarning = ({ onClose, title, message, primaryButtonText, secondaryButtonText, isVisible }) => {
  const { setStoreSelectorTabPickup, setStoreSelectorTabHomeDelivery } = useLateralPanelContext()
  const pickupChoosen = () => {
    setStoreSelectorTabPickup()
    onClose()
  }
  const homeDeliveryChoosen = () => {
    setStoreSelectorTabHomeDelivery()
    onClose()
  }
  return (
    <Modal isOpen={isVisible} onClose={onClose} size="small" title={<div />}>
      <div className="polygon_cart_warning__modal">
        <div className="polygon_cart_warning__modal__image-container">
          <img src={'/catalogo/images/PhoneWithLocationPin_Light_Circle_Blue.svg'} alt="" />
        </div>
        <div className="polygon_cart_warning__modal__head-line">
          <h1>{title}</h1>
        </div>
        <div className="polygon_cart_warning__modal__head-line-two">
          <h3>{message}</h3>
        </div>
        <div>
          <Button
            variant="primary"
            data-testid="shoppingcart-error-primarybutton"
            size="medium"
            onClick={pickupChoosen}
          >
            <text className="polygon_cart_warning__modal-text_Button">{primaryButtonText}</text>
          </Button>
        </div>
        <br />
        <div>
          <Button
            variant="tertiary"
            data-testid="shoppingcart-error-secondarybutton"
            size="medium"
            onClick={homeDeliveryChoosen}
          >
            <text className="polygon_cart_warning__modal-text_Button">{secondaryButtonText}</text>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

PolygonCartWarning.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
}
export default PolygonCartWarning
