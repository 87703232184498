import React from 'react'
import PropTypes from 'prop-types'
import AlertInfo from '../../../components/AlertInfo'
import RenderInnerHTML from '../../../components/RenderInnerHTML'

const AlertInfoComponent = ({ headerAlertMessageConfig }) => {
  return (
    <>
      <div data-testid="alert-info-component">
        <AlertInfo data-testid="alert-info-component">
          <span>
            <RenderInnerHTML html={headerAlertMessageConfig.content} />
          </span>{' '}
          {headerAlertMessageConfig.link.enabled && (
            <a href={headerAlertMessageConfig.link.to} target={headerAlertMessageConfig.link.target}>
              {headerAlertMessageConfig.link.text}
            </a>
          )}
        </AlertInfo>
      </div>
    </>
  )
}

AlertInfoComponent.propTypes = {
  headerAlertMessageConfig: PropTypes.object,
}

AlertInfoComponent.defaultProps = {
  headerAlertMessageConfig: {},
}

export default AlertInfoComponent
