import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@walmart-web/livingdesign-components'
import { Search } from '@walmart-web/livingdesign-icons'
import './index.css'

const PickupTextField = ({ id, ...rest }) => (
  <TextField
    id={id}
    UNSAFE_className="pickup-text-field"
    type="search"
    leadingIcon={<Search size="medium" />}
    {...rest}
  />
)

PickupTextField.propTypes = {
  id: PropTypes.string.isRequired,
}

export default PickupTextField
