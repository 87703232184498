import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@walmart-web/livingdesign-components'
import './index.css'

const QuantityBadge = ({ children }) => <Badge UNSAFE_className="quantity-badge">{children}</Badge>

QuantityBadge.propTypes = {
  children: PropTypes.node.isRequired,
}

export default QuantityBadge
