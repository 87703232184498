import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

const LoadingLayout = ({ children, showHeader, showFooter }) => (
  <div className="loading-layout">
    {showHeader && (
      <header>
        <div />
      </header>
    )}

    <div className="loading-layout__children">{children}</div>
    {showFooter && (
      <footer>
        <div />
        <div />
      </footer>
    )}
  </div>
)

LoadingLayout.defaultProps = {
  showHeader: false,
  showFooter: false,
}

LoadingLayout.propTypes = {
  children: PropTypes.object.isRequired,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
}

export default LoadingLayout
