import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIsTenant } from './TenantContext'
import { setHeaderCampaignTheme, setFooterCampaignTheme, setCampaignSeal } from '../Helpers/getCampaignStyleConfig'
import constants from '../common/constants'

export const CampaignStyleConfigContext = createContext({
  campaignStyle: null,
})

const Provider = ({ children }) => {
  const [campaignStyle, setCampaignStyle] = useState(null)
  const isTenantSod = useIsTenant(constants.tenant.SOD)

  const setCampaignThemes = (campaignStyle) => {
    setHeaderCampaignTheme(campaignStyle)
    setFooterCampaignTheme(campaignStyle)
    setCampaignSeal(campaignStyle)
  }

  const getCampaignStyleConfig = async () => {
    let campaignStyleResult

    try {
      const response = await fetch('/catalogo/campaign.json')
      const campaignStyleConfig = response?.ok ? await response.json() : {}

      if (campaignStyleConfig) {
        const { campaignStyleConfigSOD, campaignStyleConfigCATEX } = campaignStyleConfig
        const campaignStyleByTenant = isTenantSod ? campaignStyleConfigSOD : campaignStyleConfigCATEX

        if (campaignStyleByTenant) {
          campaignStyleResult = JSON.parse(campaignStyleByTenant)
          setCampaignThemes(campaignStyleResult?.campaignStyle || {})
        }
      }
    } catch (error) {
      //
    }

    setCampaignStyle(campaignStyleResult?.campaignStyle || {})
  }

  useEffect(() => {
    getCampaignStyleConfig()
  }, [])

  return (
    <CampaignStyleConfigContext.Provider
      value={{
        campaignStyle,
      }}
    >
      {campaignStyle && children}
    </CampaignStyleConfigContext.Provider>
  )
}

export const useCampaignStyleConfig = () => useContext(CampaignStyleConfigContext)

Provider.propTypes = {
  children: PropTypes.element.isRequired,
}

export const CampaignStyleConfigContextConsumer = CampaignStyleConfigContext.Consumer

export default Provider
