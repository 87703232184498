import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { BottomSheet, Modal, Divider } from '@walmart-web/livingdesign-components'
import useWindowSize from '../../hooks/useWindowSize'
import { useShoppingCartContext } from '../ShoppingCart/context/ShoppingCartContext'
import useShoppingCart from '../ShoppingCart/hooks/useShoppingCart'
import texts from '../../common/texts.json'
import ErrorMessage from './components/ErrorMessage'
import RecommendationItem from './components/RecommendationItem'
import urlToggleHelper from '../../Helpers/urlToggleHelper'
import './index.css'

const SubstituteProducts = ({ isOpen, onCloseSubstitutionView }) => {
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)
  const { isMobile } = useWindowSize()
  const { substituteProducts } = useShoppingCartContext()
  const { handleReplaceSoldOutItem } = useShoppingCart()
  const { isOriginIntegrationEnable } = urlToggleHelper()

  const errorMessageMediaImage = 'error-message-media.svg'
  const searchInfoErrorImage = 'searchInfo-error-blue.svg'
  const { error, recommendations, soldOutItem } = substituteProducts
  const isError = error
  const isEmpty = !recommendations || recommendations.length === 0

  const displayDivider = (itemIndex) => (recommendations.length > itemIndex + 1 ? <Divider /> : null)

  const handleRetry = async () => {
    setIsSpinnerLoading(true)
    await handleReplaceSoldOutItem(soldOutItem, isOriginIntegrationEnable)
    setIsSpinnerLoading(false)
  }

  const getContent = () => {
    if (isError || isEmpty) {
      return (
        <ErrorMessage
          onClick={isError ? handleRetry : onCloseSubstitutionView}
          errorImage={isError ? errorMessageMediaImage : searchInfoErrorImage}
          errorMessage={
            isError ? texts.ERROR_MODAL.SUBSTITUTE_PRODUCTS_SERVICE_ERROR : texts.ERROR_MODAL.SUBSTITUTE_PRODUCTS_EMPTY
          }
          buttonText={isError ? texts.ERROR_MODAL.RETRY_BUTTON_TEXT : texts.ERROR_MODAL.BACK_BUTTON_TEXT}
          isSpinnerLoading={isSpinnerLoading}
          isMobile={isMobile}
        />
      )
    }

    return (
      <div className="substitute-products__list">
        {recommendations.map((item, i) => (
          <Fragment key={`recommendation-item-${item.sku}`}>
            <RecommendationItem
              index={i}
              item={item}
              soldOutItem={soldOutItem}
              isMobile={isMobile}
              onCloseSubstitutionView={onCloseSubstitutionView}
            />
            {displayDivider(i)}
          </Fragment>
        ))}
      </div>
    )
  }

  const getTitle = (className) => <span className={className}>{texts.COMMON.CHOOSE_REPLACEMENT}</span>

  return (
    <>
      {isMobile ? (
        <BottomSheet
          isOpen={isOpen}
          onClose={onCloseSubstitutionView}
          title={getTitle('substitute-products__bottom-sheet-title')}
        >
          {getContent()}
        </BottomSheet>
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onCloseSubstitutionView}
          size={isError || isEmpty ? 'small' : 'medium'}
          title={isError || isEmpty ? <div /> : getTitle('substitute-products__modal-title')}
        >
          {getContent()}
        </Modal>
      )}
    </>
  )
}

SubstituteProducts.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseSubstitutionView: PropTypes.func.isRequired,
}

export default SubstituteProducts
