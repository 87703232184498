import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import EmptyCart from './components/EmptyCart'
import ProductsList from './components/ProductList'
import TopMessages from './components/TopMessages'
import TopCheckoutError from './components/TopCheckoutError'
import FullfillmentDetail from './components/FullfillmentDetail'
import Summary from './components/Summary'
import { getSubTotal } from './components/Summary/Utils'

import { useShoppingCartContext } from './context/ShoppingCartContext'
import { useLateralPanelContext } from '../../functional-components/LateralPanel/context/LateralPanelContext'
import { useIsTenant } from '../../contexts/TenantContext'

import texts from '../../common/texts.json'
import constants from '../../common/constants'

import './index.css'
import { useAnalytics } from '../../contexts/AnalyticsContext'
import AnalyticsConstants from '../../contexts/AnalyticsContext/constants.json'

const LDShoppingCart = ({
  onClose,
  onClickCleanUp,
  onContinueClick,
  onFulfillmentClick,
  onUpdateStoreForClientAddress,
}) => {
  const { EVENTS, FLOW_TYPES, ORIGINS } = AnalyticsConstants
  const { shoppingCartItems, store, saveShoppingCart } = useShoppingCartContext()
  const { getDataForGoogleAnalytics } = useAnalytics()
  const [isButtonGoToPayLoading, setIsButtonGoToPayLoading] = useState(false)
  const [checkoutError, setCheckoutError] = useState()
  const { goToPayMessageConfig } = useSelector((state) => state.campaignConfigReducer)
  const { goToPayEnabled = true, digitLimitToPayConfig = {}, digitLimitToCarryConfig = {} } = goToPayMessageConfig || {}

  const { active: isQuantityLimitActive, limit: quantityLimit } = digitLimitToCarryConfig
  const { active: isAmountLimitActive, limit: amountLimit } = digitLimitToPayConfig
  const isPurchaseAmountLimitVisible = isAmountLimitActive && getSubTotal(shoppingCartItems) > amountLimit
  const isQuantityLimitVisible =
    isQuantityLimitActive && shoppingCartItems.some((product) => product.quantity >= quantityLimit)
  const isButtonGoToPayDisabled = isPurchaseAmountLimitVisible || !goToPayEnabled
  const {
    CHECKOUT_MESSAGE_ERROR: {
      ERROR_SKU_WITHOUT_STOCK,
      ERROR_PRODUCTS_WITHOUT_STOCK,
      ERROR_PRODUCTS_WITHOUT_QUANTITY_MATCH,
      ERROR_PRODUCTS_IMS_STOCK,
      ERROR_PRODUCTS_WITHOUT_VENDOR,
    },
    tenant: { SOD },
  } = constants
  const isSOD = useIsTenant(SOD)
  useEffect(() => {
    getDataForGoogleAnalytics({
      eventName: EVENTS.VIEW_CART,
      flowType: FLOW_TYPES.SHOPPING_CART,
      data: {
        origin: ORIGINS.TOP_BANNER,
        selectedWalstoreStore: store?.id,
        eventEcommerce: EVENTS.VIEW_CART,
        shoppingCartItems: shoppingCartItems,
      },
    })
  }, [])

  const { openCartModalCoordinatesError } = useLateralPanelContext()
  useEffect(() => {
    if (isSOD) {
      onUpdateStoreForClientAddress().catch((error) => {
        if (error == 'Error: REQUEST_FAILED_WITH_STATUS_CODE_404') openCartModalCoordinatesError()
      })
    }
  }, [])

  const updateMaxAvailableQuantity = (shoppingCartItems, checkoutResponse) => {
    const updatedCart = shoppingCartItems.map((item) => {
      const responseItem = checkoutResponse.find((response) => response.sku === item.sku)
      if (responseItem) {
        if (responseItem['unpublished']) {
          return { ...item, maxAvailable: 0 }
        } else {
          return { ...item, maxAvailable: responseItem.maxAvailable }
        }
      }
    })
    return updatedCart
  }

  const onContinueErrorHandledClick = async () => {
    try {
      setIsButtonGoToPayLoading(!isSOD)
      await onContinueClick()
    } catch (error) {
      setIsButtonGoToPayLoading(false)
      setCheckoutError(error)
      if (
        !isSOD &&
        error.response?.details?.products &&
        (error.response.message.includes(ERROR_SKU_WITHOUT_STOCK) ||
          error.response.message.includes(ERROR_PRODUCTS_WITHOUT_STOCK) ||
          error.response.message.includes(ERROR_PRODUCTS_WITHOUT_QUANTITY_MATCH) ||
          error.response.message.includes(ERROR_PRODUCTS_IMS_STOCK) ||
          error.response.message.includes(ERROR_PRODUCTS_WITHOUT_VENDOR))
      ) {
        const updatedCart = updateMaxAvailableQuantity(shoppingCartItems, error.response.details.products)
        saveShoppingCart(updatedCart)
      }
    }
  }

  return (
    <div className="shopping-cart__panel">
      <div className="shopping-cart">
        {shoppingCartItems && shoppingCartItems.length > 0 ? (
          <Fragment>
            {isSOD && <FullfillmentDetail onClick={onFulfillmentClick} />}
            {!isSOD && (
              <>
                {checkoutError && <TopCheckoutError error={checkoutError} shoppingCartItems={shoppingCartItems} />}
                <TopMessages
                  isPurchaseAmountLimitVisible={isPurchaseAmountLimitVisible}
                  goToPayEnabled={goToPayEnabled}
                  isQuantityLimitVisible={isQuantityLimitVisible}
                />
              </>
            )}
            <ProductsList cartItems={shoppingCartItems} onClickCleanUp={onClickCleanUp} />
            <div className="shopping-cart__sticky-division">{texts.COMMON.SPACE}</div>
            <div className="shopping-cart__summary" data-testid="shopping-cart-summary">
              <Summary
                isButtonGoToPayDisabled={isButtonGoToPayDisabled}
                productList={shoppingCartItems}
                isButtonGoToPayLoading={isButtonGoToPayLoading}
                onClick={onContinueErrorHandledClick}
              />
            </div>
          </Fragment>
        ) : (
          <EmptyCart onClick={onClose} />
        )}
      </div>
    </div>
  )
}

LDShoppingCart.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickCleanUp: PropTypes.func.isRequired,
  onContinueClick: PropTypes.func.isRequired,
  onFulfillmentClick: PropTypes.func.isRequired,
  onUpdateStoreForClientAddress: PropTypes.func.isRequired,
}

export default LDShoppingCart
