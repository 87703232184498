import { UPDATE_PROMOTIONS } from '../types'

export default (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROMOTIONS,
    payload: {
      data,
    },
  })
}
