import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import RenderInnerHTML from '../../components/RenderInnerHTML'
import constants from '../../common/constants'
import './index.css'

const MobileAlertInfoComponent = ({ headerAlertMessageConfig }) => {
  const {
    CONTINGENCY_ALERT: { COOKIE_NAME, COOKIE_VALUE, COOKIE_EXPIRATION_HOURS },
    timeout: { TEN_SECONDS },
  } = constants
  const { contentMobile: alertContent, timeoutMobile = TEN_SECONDS } = headerAlertMessageConfig
  const timeoutMobileMilliseconds = timeoutMobile * 1000
  const [isAlertClosed, setIsAlertClosed] = useState(false)
  const cookieAlertExist = document.cookie.includes(`${COOKIE_NAME}=${COOKIE_VALUE}`)
  const shouldShowAlert = !isAlertClosed && !cookieAlertExist && alertContent

  const closeHandler = () => {
    const expireDate = new Date()
    expireDate.setHours(expireDate.getHours() + COOKIE_EXPIRATION_HOURS)
    document.cookie = `${COOKIE_NAME}=${COOKIE_VALUE} ; expires = ${expireDate.toUTCString()}`
    setIsAlertClosed(true)
  }

  useEffect(() => {
    let timeoutId
    if (timeoutMobileMilliseconds && shouldShowAlert) {
      timeoutId = setTimeout(() => {
        closeHandler()
      }, timeoutMobileMilliseconds)
    }

    return () => clearTimeout(timeoutId)
  }, [timeoutMobileMilliseconds])

  return (
    <div
      className={`mobile-alert-info-component__main-container ${
        shouldShowAlert
          ? 'mobile-alert-info-component__main-container--visible'
          : 'mobile-alert-info-component__main-container--hidden'
      }`}
    >
      <div className="mobile-alert-info-component__content">
        <span className="mobile-alert-info-component__text">
          <RenderInnerHTML html={alertContent} />
        </span>
        <button className="mobile-alert-info-component__close-alert" onClick={closeHandler}>
          <img alt="close" src="/catalogo/images/close_button.svg" />
        </button>
      </div>
    </div>
  )
}

MobileAlertInfoComponent.propTypes = {
  headerAlertMessageConfig: PropTypes.object.isRequired,
}

export default MobileAlertInfoComponent
