import styled from 'styled-components'

export const CarouselWrapper = styled.div`
  @media (min-width: 768px) {
    padding: ${(props) => (props.customStyle && props.customStyle.padding ? props.customStyle.padding : 'unset')};
    img {
      border-radius: ${(props) =>
        props.customStyle && props.customStyle.borderRadius ? props.customStyle.borderRadius : 'unset'};
      min-height: ${(props) =>
        props.customStyle && props.customStyle.imageMinHeight ? props.customStyle.imageMinHeight : 'unset'};
    }
  }

  margin-bottom: ${(props) =>
    props.customStyle && props.customStyle.marginBottom ? props.customStyle.marginBottom : 'unset'};
  button::before {
    font-size: ${(props) =>
      props.customStyle && props.customStyle.buttonSize ? props.customStyle.buttonSize : 'inherit'};
  }

  .slick-dots {
    bottom: -25px;
  }
`

export const BackgroundContainer = styled.div`
  background: #e5e5e5;
  padding-top: 100px;
  margin-top: 70px;
  @media (max-width: 992px) {
    margin-top: 80px;
    padding-top: 130px;
  }
  @media (max-width: 768px) {
    margin-top: 70px;
    padding-top: 100px;
  }
`

export const BannersSection = styled.section`
  background: #f0f0f0;
  padding-bottom: 2px;
`
export const SalesSection = styled.section`
  background: #f0f0f0;
  padding-bottom: 80px;
  padding-top: 10px;
  @media (min-width: 769px) {
    padding: 3vw 0 7vw 0;
  }
`

export const ProductsSection = styled.section`
  background: #ffffff;
  padding-top: 30px;
`

export const CorporateFooterSection = styled.section`
  padding-top: 1px;
  background: #fff;

  @media (max-width: 768px) {
    background: #f7f6f6;
  }
`

export const CustomInfoSection = styled.div`
  /*background: #ffffff;*/
  background: #f0f0f0;
  /*padding-top: 4vw;
   padding-bottom: 4vw;*/
  padding-top: 1vw;
  padding-bottom: 3vw;

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: 30px;
    background: #f0f0f0;
    padding-top: 20px;
  }
`

export const InspirationalSection = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`

export const OffersBannerSection = styled.section`
  padding-top: 50px;
  background: #fff;
  padding-bottom: 50px;
`

export const LineBreakerSection = styled.section`
  padding-top: 1px;
  background: #fff;
  margin-bottom: -20px;

  @media (max-width: 768px) {
    margin-bottom: -40px;
  }
`
