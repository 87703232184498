import React from 'react'
import PropTypes from 'prop-types'
import './index.css'
import texts from '../../../../../../common/texts.json'
import { priceFormatter } from '../../../../../../Helpers'

const CompleteProductPack = ({
  discount,
  remainingPackSizeQuantity,
  isPackCompleted,
  isCombinable,
  parentComponent,
}) => {
  const contextClass = parentComponent ? `complete-product-pack__${parentComponent}` : ''
  const containerClasses = `complete-product-pack__container ${contextClass}`

  const { REMAINING_ADD_LABEL, REMAINING_MORE_LABEL, REMAINING_COMPLETE_LABEL, SAVING_LABEL_WITHOUT_AMOUNT } =
    texts.PACK_PROMOTION

  const showPackCompleted = () => (
    <div className="complete-product-pack__pack-completed" data-testid="complete-product-pack-msg">
      {SAVING_LABEL_WITHOUT_AMOUNT}
      <strong>{priceFormatter(discount)}</strong>
    </div>
  )

  const showRemainingOrPackCompleted = () => {
    return (
      <div data-testid="complete-product-pack" className={containerClasses}>
        {isPackCompleted ? (
          showPackCompleted()
        ) : (
          <div className="complete-product-pack__remaining-to-complete" data-testid="incomplete-product-pack">
            {REMAINING_ADD_LABEL}
            <strong>
              {remainingPackSizeQuantity}
              {REMAINING_MORE_LABEL}
            </strong>
            {REMAINING_COMPLETE_LABEL}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {isCombinable && discount > 0 && showPackCompleted()}
      {!isCombinable && showRemainingOrPackCompleted()}
    </>
  )
}

CompleteProductPack.propTypes = {
  discount: PropTypes.number.isRequired,
  remainingPackSizeQuantity: PropTypes.number.isRequired,
  isPackCompleted: PropTypes.bool.isRequired,
  isCombinable: PropTypes.bool,
  parentComponent: PropTypes.string,
}

CompleteProductPack.defaultProps = {
  isCombinable: false,
  parentComponent: undefined,
}

export default CompleteProductPack
