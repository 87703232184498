import React from 'react'
import PropTypes from 'prop-types'
import WlmPrice from '../../../functional-components/ProductPrices/components/WlmPrice'
import RegularUnitPrice from '../../../functional-components/ProductPrices/components/RegularUnitPrice'
import Price from '../../../functional-components/ProductPrices/components/Price'
import { getItemSaleDiscount } from '../../ShoppingCart/price.utils'
import SavingsLabel from '../../../functional-components/SavingsLabel'
import { getItemSavingAmount, isSod } from '../../../Helpers'

const AlternativesProductPrices = ({ item }) => {
  const { price } = item
  const packSize = price.packSize || 0
  const isPack = packSize > 0
  const isSavingPrice = price.BasePriceReference > price.BasePriceSales
  const itemPrice = isPack ? price.packPrice : price.BasePriceSales
  const itemDiscount = isPack ? 0 : getItemSaleDiscount(item)
  const showSodSavingsLabel = itemDiscount > 0 && isSod()

  const renderSavingPrice = () =>
    isSavingPrice ? (
      <div className="d-flex">
        <Price price={price.BasePriceReference} className="product-item__saving-price" />
      </div>
    ) : (
      ''
    )

  return (
    <>
      <WlmPrice
        price={itemPrice}
        packQuantity={packSize}
        discount={itemDiscount}
        priceClass="product-item__price-promo-description"
        discountClass="product-item__discount-percentage"
      >
        {showSodSavingsLabel > 0 && <SavingsLabel savings={getItemSavingAmount(price)} classNames="ml-2" />}
      </WlmPrice>
      {isPack ? <RegularUnitPrice price={price.BasePriceSales} location="shopping-cart" /> : renderSavingPrice()}
    </>
  )
}

AlternativesProductPrices.propTypes = {
  item: PropTypes.object.isRequired,
}

export default AlternativesProductPrices
