import styled from 'styled-components'

export const customModalStyles = {
  content: {
    top: '0px',
    left: '0',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '21vw',
    maxWidth: '100%',
    borderRadius: 'none',
    overflow: 'hidden',
    height: '100vh',
    border: 'none',
    background: 'none',
  },
  overlay: {
    top: '88px',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '150',
  },
}

export const FirstLevelPrimaryIcon = styled.img`
  height: 30px;
  margin-right: 14px;
`

export const CampaignTagIcon = styled.img`
  height: 24px;
  margin-right: 14px;
`

export const ViewAllArrow = styled.img`
  height: 10px;
  margin-left: 2px;
`

export const FirstLevelSecondaryIcon = styled.img`
  position: absolute;
  height: 33px;
  right: 2px;
`

export const ExternalIconLink = styled.img`
  position: absolute;
  height: 33px;
  right: 2px;
`

const firstLevelSectionBG = (props) => {
  if (props.active && props.indicatorColor) return props.indicatorColor
  if (props.active && !props.indicatorColor) return '#fff'
  return '#F2F8FD'
}

const firstLevelSectionBL = (props) => {
  if (props.active && props.indicatorColor) return props.indicatorColor
  if (props.active && !props.indicatorColor) return '#FFC220'
  return '#F2F8FD'
}

const firstLevelSectionImageFilter = ({ active, applyFilter, isSVGIcon }) => {
  if (active && applyFilter) return 'brightness(0) invert(1)'
  if (active && !applyFilter && isSVGIcon)
    return 'invert(32%) sepia(68%) saturate(2456%) hue-rotate(189deg) brightness(85%) contrast(103%)'
  return 'unset'
}

const firstLevelSectionColor = (props) => {
  if (props.active && props.selectedTextColor) return props.selectedTextColor
  if (props.active && !props.selectedTextColor) return '#0071ce'
  return '#414042'
}

export const FirstLevelSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 15px;
  width: 100%;
  height: 46px;
  cursor: pointer;
  background-color: ${firstLevelSectionBG};
  border-left: 5px solid ${firstLevelSectionBL};
  img {
    &:first-child {
      filter: ${firstLevelSectionImageFilter};
    }

    &:last-child {
      filter: ${firstLevelSectionImageFilter};
    }
  }

  div {
    color: ${firstLevelSectionColor};
    font-weight: ${(props) => (props.active ? 'bold' : 'inherit')};
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: ${(props) => (props.indicatorColor ? props.indicatorColor : '#fff')};
      border-left: 5px solid ${(props) => (props.indicatorColor ? props.indicatorColor : '#FFC220')};
      div {
        color: ${(props) => (props.selectedTextColor ? props.selectedTextColor : '#0071ce')};
        font-weight: bold;
      }
    }
  }

  @media (max-width: 768px) {
    right: 0px;
  }
`

export const FirstLevelTitle = styled.div`
  color: ${(props) => (props.defaultTextColor ? props.defaultTextColor : '#414042')};
  font-size: 16px;
  margin-right: 25px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const SecondLevelHeader = styled.div`
  display: flex;
  align-items: center;
`

export const SecondLevelMobileHeader = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
`

export const SecondLevelHeaderIcon = styled.img`
  height: 50px;
  margin-right: 14px;
  filter: ${({ applyFilter }) => (applyFilter ? 'brightness(0) invert(1)' : 'none')};

  @media (max-width: 1000px) {
    height: 30px;
  }

  @media (max-width: 768px) {
    height: 35px;
    padding-left: 4px;
    margin-right: 0px;
  }
`

export const SecondLevelHeaderTitle = styled.div`
  color: ${(props) => props.selectedTextColor};
  font-weight: bold;
  font-size: 25px;
  margin-right: 14px;

  @media (max-width: 1000px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const SecondLevelHeaderLink = styled.a`
  width: 106px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 ${({ shadowColor }) => shadowColor};
  background-color: #ffffff;
  color: #0071ce;
  text-align: center;
  font-weight: bold;
  font-size: 18px;

  @media (max-width: 1000px) {
    width: 64px;
    font-size: 12px;
  }

  &:hover {
    color: #0071ce;
    box-shadow: 0 0 8px #aeaeae;
    transform: scale(1.01);
  }
`
export const SecondLevelSection = styled.div`
  padding-left: 30px;
  padding-top: 40px;
  padding-bottom: 30px;
  overflow: scroll;
  height: 100vh;
  background: ${(props) => props.selectedBackgroundColor};
  color: ${(props) => props.selectedTextColor};
  padding-bottom: 88px;
  width: 66vw;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-top: 0px;
    width: 100vw;
  }
`

export const ThirdLevelHeader = styled.div`
  color: ${(props) => props.selectedTextColor};
  font-weight: bold;
  font-size: 16px;
  margin-right: 14px;
  margin-bottom: 8px;

  &:hover {
    color: ${(props) => props.hoverColorLink};
  }
`

const thirdLevelTitleImageFilter = ({ active, applyFilter }) => {
  if (active && applyFilter) return 'unset'
  if (active && !applyFilter) return 'brightness(0) invert(1)'
  if (!active && applyFilter) return 'invert(10) brightness(0)'
  return 'brightness(0) invert(1)'
}

export const ThirdLevelTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;

  color: ${(props) => (props.active ? props.hoverColorLink : props.selectedTextColor)};
  border-bottom: ${(props) => (props.active ? '1px solid' : 'unset')};

  img {
    filter: ${thirdLevelTitleImageFilter};
  }

  &:hover {
    color: ${(props) => props.hoverColorLink};

    img {
      filter: ${({ applyFilter }) => (applyFilter ? 'none' : 'brightness(0) invert(1)')};
    }
  }
`

export const ThirdLevelSection = styled.div`
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 17px;
  }
`

export const LinkContainer = styled.a`
  width: 100%;
`
