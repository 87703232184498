import { SET_MONDAY_DISCOUNT } from '../types'

const initState = {
  enabled: false,
}

export default (state = initState.enabled, action) => {
  switch (action.type) {
    case SET_MONDAY_DISCOUNT:
      return Object.assign({}, state, { enabled: action.payload.enabled })
    default:
      return state
  }
}
