import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import getConfig from '../../../Helpers/getConfig'
import { filterAllAddressInfo } from './addressFormatter'

import AddressSuggestions from './AddressSuggestions'
import AddressInput from './AddressInput'
import constants from '../../../common/constants'

const { MIN_LENGTH_AUTOCOMPLETE, MS_SUGGESTION_DEBOUNCE } = constants.delivery.autocomplete

const renderInput = ({ selectProps }) => <AddressInput selectProps={selectProps} />

const renderSuggestions = ({ options = [], selectOption, selectProps }) => {
  if (
    options.length < 1 &&
    selectProps.addressLineOneValue.length > MIN_LENGTH_AUTOCOMPLETE &&
    !selectProps.isLoading
  ) {
    selectProps.handleErrorMessage()
  }

  return (
    selectProps.addressLineOneValue &&
    selectProps.menuOpen && (
      <AddressSuggestions options={options} setMenuOpen={selectProps.setMenuOpen} selectOption={selectOption} />
    )
  )
}

const AddressAutocomplete = ({
  setAddressLineOneValue,
  addressLineOneValue,
  setPlaceInformation,
  setPlaceId,
  placeId,
  errorMessage,
  handleErrorMessage,
  addressInputRef,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const googleMapAccessCode = getConfig('googleMapAccessCode')

  useEffect(() => {
    const getPlaceInformation = async () => {
      try {
        const result = await geocodeByPlaceId(placeId)
        setPlaceInformation(filterAllAddressInfo(result[0]))
      } catch (error) {
        setPlaceInformation({})
      }
    }

    if (placeId) getPlaceInformation()
  }, [placeId])

  const onSelectSuggestedAddress = (address) => {
    const {
      value: { description, place_id: addressPlaceId },
    } = address
    setPlaceId(addressPlaceId)
    setAddressLineOneValue(description)
  }

  return (
    <GooglePlacesAutocomplete
      apiKey={googleMapAccessCode}
      minLengthAutocomplete={MIN_LENGTH_AUTOCOMPLETE}
      debounce={MS_SUGGESTION_DEBOUNCE}
      autocompletionRequest={{
        componentRestrictions: { country: 'cl' },
        types: ['address'],
      }}
      selectProps={{
        error: errorMessage,
        inputValue: addressLineOneValue,
        onChange: onSelectSuggestedAddress,
        onInputChange: (_selectedAddress) => {
          setAddressLineOneValue(_selectedAddress)
        },
        onKeyDown: () => {},
        components: {
          Control: renderInput,
          Menu: renderSuggestions,
        },
        tabSelectsValue: false,
        setMenuOpen,
        setAddressLineOneValue,
        addressLineOneValue,
        menuOpen,
        handleErrorMessage,
        addressInputRef,
      }}
    />
  )
}

AddressAutocomplete.propTypes = {
  addressLineOneValue: PropTypes.string.isRequired,
  setAddressLineOneValue: PropTypes.func.isRequired,
  setPlaceInformation: PropTypes.func.isRequired,
  placeId: PropTypes.string.isRequired,
  setPlaceId: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleErrorMessage: PropTypes.func.isRequired,
  addressInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default AddressAutocomplete
