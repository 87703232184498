import React from 'react'
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer'
import { SalesSection as SalesSectionStyled } from '../../../../components/CampaignHomeStyledComponents'
import { Skeleton, SkeletonText } from '@walmart-web/livingdesign-components'
import useWindowSize from '../../../../hooks/useWindowSize'
import './index.css'

const ResponsiveSkeleton = () => {
  const { isMobile } = useWindowSize()
  const lineBreakerClass = isMobile ? 'lineBreaker-mobile' : 'lineBreaker-desktop'
  const textTitleClass = isMobile ? 'grid-title-mobile' : 'grid-title-desktop'

  return (
    <>
      <Skeleton className={lineBreakerClass} />
      <SkeletonText lines={2} className={textTitleClass} />
      <div className="sales-container">
        <div className="item-1">
          <Skeleton className={'image'} />
        </div>
        <div className="item-2">
          <Skeleton className={'image'} />
        </div>
        <div className="item-3">
          <Skeleton className={'image'} />
        </div>
        <div className="item-4">
          <Skeleton className={'image'} />
        </div>
      </div>
      <Skeleton className={lineBreakerClass} />
    </>
  )
}

const SalesSectionLoader = () => (
  <SalesSectionStyled data-testid="sales-section-loader">
    <MaxWidthContainer>
      <ResponsiveSkeleton />
    </MaxWidthContainer>
  </SalesSectionStyled>
)

export default SalesSectionLoader
