import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@walmart-web/livingdesign-components'
import constants from '../../../../common/constants'
import texts from '../../../../common/texts.json'
import './index.css'

const TopCheckoutError = ({ error, shoppingCartItems }) => {
  const {
    PRODUCT_NOT_AVAILABLE_ALERT_TEXT_ONE_ITEM,
    SPACE,
    REPLACE_PRODUCT_ALERT_TEXT_ONE_ITEM,
    PRODUCT_NOT_AVAILABLE_ALERT_TEXT_MANY_ITEMS,
    REPLACE_PRODUCT_ALERT_TEXT_MANY_ITEMS,
    NOT_AVAILABLE_PRODUCTS_MESSAGE,
  } = texts.SHOPPING_CART
  const {
    CHECKOUT_MESSAGE_ERROR: {
      ERROR_NO_REDIRECT_CHECKOUT_URL,
      ERROR_SKU_WITHOUT_STOCK,
      ERROR_PRODUCTS_WITHOUT_STOCK,
      ERROR_PRODUCTS_WITHOUT_QUANTITY_MATCH,
    },
  } = constants
  const isAnyProductSoldOut = shoppingCartItems.some(
    (item) => item.maxAvailable === 0 || item.quantity > item.maxAvailable
  )
  const errorMessage = error?.message
  const responseErrorMessage = error?.response?.message || ''
  const isStockError =
    responseErrorMessage.includes(ERROR_SKU_WITHOUT_STOCK) ||
    responseErrorMessage.includes(ERROR_PRODUCTS_WITHOUT_STOCK) ||
    responseErrorMessage.includes(ERROR_PRODUCTS_WITHOUT_QUANTITY_MATCH)
  const isQuantityErrorWithoutProducts =
    responseErrorMessage.includes(ERROR_PRODUCTS_WITHOUT_QUANTITY_MATCH) && !isAnyProductSoldOut
  if (isStockError && isAnyProductSoldOut) {
    return (
      <div className="top-checkout-error__alert">
        <Alert variant="warning">{NOT_AVAILABLE_PRODUCTS_MESSAGE}</Alert>
      </div>
    )
  } else if (errorMessage === ERROR_NO_REDIRECT_CHECKOUT_URL && (!isStockError || isQuantityErrorWithoutProducts)) {
    const isSingleItem = shoppingCartItems.length === 1
    return (
      <div className="top-checkout-error__alert">
        <Alert variant="error">
          <strong>
            {isSingleItem ? PRODUCT_NOT_AVAILABLE_ALERT_TEXT_ONE_ITEM : PRODUCT_NOT_AVAILABLE_ALERT_TEXT_MANY_ITEMS}
          </strong>
          {SPACE}
          {isSingleItem ? REPLACE_PRODUCT_ALERT_TEXT_ONE_ITEM : REPLACE_PRODUCT_ALERT_TEXT_MANY_ITEMS}
        </Alert>
      </div>
    )
  }
  return null
}

TopCheckoutError.propTypes = {
  error: PropTypes.object.isRequired,
  shoppingCartItems: PropTypes.array.isRequired,
}

export default TopCheckoutError
