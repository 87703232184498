import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@walmart-web/livingdesign-components'
import './Overlay.css'

const Overlay = ({ coverAll }) => (
  <div className={coverAll ? 'overlay--all' : 'overlay'}>
    { coverAll && <Spinner className="overlay--all__central-spinner" /> }
  </div>
)

Overlay.defaultProps = {
  coverAll: false,
}

Overlay.propTypes = {
  coverAll: PropTypes.bool,
}

export default Overlay
