import ClientAPI from '../ClientAPI'
import store from '../../store'
import setPromotions from '../../actions/setPromotions'
import { isSod, getProductLimit } from '../../Helpers'
import increasePromoEngineRetries from '../../actions/increasePromoEngineRetries'
import resetPromoEngineRetries from '../../actions/resetPromoEngineRetries'
import setIsLoadingPrices from '../../actions/setIsLoadingPrices'

const clientAPI = new ClientAPI()

const getQuantity = (quantity, productLimit) => (quantity >= productLimit ? productLimit : quantity)
const getPromotionItemsEvaluate = async () => {
  let {
    shoppingCartReducer: { shoppingCart },
    campaignConfigReducer: {
      isPromotionEngineEnabled,
      goToPayMessageConfig: {
        digitLimitToCarryConfig: { limit },
      },
    },
  } = store.getState()

  if (shoppingCart.length < 1) return null

  if (isPromotionEngineEnabled) {
    const products = shoppingCart.map((item) => {
      let {
        isMKP,
        sku,
        max,
        quantity,
        keyword,
        promotion,
        price: { BasePriceReference, BasePriceSales, BasePriceTLMC, packPrice, packSize },
        winningOffer,
      } = item
      const productLimit = getProductLimit(max, limit)
      quantity = getQuantity(quantity, productLimit)
      if (productLimit !== max) {
        max = productLimit
      }
      return {
        isMKP: isMKP || false,
        sku,
        quantity,
        keyword: keyword || [],
        promotionId: promotion?.id || '',
        isCombinable: promotion?.isCombinable || false,
        max: isSod() ? max : limit,
        price: {
          BasePriceReference,
          BasePriceSales,
          BasePriceTLMC,
          packPrice,
          packSize,
        },
        sellerId: winningOffer?.sellerId,
      }
    })
    try {
      store.dispatch(setIsLoadingPrices(true))
      const response = await clientAPI.promotionEvaluate({ products })
      store.dispatch(setPromotions(response))
      store.dispatch(resetPromoEngineRetries)
      store.dispatch(setIsLoadingPrices(false))
    } catch (error) {
      store.dispatch(setPromotions({ products: [] }))
      store.dispatch(increasePromoEngineRetries)
    }
  } else {
    store.dispatch(setPromotions({ products: [] }))
  }
}

export default getPromotionItemsEvaluate
