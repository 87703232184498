import React from 'react'
import PropTypes from 'prop-types'
import { FLOW_TYPES, ORIGINS } from '../../../data-tagging/constants'
import TrackingLink from '../../../functional-components/TrackingLink'

const ServiceInfoLink = ({ text, link }) => {
  return (
    <div className="service-link">
      <TrackingLink
        href={link}
        target="_blank"
        sectionName="header_info_link"
        flowType={FLOW_TYPES.HEADER}
        origin={ORIGINS.HEADER}
        sectionItem={text}
      >
        {text}
      </TrackingLink>
    </div>
  )
}

ServiceInfoLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

export default ServiceInfoLink
