import React from 'react'
import LateralPanelMessage from '../../../LateralPanel/components/LateralPanelMessage'
import GenericPanelTitle from '../../../LateralPanel/components/GenericPanelTitle'
import texts from '../../../../common/texts.json'

export const PromotionsValidationTitle = () => (
  <GenericPanelTitle>{texts.PROMOTIONS_VALIDATE.VALIDATION_PANEL_TITLE}</GenericPanelTitle>
)

const PromotionsValidationLoading = () => (
  <LateralPanelMessage
    message={texts.PROMOTIONS_VALIDATE.VALIDATION_TEXT}
    imgProps={{ alt: texts.PROMOTIONS_VALIDATE.VALIDATION_ALT_IMAGE, src: 'shoppingCart/cartCircle.svg' }}
    loading
  />
)

export default PromotionsValidationLoading
