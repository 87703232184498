import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import ContentCategories from './ContentCategories'
import { customModalStyles } from './styled'
import './index.css'
import ClientAPI from '../../common/ClientAPI'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root')

class CategoriesMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSecondLevelSection: false,
      currentCategory: {},
      showPreselectedCategory: true,
    }
    this.clientApi = new ClientAPI()
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { visible } = nextProps
    const { visible: propVisible } = this.props
    if (!propVisible && visible) {
      this.setState({ showPreselectedCategory: true })
    }
  }

  showCategories = (category) => {
    if (category) {
      this.setState({ currentCategory: category, showSecondLevelSection: true })
    }
  }

  hideCategories = () => {
    this.setState({ currentCategory: {}, showSecondLevelSection: false, showPreselectedCategory: false })
  }

  getActiveCategory = (location) => {
    try {
      const { pathname } = location

      const categories = pathname.split('/category/')[1]
      const cleanCategories = categories.replace(/_/g, ' ')
      return cleanCategories.split('/')
    } catch (e) {
      return ['', '', '']
    }
  }

  render() {
    const { showSecondLevelSection, currentCategory, showPreselectedCategory } = this.state
    const { handleClose, visible, location, windowSize, categories, links } = this.props

    const isMobile = windowSize.width <= 768
    const isIpad = windowSize.width <= 910

    const hideFirstLevelSection = isMobile && showSecondLevelSection

    const [activeCategory1, activeCategory2, activeCategory3] = showPreselectedCategory
      ? this.getActiveCategory(location)
      : ['', '', '']

    if (isMobile || isIpad) {
      customModalStyles.overlay.top = '58px'
    } else {
      customModalStyles.overlay.top = '88px'
    }

    return (
      <Modal
        isOpen={visible}
        style={customModalStyles}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        shouldFocusAfterRender
        closeTimeoutMS={500}
        onRequestClose={handleClose}
        onAfterClose={this.hideCategories}
      >
        <ContentCategories
          hideFirstLevelSection={hideFirstLevelSection}
          isMobile={isMobile}
          isIpad={isIpad}
          hideCategories={this.hideCategories}
          showCategories={this.showCategories}
          categories={categories}
          links={links}
          showPreselectedCategory={showPreselectedCategory}
          handleClose={handleClose}
          activeCategory1={activeCategory1}
          activeCategory2={activeCategory2}
          activeCategory3={activeCategory3}
          showSecondLevelSection={showSecondLevelSection}
          currentCategory={currentCategory}
        />
      </Modal>
    )
  }
}

CategoriesMenu.propTypes = {
  categories: PropTypes.array.isRequired,
  links: PropTypes.array.isRequired,
  windowSize: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  visible: PropTypes.bool,
}

CategoriesMenu.defaultProps = {
  handleClose: () => {},
  visible: false,
}

export default CategoriesMenu
