const formatAddress = (addressObj) => {
  const { streetInput, streetNumber, apartment, selectedCommune, comments, location } = addressObj
  const { lat, lng } = location
  return {
    street: streetInput,
    number: streetNumber,
    additionalInfo: apartment,
    communeName: selectedCommune?.communeName,
    latitude: lat,
    longitude: lng,
    alias: '',
    comments,
    preferred: false,
  }
}

export { formatAddress }
