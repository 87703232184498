import { connect } from 'react-redux'
import setCategoriesMenu from '../../actions/setCategoriesMenu'
import DisplayHeader from './DisplayHeader'

const mapStateToProps = (state) => ({
  campaignConfig: state.campaignConfigReducer,
  categoriesMenu: state.categoriesMenu,
  selectedWalstoreStore: state.selectedWalstoreStore,
})

const mapDispatchToProps = (dispatch) => ({
  setCategoriesMenu: (categoriesMenu) => dispatch(setCategoriesMenu(categoriesMenu)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DisplayHeader)
