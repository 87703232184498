import axios from 'axios'
import getConfig from '../../Helpers/getConfig'
import constants from '../constants'
import { getTenant, getFlowId } from '../../Helpers'

const getFirstStoreSlots = async (dispatchType, store, address) => {
  const baseURLbff = getConfig('baseURLbff')
  const tenant = getTenant()

  const clientInstance = axios.create({
    baseURL: baseURLbff,
    timeout: constants.timeout.TWENTY_SECONDS,
  })

  let regionId
  let communeId
  let location = { lat: 0, lng: 0 }

  if (dispatchType === constants.delivery.PICKUP_TYPE) {
    regionId = store.location.region.srmsId
    communeId = store.location.commune.srmsId
  } else {
    regionId = address?.selectedState.srsId
    communeId = address?.selectedCommune.srsId

    if (address?.location) {
      location = address?.location
    }
  }

  try {
    const response = await clientInstance.request({
      url: 'slots/first',
      method: 'post',
      data: {
        location,
        regionId,
        communeId,
        dispatchType,
        storeId: store.id,
      },
      headers: {
        tenant,
        'X-FlowId': getFlowId(),
      },
    })

    return response.data
  } catch (error) {
    return null
  }
}

export default getFirstStoreSlots
