import { useCallback, useEffect } from 'react'

export const debounce = (func, wait = 200) => {
  let timeout
  const executedFunction = (...args) => {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
  executedFunction.cancel = () => {
    clearTimeout(timeout)
  }
  return executedFunction
}

export const useDebounce = (callback, delay = 1000, deps = []) => {
  const debouncedCallback = useCallback(debounce(callback, delay), [delay, ...deps])
  useEffect(() => {
    return () => {
      debouncedCallback.cancel()
    }
  }, [delay, debouncedCallback, ...deps])
  return debouncedCallback
}
