import React from 'react'
import PropTypes from 'prop-types'

import { useDeliveryContext } from '../context/DeliveryContext'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'

import { getButtonByDispatchType, getStripByDispatchType } from './utils'

export const BUTTON_TYPE = 'button'
export const STRIP_TYPE = 'strip'

const StoreSelectorWidget = ({ onClick, widgetType }) => {
  const { store } = useShoppingCartContext()
  const { dispatchType, address, slotText } = useDeliveryContext()

  const [Component, headingText] =
    widgetType === STRIP_TYPE
      ? getStripByDispatchType(dispatchType, store, address)
      : getButtonByDispatchType(dispatchType, store, address)

  const props = {
    onClick,
    headingText,
  }
  if (dispatchType) props.bodyText = slotText

  return <Component {...props} />
}

StoreSelectorWidget.propTypes = {
  onClick: PropTypes.func.isRequired,
  widgetType: PropTypes.string.isRequired,
}

export default StoreSelectorWidget
