import React from 'react'
import PropTypes from 'prop-types'
import Dotdotdot from 'react-dotdotdot'
import texts from '../../../../common/texts.json'
import './index.css'

const SizeAndColor = ({ product }) => {
  const { isVariant, attributes } = product
  const { SHOPPING_CART } = texts

  if (isVariant && attributes) {
    const { skuColor, skuSize } = attributes

    return (
      <span className="size-and-color__attributes d-block">
        {skuColor && (
          <Dotdotdot clamp={1}>
            <b>{`${SHOPPING_CART.SKU_COLOR_ATTRIBUTE}: `}</b>
            {skuColor}
          </Dotdotdot>
        )}
        {skuSize && (
          <Dotdotdot clamp={1}>
            <b>{`${SHOPPING_CART.SKU_SIZE_ATTRIBUTE}: `}</b>
            {skuSize}
          </Dotdotdot>
        )}
      </span>
    )
  }
  return null
}

SizeAndColor.propTypes = {
  product: PropTypes.object.isRequired,
}

export default SizeAndColor
