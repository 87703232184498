import { getCapitalizedText } from './../../../Helpers'
import { makeAddressObject } from './addressFormatter'

import StoreSelectorDefaultButton from '../components/DefaultHeaderButton'
import StoreSelectorDeliveryButton from '../components/DeliveryHeaderButton'
import StoreSelectorPickupButton from '../components/PickupHeaderButton'
import StoreSelectorDefaultBand from '../components/DefaultHeaderBand'
import StoreSelectorDeliveryBand from '../components/DeliveryHeaderBand'
import StoreSelectorPickupBand from '../components/PickupHeaderBand'

import constants from '../../../common/constants'
import texts from '../../../common/texts.json'

const getDefaultHeadingText = () => texts.STORE_SELECTOR.LINK

const getDeliveryHeadingText = (address) => {
  if (!address) {
    return getDefaultHeadingText()
  }

  const normalizedAddress = `${address.streetInput}${
    address.streetNumber !== texts.DELIVERY_FORM.ADDRESS_WITHOUT_NUMBER ? ` ${address.streetNumber}` : ''
  }`.toLowerCase()
  return `${texts.STORE_SELECTOR.DELIVERY_ICON_TEXT} ${getCapitalizedText(normalizedAddress)}`
}

const getDeliveryHeadingBandText = (address) =>
  `${getDeliveryHeadingText(address)}, ${address?.selectedCommune?.communeName}`

const getPickupHeadingText = (store) => `${texts.STORE_SELECTOR.PICKUP_ICON_TEXT} ${store.name}`

const getButtonByDispatchType = (dispatchType, store, address) => {
  let buttonComponent
  let headingText
  switch (dispatchType) {
    case constants.delivery.HOME_DELIVERY_TYPE:
      buttonComponent = StoreSelectorDeliveryButton
      headingText = getDeliveryHeadingText(address)
      break
    case constants.delivery.PICKUP_TYPE:
      buttonComponent = StoreSelectorPickupButton
      headingText = getPickupHeadingText(store)
      break
    default:
      buttonComponent = StoreSelectorDefaultButton
      headingText = getDefaultHeadingText()
      break
  }
  return [buttonComponent, headingText]
}

const getStripByDispatchType = (dispatchType, store, address) => {
  let headerBandComponent
  let headingText
  switch (dispatchType) {
    case constants.delivery.HOME_DELIVERY_TYPE:
      headerBandComponent = StoreSelectorDeliveryBand
      headingText = getDeliveryHeadingBandText(address)
      break
    case constants.delivery.PICKUP_TYPE:
      headerBandComponent = StoreSelectorPickupBand
      headingText = getPickupHeadingText(store)
      break
    default:
      headerBandComponent = StoreSelectorDefaultBand
      headingText = getDefaultHeadingText()
      break
  }
  return [headerBandComponent, headingText]
}

const getAddressObject = ({ placeInformation, addressNumber, storeInfo, addressHelp, addressReference }) => {
  const regularizedPlaceInformation = {
    ...placeInformation,
    location: {
      lat: placeInformation.location.lat(),
      lng: placeInformation.location.lng(),
    },
  }
  const addressObj = makeAddressObject(regularizedPlaceInformation, addressNumber, storeInfo)
  addressObj.apartment = addressHelp
  addressObj.comments = addressReference
  return addressObj
}

export {
  getButtonByDispatchType,
  getStripByDispatchType,
  getDeliveryHeadingText,
  getDeliveryHeadingBandText,
  getPickupHeadingText,
  getDefaultHeadingText,
  getAddressObject,
}
