import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { hot } from 'react-hot-loader'
import store from './store'
import Routes from './Routes'
import TenantContextProvider from './contexts/TenantContext'
import UserDataContextProvider from './contexts/UserDataContext'
import CampaignStyleConfigContextProvider from './contexts/CampaignStyleConfigContext'
import TagsContextProvider from './functional-components/Tags/context/TagsContext'
import ShoppingCartContextProvider from './functional-components/ShoppingCart/context/ShoppingCartContext'
import AnalyticsContextProvider from './contexts/AnalyticsContext'
import LateralPanelContextProvider from './functional-components/LateralPanel/context/LateralPanelContext'
import '@walmart-web/livingdesign-components/index.esm.css'
import { LivingDesignProvider } from '@walmart-web/livingdesign-components'

export const getRelocatedServicesEnv = () => {
  const search = window.location ? window.location.search : ''
  const qs = new URLSearchParams(search)
  const env = qs.get('__env')
  if (env) return env
  const sessionEnv = window.sessionStorage ? window.sessionStorage.getItem('__env') : ''
  if (sessionEnv) return sessionEnv
  return ''
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <TenantContextProvider>
          <CampaignStyleConfigContextProvider>
            <TagsContextProvider>
              <UserDataContextProvider>
                <ShoppingCartContextProvider>
                  <AnalyticsContextProvider>
                    <LateralPanelContextProvider>
                      <LivingDesignProvider>
                        <Routes />
                      </LivingDesignProvider>
                    </LateralPanelContextProvider>
                  </AnalyticsContextProvider>
                </ShoppingCartContextProvider>
              </UserDataContextProvider>
            </TagsContextProvider>
          </CampaignStyleConfigContextProvider>
        </TenantContextProvider>
      </Provider>
    )
  }
}

export default hot(module)(App)
