import { CHECKOUT_REDIRECT } from '../types'

const initState = {
  isRedirectToCheckout: false,
}
export default (state = initState, action) => {
  switch (action.type) {
    case CHECKOUT_REDIRECT:
      return { ...state, isRedirectToCheckout: true }
    default:
      return state
  }
}
