import React from 'react'

import ShoppingCartIcon from '../../ShoppingCart/icon/ShoppingCartIcon'
import { useLateralPanelContext } from '../context/LateralPanelContext'
import getPromotionItemsEvaluate from '../../../common/clients/promotionEngineClient'

const ShoppingCartHeaderIcon = () => {
  const { openShoppingCart, showPromotionsValidation } = useLateralPanelContext()

  const handleOpenShoppingCart = async () => {
    showPromotionsValidation()
    await getPromotionItemsEvaluate()
    openShoppingCart()
  }

  return <ShoppingCartIcon onClick={handleOpenShoppingCart} />
}

export default ShoppingCartHeaderIcon
