import React from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircle } from '@walmart-web/livingdesign-icons'
import HeaderButton from '../HeaderButton'

const DefaultHeaderButton = ({ onClick, headingText, leadingSize, ...rest }) => (
  <HeaderButton
    leading={<ExclamationCircle size={leadingSize} />}
    onClick={onClick}
    headingText={headingText}
    {...rest}
  />
)

DefaultHeaderButton.defaultProps = {
  leadingSize: 'medium',
}

DefaultHeaderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  leadingSize: PropTypes.string,
}

export default DefaultHeaderButton
