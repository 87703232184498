import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@walmart-web/livingdesign-components'
import { ArrowLeft as ArrowLeftIcon, Close as CloseIcon } from '@walmart-web/livingdesign-icons'

import texts from '../../../common/texts.json'

import './LoginTitle.css'

const LoginTitle = ({ onClose, onClickBack }) => (
  <div className="login-title__container">
    <div className="login-title__back-button">
      <Button variant="tertiary" onClick={onClickBack} leading={<ArrowLeftIcon />} />
    </div>
    <div className="login-title__close-button">
      <Button
        variant="tertiary"
        aria-label={texts.SHOPPING_CART.CLOSE_CART}
        leading={<CloseIcon />}
        onClick={onClose}
      />
    </div>
  </div>
)

LoginTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
}

export default LoginTitle
