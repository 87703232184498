import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './global.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './tenant.css'
import { v4 as uuidv4 } from 'uuid'
import './functional-components/Advertising/useOnScreen/polyfill'
import App from './App'
import { tagManagerInitilize } from './Helpers'
import { setPxConfig } from './Helpers/perimeterxCaptcha'

const main = async () => {
  while (!window.__ENV__) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => setTimeout(resolve, 100))
  }

  tagManagerInitilize()
  setPxConfig()
  if (typeof localStorage !== 'undefined' && localStorage !== null && !localStorage.getItem('XFlowId')) {
    localStorage.setItem('XFlowId', uuidv4())
  }
  ReactDOM.render(<App />, document.getElementById('root'))
}

main()
