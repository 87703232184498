import { SET_BANNER_SLOT_INFO } from '../types'

const initState = {
  slotId: '',
  slotName: '',
  pathname: '',
}
export default (state = initState, action) => {
  if (action.type === SET_BANNER_SLOT_INFO) {
    return Object.assign({}, state, action.payload)
  } else {
    return state
  }
}
