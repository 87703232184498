import axios from 'axios'
import axiosRetry from 'axios-retry'
import { responseModifierCampaignConfig } from './responseModifier'
import { getTenant } from '../Helpers'
import constants from './constants'

// import logger from './Logger'

const retryConfig = {
  retries: 2,
  shouldResetTimeout: true,
  retryCondition: () => true,
}

export default class ClientStorage {
  constructor(config) {
    this.configData = typeof window !== 'undefined' ? window.__ENV__ : config
    this.isTenantSod = getTenant() === constants.tenant.SOD
  }

  getStorageBaseUrlForCurrentTenant() {
    return this.isTenantSod ? this.configData.storageBaseUrlSOD : this.configData.storageBaseUrl
  }

  storageInstance() {
    const storageInstance = axios.create({
      baseURL: this.getStorageBaseUrlForCurrentTenant(),
      timeout: 6000,
    })
    axiosRetry(storageInstance, retryConfig)
    return storageInstance
  }

  getMenuCategories() {
    return new Promise((resolve, reject) => {
      const client = this.storageInstance()
      client
        .request({
          url: `/json/menu_categories.json?ts=${Date.now()}`,
          method: 'get',
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getMenuLinks() {
    return new Promise((resolve, reject) => {
      const client = this.storageInstance()
      client
        .request({
          url: `/json/menu_links.json?ts=${Date.now()}`,
          method: 'get',
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getRidiculouslyLowPricesConfig() {
    return new Promise((resolve, reject) => {
      const client = this.storageInstance()
      client
        .request({
          url: `/${this.configData.ridiculouslyLowPrice}?ts=${Date.now()}`,
          method: 'get',
        })
        .then((response) => {
          resolve({ ridiculouslyLowPricesConfig: response.data })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getHomePageConfig(useCache = false) {
    return new Promise((resolve, reject) => {
      const client = this.storageInstance()
      client
        .request({
          url: `/${this.configData.homePageConfig}${useCache ? '' : `?ts=${Date.now()}`}`,
          method: 'get',
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getCampaignConfig() {
    return new Promise((resolve, reject) => {
      const client = this.storageInstance()
      client
        .request({
          url: `/${this.configData.campaignConfig}?ts=${Date.now()}`,
          method: 'get',
        })
        .then((response) => {
          resolve(responseModifierCampaignConfig(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getRecommendedCategoryWhenNoResultsInSearch() {
    return new Promise((resolve, reject) => {
      const client = this.storageInstance()
      client
        .request({
          url: `/${this.configData.searchWithoutResultToCategory}?ts=${Date.now()}`,
          method: 'get',
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getCarouselCategories() {
    const storage = this.storageInstance()
    return storage
      .request({
        url: '/json/categoriesCarousel.json',
        method: 'get',
      })
      .then((response) => {
        return response.data
      })
      .catch(() => {
        return []
      })
  }
}
