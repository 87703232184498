import { Link } from 'react-router-dom'
import React from 'react'
import PropTypes from 'prop-types'
import { ThirdLevelHeader, ThirdLevelSection, ThirdLevelTitle, ViewAllArrow } from '../../../styled'

const configData = window.__ENV__ || { storageBaseUrl: '' }

const ThirdLevelViewMore = ({ ...props }) => {
  const {
    activeCategory2,
    activeCategory3,
    secondLevelCategory,
    hoverColorLink,
    selectedTextColor,
    handleClose,
    currentCategory,
  } = props
  const applyFilter = selectedTextColor !== '#FFF'
  const active = secondLevelCategory.label === activeCategory2 && !activeCategory3

  return (
    <div key="thirdLevelCategory-div-view-more">
      <Link
        key="thirdLevelCategory-link-view-more"
        to={`/category/${currentCategory.label.replace(/ /g, '_')}/${secondLevelCategory.label.replace(/ /g, '_')}`}
        onKeyPress={handleClose}
        onClick={handleClose}
      >
        <ThirdLevelTitle
          style={{ fontStyle: 'italic', fontWeight: 'bold' }}
          hoverColorLink={hoverColorLink}
          selectedTextColor={selectedTextColor}
          applyFilter={applyFilter}
          active={active}
          className="third-level-view-more__categories"
        >
          ver todo
          <ViewAllArrow alt="view-all-third-Level" src={`${configData.storageBaseUrl}/menuIcons/arrow_blue.svg`} />
        </ThirdLevelTitle>
      </Link>
    </div>
  )
}

const ThirdLevelCategories = ({ ...props }) => {
  const { currentCategory, activeCategory3, handleClose } = props

  const selectedTextColor =
    (currentCategory.campaignTag || currentCategory.special) && currentCategory.indicatorColor ? '#FFF' : '#414042'
  const hoverColorLink =
    (currentCategory.campaignTag || currentCategory.special) && currentCategory.indicatorColor ? 'LightGray' : '#0071ce'

  return (
    <ThirdLevelSection>
      {currentCategory.categoriesLevel2.map((secondLevelCategory, index) => {
        if (!secondLevelCategory.hidden) {
          return (
            <div key={`secondLevelCategory-div-${index}`}>
              <Link
                key={`secondLevelCategory-link-${index}`}
                to={`/category/${currentCategory.label.replace(/ /g, '_')}/${secondLevelCategory.label.replace(
                  / /g,
                  '_'
                )}`}
                onKeyPress={handleClose}
                onClick={handleClose}
              >
                <ThirdLevelHeader selectedTextColor={selectedTextColor} hoverColorLink={hoverColorLink}>
                  {secondLevelCategory.label}
                </ThirdLevelHeader>
              </Link>
              {secondLevelCategory.categoriesLevel3 &&
                secondLevelCategory.categoriesLevel3.length &&
                secondLevelCategory.categoriesLevel3.map((thirdLevelCategory, index) => {
                  const active = activeCategory3 === thirdLevelCategory.label
                  if (!thirdLevelCategory.hidden) {
                    return (
                      <div key={`thirdLevelCategory-div-${index}`} style={{ lineHeight: '2' }}>
                        <Link
                          key={`thirdLevelCategory-link-${index}`}
                          to={`/category/${currentCategory.label.replace(
                            / /g,
                            '_'
                          )}/${secondLevelCategory.label.replace(/ /g, '_')}/${thirdLevelCategory.label.replace(
                            / /g,
                            '_'
                          )}`}
                          onKeyPress={handleClose}
                          onClick={handleClose}
                        >
                          <ThirdLevelTitle
                            active={active}
                            hoverColorLink={hoverColorLink}
                            selectedTextColor={selectedTextColor}
                          >
                            {thirdLevelCategory.label}
                          </ThirdLevelTitle>
                        </Link>
                      </div>
                    )
                  }
                  return null
                })}
              <ThirdLevelViewMore
                {...props}
                secondLevelCategory={secondLevelCategory}
                hoverColorLink={hoverColorLink}
                selectedTextColor={selectedTextColor}
              />
            </div>
          )
        }
        return null
      })}
    </ThirdLevelSection>
  )
}

export default ThirdLevelCategories

ThirdLevelCategories.defaultProps = {
  activeCategory3: '',
}

ThirdLevelCategories.propTypes = {
  currentCategory: PropTypes.object.isRequired,
  activeCategory3: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
}

ThirdLevelViewMore.defaultProps = {
  activeCategory2: '',
  activeCategory3: '',
  secondLevelCategory: {},
  hoverColorLink: '',
  selectedTextColor: '',
}

ThirdLevelViewMore.propTypes = {
  activeCategory2: PropTypes.string,
  activeCategory3: PropTypes.string,
  secondLevelCategory: PropTypes.object,
  hoverColorLink: PropTypes.string,
  selectedTextColor: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  currentCategory: PropTypes.object.isRequired,
}
