import React from 'react'
import LateralPanelMessage from '../../LateralPanel/components/LateralPanelMessage'
import GenericPanelTitle from '../../LateralPanel/components/GenericPanelTitle'
import texts from '../../../common/texts.json'

export const SoldOutLoadingTitle = () => (
  <GenericPanelTitle>{texts.SOLD_OUT_PRODUCTS.VALIDATION_PANEL_TITLE}</GenericPanelTitle>
)

const SoldOutItemsLoading = () => (
  <LateralPanelMessage
    message={texts.SOLD_OUT_PRODUCTS.VALIDATION_TEXT}
    imgProps={{ alt: texts.SOLD_OUT_PRODUCTS.VALIDATION_ALT_IMAGE, src: 'shoppingCart/shoppingBag.svg' }}
    loading
  />
)

export default SoldOutItemsLoading
