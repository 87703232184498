import React from 'react'
import PropTypes from 'prop-types'
import slugify from './../../common/slugify'
import { logEventToDataCollection } from '../../data-tagging'
import { EVENTS } from '../../data-tagging/constants'
import { getSaleChannel } from '../../contexts/AnalyticsContext/utils'

const TrackingLink = ({ href, target, sectionName, sectionItem, className, children, flowType, origin }) => {
  const onEventClickTagging = ({ sectionItem, sectionUrl }) => {
    logEventToDataCollection({
      eventName: EVENTS.CLICK_PAGE_SECTION_ITEM_LINK,
      flowType: flowType,
      origin: origin,
      data: {
        sectionName,
        sectionItem,
        sectionUrl,
        saleChannel: getSaleChannel(),
      },
    })
  }

  return (
    <a
      href={href}
      target={target}
      className={className}
      onClick={() => onEventClickTagging({ sectionItem: slugify(sectionItem), sectionUrl: href })}
    >
      {children}
    </a>
  )
}

TrackingLink.default = {
  target: '_self',
}

TrackingLink.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  sectionName: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  flowType: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  sectionItem: PropTypes.string.isRequired,
}

export default TrackingLink
