import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Loadable from '@loadable/component'
import { setCategoryPageConfig, setCampaignConfig } from './actions/setConfigs'
import setSelectedWalstoreStore from './actions/setSelectedWalstoreStore'
import ScrollToTop from './ScrollToTop'
import setWindowSize from './actions/windowSize'
import setPickupStore from './actions/setPickupStore'
import ClientStorage from './common/ClientStorage'
import { getTenant } from './Helpers'
import { getRawItem } from './Helpers/localStorageHelper'
import RoutesSwitch from './routes-components/RoutesSwitch'
import constants from './common/constants'

const CategoryLoader = Loadable(() => import('./RoutesPages/Category'))
const ProductDetailLoader = Loadable(() => import('./RoutesPages/ProductDetail'))
const SearchLoader = Loadable(() => import('./RoutesPages/Search'))

class Routes extends Component {
  constructor(props) {
    super(props)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.clientStorage = new ClientStorage()
    this.tenant = getTenant()
    this.isTenantSod = this.tenant === constants.tenant.SOD
    this.isWalstore = this.configData().isWalstore === 'true'
  }

  componentDidMount() {
    const { setSelectedWalstoreStore } = this.props
    if (this.isWalstore) {
      const selectedWalstoreStore = getRawItem('selectedStore')
      if (selectedWalstoreStore) {
        setSelectedWalstoreStore(JSON.parse(selectedWalstoreStore))
      } else if (!window.location.pathname.includes('config')) {
        window.location.href = `${encodeURI(window.location.origin)}/catalogo/config`
      }
    }

    this.getCampaignConfig()
    this.updateWindowDimensions()
    this.dismissCartOnPayment()
    window.addEventListener('resize', this.updateWindowDimensions)
    setTimeout(() => {
      CategoryLoader.preload()
      ProductDetailLoader.preload()
      SearchLoader.preload()
    }, 1500)
  }

  shouldComponentUpdate(nextProps) {
    const { campaignConfig } = this.props
    if (campaignConfig.enabledStoresForPickup !== nextProps.campaignConfig.enabledStoresForPickup) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  getCampaignConfig = () => {
    const { setCampaignConfig } = this.props
    this.clientStorage.getCampaignConfig().then((response) => {
      setCampaignConfig(response)
    })
  }

  getDomainName() {
    const { hostname } = window.location
    return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1)
  }

  configData = () => window.__ENV__

  dismissCartOnPayment() {
    if (document.cookie.indexOf('orderStatus=paid') >= 0) {
      localStorage.removeItem(this.isTenantSod ? 'supermercado_shoppingCart' : 'shoppingCart')
      document.cookie = `orderStatus=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${this.getDomainName()}; path=/${
        this.tenant
      };`
      document.cookie = `orderStatus=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${this.getDomainName()}; path=/;`
    }
  }

  updateWindowDimensions() {
    const { setWindowSize } = this.props
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    return (
      <div className="wrapper">
        <Router basename={`/${this.tenant}`}>
          <ScrollToTop>
            <RoutesSwitch configData={this.configData()} isWalstore={this.isWalstore} />
          </ScrollToTop>
        </Router>
      </div>
    )
  }
}

Routes.defaultProps = {
  items: {},
  attribute: {},
  limit: 0,
  translations: '',
}

Routes.propTypes = {
  setWindowSize: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setCategoryPageConfig: PropTypes.func.isRequired,
  setCampaignConfig: PropTypes.func.isRequired,
  setSelectedWalstoreStore: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setPickupStore: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  items: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  attribute: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  limit: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  translations: PropTypes.string,
  campaignConfig: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  setWindowSize: (windowSize) => dispatch(setWindowSize(windowSize)),
  setPickupStore: (pickupStore) => dispatch(setPickupStore(pickupStore)),
  setCategoryPageConfig: (config) => dispatch(setCategoryPageConfig(config)),
  setCampaignConfig: (config) => dispatch(setCampaignConfig(config)),
  setSelectedWalstoreStore: (store) => dispatch(setSelectedWalstoreStore(store)),
})

const mapStateToProps = (state) => ({
  campaignConfig: state.campaignConfigReducer,
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
