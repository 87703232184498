import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from '@walmart-web/livingdesign-components'
import LazyLoadImageContainer from '../../../components/LazyLoadImageContainer'
import AlternativeProductPrices from './AlternativeProductPrices'
import { productUrl, getMaxSalesUnitsForProduct } from '../../../Helpers'
import { useTenant } from '../../../contexts/TenantContext'
import useShoppingCart from '../../../functional-components/ShoppingCart/hooks/useShoppingCart'
import constants from '../../../common/constants'
import CustomButton from '../../../functional-components/CustomButton'
import CartQuantityStepper from '../../../components/ProductCard/ProductCardButton/components/CartQuantityStepper'
import texts from '../../../common/texts.json'
import { validateSlotBanner } from '../../../common/advisorUtil'

const AlternativeProduct = ({ item, isMobile, origin, flowType }) => {
  const { handleAddToCart, handleRemoveOneFromCart, getProductQuantityInCart } = useShoppingCart()
  const { groceryBoxSets } = useSelector((state) => state.campaignConfigReducer)
  const bannerSlotInfo = useSelector((s) => validateSlotBanner(s.advertisingDisplay))
  const listProduct = groceryBoxSets || []
  const { sku, displayName, brand, images } = item
  const { tenant } = useTenant()
  const itemUrl = productUrl(tenant, item)
  const productQuantity = getProductQuantityInCart(item)
  const maxUnits = getMaxSalesUnitsForProduct(listProduct, item)
  const hasSalesUnits = maxUnits !== constants.maxQuantity.UNLIMITED

  const handleAddProductToCart = () =>
    handleAddToCart({ product: item, listProduct, origin, shouldOpenCart: true, flowType, bannerSlotInfo })

  const handleRemoveProductFromCart = () => handleRemoveOneFromCart(item, origin, flowType)

  const renderButton = () => {
    if (productQuantity > 0)
      return (
        <CartQuantityStepper
          hit={item}
          productQuantity={productQuantity}
          origin={origin}
          validateUnit={hasSalesUnits}
          onAddToCart={handleAddProductToCart}
          onRemoveOneFromCart={handleRemoveProductFromCart}
        />
      )

    return (
      <CustomButton
        title={texts.COMMON.ADD}
        onClick={handleAddProductToCart}
        className="walmart-add-cart-button alternative-item-add-cart_button"
        onKeyPress={handleAddProductToCart}
      />
    )
  }

  return (
    <div className="alternative-item__container">
      <div className="alternative-item__item">
        <div className="alternative-item__image">
          <Link href={itemUrl}>
            <LazyLoadImageContainer
              id="lazy-img"
              className="img-fluid m-auto"
              alt={`${sku} - ${displayName}`}
              sku={sku}
              hitImage={images?.mediumImage}
            />
          </Link>
        </div>
        <div className={`alternative-item__description--${isMobile ? 'mobile' : 'desktop'}`}>
          <a className="alternative-item__description-text" href={itemUrl}>
            {brand}, {displayName}
          </a>
          <AlternativeProductPrices item={item} />
        </div>
        <div className="alternative-item-button__container">{renderButton()}</div>
      </div>
    </div>
  )
}

AlternativeProduct.propTypes = {
  item: PropTypes.object.isRequired,
  campaignConfig: PropTypes.array,
  isMobile: PropTypes.bool,
  origin: PropTypes.string,
  flowType: PropTypes.string,
}

export default AlternativeProduct
