import styled from 'styled-components'

export const CategoryMobileLabel = styled.a`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 21px;
  width: 100%;
  height: 46px;
  background-color: #e7f4ff;
`

export const FirstLevelContainer = styled.div`
  background: #e7f4ff; 
  height: 100vh; 
  overflow: scroll;
  padding-bottom: 88px;
  width: 22vw;
  transition: all 500ms ease-in-out;
  transform: ${({ show }) => (show ? 'translateX(0px)' : 'translateX(-100%)')};
  
  @media (max-width: 1150px) {
    width: 32vw;
  }
  
  @media (max-width: 768px) {
    padding-bottom: 58px;
    width: 100vw;
    padding-bottom: 14rem;
  }
`

export const FirstLevelTitle = styled.div`
  color: ${props => (props.defaultTextColor ? props.defaultTextColor : '#414042')};
  font-size: 16px;
  margin-right: 25px;

  @media (max-width: 1150px) {
    font-size: 14px;
  }

`
