import { SET_HOME_PAGE_CONFIG, SET_CATEGORY_PAGE_CONFIG, SET_CAMPAIGN_CONFIG } from '../types'

export const setHomePageConfig = (config) => (dispatch) => {
  dispatch({
    type: SET_HOME_PAGE_CONFIG,
    payload: {
      config,
    },
  })
}

export const setCategoryPageConfig = (config) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_PAGE_CONFIG,
    payload: {
      config,
    },
  })
}

export const setCampaignConfig = (config) => (dispatch) => {
  dispatch({
    type: SET_CAMPAIGN_CONFIG,
    payload: {
      config,
    },
  })
}
