import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner } from '@walmart-web/livingdesign-components'
import texts from '../../../common/texts.json'
import '../index.css'

const ErrorMessage = ({ onClick, errorImage, errorMessage, buttonText, isSpinnerLoading, isMobile }) => (
  <div className={`substitute-products__alert${isMobile ? '-mobile' : ''}`}>
    <div className="substitute-products__alert__image-container">
      <img src={`/catalogo/images/${errorImage}`} alt={errorMessage} />
    </div>
    <div className="substitute-products__alert__head-line">
      <h1>{texts.ERROR_MODAL.TITLE}</h1>
    </div>
    <div className="substitute-products__alert__head-line-two">
      <h3>{errorMessage}</h3>
    </div>
    <div>
      <Button className="substitute-products__alert__button" variant="primary" size="medium" onClick={onClick}>
        {isSpinnerLoading ? <Spinner size="small" color="white" /> : <span>{buttonText}</span>}
      </Button>
    </div>
  </div>
)

ErrorMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
  errorImage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isSpinnerLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default ErrorMessage
