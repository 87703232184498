import { SET_PICKUP_STORE } from '../types'

const initState = {
  pickupStore: {
    id: null,
    name: null,
    address: null,
    commune: null,
  },
}
export default (state = initState.pickupStore, action) => {
  switch (action.type) {
    case SET_PICKUP_STORE:
      return {
        ...state,
        ...action.payload.pickupStore,
      }
    default:
      return state
  }
}
