import { setRawItem, getRawItem } from '../../Helpers/localStorageHelper'

const setShouldOpenPromotionalPopUp = (itemKey, value) => {
  setRawItem(`shouldOpen${itemKey}`, value)
}

const shouldOpenPromotionalPopUp = (itemKey) => {
  const shouldOpenPromotionalPopUpValue = getRawItem(`shouldOpen${itemKey}`)
  return !shouldOpenPromotionalPopUpValue || shouldOpenPromotionalPopUpValue === 'true'
}

export { setShouldOpenPromotionalPopUp, shouldOpenPromotionalPopUp }
