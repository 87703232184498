import React from 'react'
import LoadingBreadcrum from '../LoadingBreadcrum'
import LoadingSkeleton from '../LoadingSkeleton'
import './index.css'

const LoadingProductDetail = () => (
  <div className="loading-product-detail">
    <LoadingBreadcrum />
    <div className="loading-product-detail__product-card">
      <div>
        <LoadingSkeleton type="product-image" />
      </div>
      <div>
        <LoadingSkeleton type="h2" spaceBottom />
        <LoadingSkeleton type="h1" spaceBottom />
        <LoadingSkeleton type="p" spaceBottom />
        <LoadingSkeleton type="big" spaceBottom />
        <LoadingSkeleton type="p" spaceBottom />
        <LoadingSkeleton type="p" spaceBottom />
        <button disabled type="button" className="loading-product-detail__product-card__button">
          <LoadingSkeleton type="p" />
        </button>
      </div>
    </div>
  </div>
)

export default LoadingProductDetail
