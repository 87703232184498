import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button } from '@walmart-web/livingdesign-components'
import { Close as CloseIcon } from '@walmart-web/livingdesign-icons'
import texts from '../../common/texts.json'
import RenderInnerHTML from '../../components/RenderInnerHTML'
import textTemplate from '../../common/textTemplate'
import { priceEnhancer } from '../../Helpers'
import {
  shouldOpenPromoteLiderAppPopUp,
  setShouldOpenPromoteLiderAppPopUp,
  setLastTimeLiderAppPopUpShown,
} from './utils'
import './index.css'

const PromoteLiderAppPopUp = ({ setIsPromoteLiderAppPopUpClosed }) => {
  const [isOpen, setIsOpen] = useState(shouldOpenPromoteLiderAppPopUp())

  if (isOpen) {
    document.body.style.overflowY = 'hidden'
  }

  const closePopUp = () => {
    setIsOpen(false)
    setShouldOpenPromoteLiderAppPopUp('false')
    setLastTimeLiderAppPopUpShown(new Date())
    setIsPromoteLiderAppPopUpClosed(true)
    document.body.style.overflowY = 'visible'
  }

  const logoLiderApp = '/catalogo/images/promoteLiderAppPopUp/logo.svg'
  const mobileImage = '/catalogo/images/promoteLiderAppPopUp/mobile-image.svg'
  const liderAppLink = 'https://app.lider.cl/links/exp-sticky'

  const { freeDeliveryThreshold } = useSelector((state) => state.campaignConfigReducer)
  const minimunFreeShippingAmount = freeDeliveryThreshold ? priceEnhancer(freeDeliveryThreshold) : ''
  const popUpDescription = textTemplate(texts.LIDER_APP_POP_UP.DESCRIPTION, { minimunFreeShippingAmount })

  return (
    <>
      {isOpen && (
        <div data-testid="promote-lider-app-test-id" className="promote-lider-app__popup">
          <div className="promote-lider-app__top">
            <div className="promote-lider-app__close-button-container">
              <Button
                className="promote-lider-app__close-button"
                variant="tertiary"
                aria-label="Cerrar"
                leading={<CloseIcon />}
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="promote-lider-app__container">
            <div className="promote-lider-app__logo">
              <img src={logoLiderApp} alt="Lider App" />
            </div>
            <div className="promote-lider-app__mobile-image">
              <img src={mobileImage} alt="Lider App" />
            </div>
            <div className="promote-lider-app__title">{texts.LIDER_APP_POP_UP.TITLE}</div>
            <div className="promote-lider-app__description">
              <RenderInnerHTML html={popUpDescription} />
            </div>
            <div className="promote-lider-app__bottom-links">
              <div className="promote-lider-app__download-link-container">
                <a href={liderAppLink} className="promote-lider-app__download-link">
                  <span className="promote-lider-app__download-link-text">{texts.LIDER_APP_POP_UP.DOWNLOAD_TEXT}</span>
                </a>
              </div>
              <div className="promote-lider-app__web-link-container">
                <button type="button" className="promote-lider-app__web-link" onClick={closePopUp}>
                  {texts.LIDER_APP_POP_UP.KEEP_IN_WEB_VERSION}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

PromoteLiderAppPopUp.propTypes = {
  setIsPromoteLiderAppPopUpClosed: PropTypes.func.isRequired,
}

export default PromoteLiderAppPopUp
