import { getConfigData } from '../../../Helpers'
import constants from '../../../common/constants'
const {
  searchPlaceholder: { PDC_TEXT, SOD_TEXT, CATEX_TEXT },
  tenant: { SOD },
} = constants
export const escapeRegexChars = (str) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')

const addQueryParam = (queryValue) => `query=${encodeURIComponent(queryValue)}`

export const searchLink = (queryValue) => `/search?${addQueryParam(queryValue)}`

export const productSuggestionLink = (sku, queryValue) => {
  const productPath = `/product/sku/${sku}`
  return `${productPath}?${addQueryParam(queryValue)}`
}

export const categorySuggestionLink = (categoryName, queryValue) => {
  const categoryPath = `/category/${categoryName.replace(/ /g, '_')}`
  return `${categoryPath}?${addQueryParam(queryValue)}`
}

export const grocerySearchLink = (queryValue) => {
  const query = encodeURIComponent(queryValue)
  const { liderGrocery } = getConfigData()
  return `${liderGrocery}/search?Ntt=${query}&ost=${query}`
}

export const getPlaceHolder = (tenant) => {
  if (getConfigData().isWalstore === 'true') {
    return PDC_TEXT
  }
  if (tenant === SOD) {
    return SOD_TEXT
  }
  return CATEX_TEXT
}
