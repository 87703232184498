import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ClientAPI from '../../../common/ClientAPI'
import setSupermarketItems from '../../../actions/setSupermarketItems'
import {
  Suggestion, ProductSuggestion, CategorySuggestion, SearchSuggestion, SearchSuggestionGR,
} from './SearchSuggestions'
import loggerPromise from '../../../common/Logger'
import { useTenant } from '../../../contexts/TenantContext'
import texts from '../../../common/texts.json'


const AutoComplete = ({
  setSupermarketItems,
  dontShowResultsInGlobalSearch,
  products,
  inputValue,
  showResultsInGlobalSearch,
  supermarketItems,
  handleClickOutside,
  categories,
  suggestions,
}) => {
  const { tenant } = useTenant()
  useEffect(() => {
    const loadSuperMarketItems = async () => {
      const clientAPI = new ClientAPI()
      try {
        const { items } = await clientAPI.getSupermarketItems()
        if (items && items.length > 0) {
          setSupermarketItems(items)
        }
      } catch (ex) {
        loggerPromise().then(logger => logger.error({ errorObject: ex }, 'Cant get supermarketItems'))
      }
      
    }
    loadSuperMarketItems()
  }, [])

  if (!showResultsInGlobalSearch || inputValue === '') {
    return null
  }
  const shouldShowSupermarketLink = tenant === 'catalogo' && supermarketItems.filter(item => inputValue.toLowerCase().includes(item.toLowerCase())).length > 0

  if (products.length === 0 && categories.length === 0 && suggestions.length === 0) {
    return shouldShowSupermarketLink ? (
      <div className="search-results-list p-10, bg-white">
        <SearchSuggestionGR inputValue={inputValue} onClick={dontShowResultsInGlobalSearch} />
      </div>
    ) : (
      <div className="search-results-list p-10, bg-white">
        <SearchSuggestion inputValue={inputValue} onClick={dontShowResultsInGlobalSearch} />
      </div>
    )
  }

  const commonProps = {
    inputValue,
    onClick: dontShowResultsInGlobalSearch,
  }
  const suggestionsProps = {
    ...commonProps,
    onClickLink: handleClickOutside,
  }

  return (
    <div className="search-results-list p-10, bg-white">
      {products.length > 0 && (<div className="search-result p-10 pt-15">{texts.SEARCH_SUGGESTIONS.PRODUCT_TITLE}</div>)}
      {products.map((product, ix) => (
        <ProductSuggestion key={`product-${ix}`} product={product} {...suggestionsProps} />
      ))}
      {categories.length > 0 && (
      <div className="search-result p-10 pt-15">{texts.SEARCH_SUGGESTIONS.CATEGORY_TITLE}</div>)}
      {categories.map((category, ix) => (
        <CategorySuggestion key={`category-${ix}`} category={category} {...suggestionsProps} />
      ))}
      {suggestions.map((suggestion, ix) => (
        <Suggestion key={`suggestion-${ix}`} name={suggestion} {...suggestionsProps} />
      ))
        }
      {(products.length > 0 || categories.length > 0 || suggestions.length > 0) && (
      <hr className="mt-10 mb-0" />)}
      <SearchSuggestion {...commonProps} />
      {shouldShowSupermarketLink && (
      <SearchSuggestionGR {...commonProps} className="search-word__text pt-0" />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  supermarketItems: state.supermarketItemsReducer.supermarketItems,
})

const mapDispatchToProps = dispatch => ({
  setSupermarketItems: supermarketItems => dispatch(setSupermarketItems(supermarketItems)),
})

AutoComplete.defaultProps = {
  products: [],
  supermarketItems: [],
  showResultsInGlobalSearch: false,
  inputValue: '',
  categories: [],
  suggestions: [],
}

AutoComplete.propTypes = {
  products: PropTypes.array,
  supermarketItems: PropTypes.array,
  handleClickOutside: PropTypes.func.isRequired,
  setSupermarketItems: PropTypes.func.isRequired,
  dontShowResultsInGlobalSearch: PropTypes.func.isRequired,
  showResultsInGlobalSearch: PropTypes.bool,
  inputValue: PropTypes.string,
  categories: PropTypes.array,
  suggestions: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete)
