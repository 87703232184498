import React from 'react'
import PropTypes from 'prop-types'

import { Button, Modal as CleanupCartWarningModal } from '@walmart-web/livingdesign-components'

import texts from '../../../../common/texts.json'
import useShoppingCart from '../../hooks/useShoppingCart'

import './index.css'

const CleanupCartWarning = ({ modalIsOpen, onClose }) => {
  const { handleEmptyCart } = useShoppingCart()

  const handleOnClick = () => {
    handleEmptyCart()
    onClose()
  }

  return (
    <CleanupCartWarningModal
      isOpen={modalIsOpen}
      onClose={onClose}
      size="small"
      title={<div className="cleanup-cart-warning__modal-title">{texts.SHOPPING_CART.CLEANUP_WARNING_TITLE}</div>}
      actions={(
        <div className="cleanup-cart-warning__modal-actions">
          <div className="cleanup-cart-warning__modal-approval-button">
            <Button variant="primary" size="medium" onClick={handleOnClick}>
              {texts.SHOPPING_CART.CLEANUP_WARNING_APPROVAL}
            </Button>
          </div>
          <div className="cleanup-cart-warning__modal-rejection-button">
            <Button variant="secondary" size="medium" onClick={onClose}>
              {texts.SHOPPING_CART.CLEANUP_WARNING_REJECTION}
            </Button>
          </div>
        </div>
      )}
    >
      <div className="cleanup-cart-warning__modal-text">{texts.SHOPPING_CART.CLEANUP_WARNING_CONTEXT}</div>
    </CleanupCartWarningModal>
  )
}

CleanupCartWarning.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CleanupCartWarning
