import React from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircle } from '@walmart-web/livingdesign-icons'
import HeaderBand from '../HeaderBand'

const DefaultHeaderBand = ({ onClick, headingText, leadingSize, ...rest }) => (
  <HeaderBand
    onClick={onClick}
    headingText={headingText}
    leading={<ExclamationCircle size={leadingSize} />}
    {...rest}
  />
)

DefaultHeaderBand.defaultProps = {
  leadingSize: 'small',
}

DefaultHeaderBand.propTypes = {
  leadingSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
}

export default DefaultHeaderBand
