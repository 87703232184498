import React from 'react'
import texts from '../../../common/texts.json'
import { Button } from '@walmart-web/livingdesign-components'
import { getConfigData } from '../../../Helpers'
import PropTypes from 'prop-types'

const ErrorPage = ({ onClosePanel }) => {
  const { liderGrocery } = getConfigData()
  const ERROR_CONFIRMATION_IMAGE = '/catalogo/images/error_confirm.svg'

  const buttonText = onClosePanel ? texts.LOGIN.TEXT_ERROR_BUTTON : texts.COMMON.GO_HOME_TEXT

  const handleOnClick = () => {
    if (!onClosePanel) {
      window.location.href = liderGrocery
      return
    }

    onClosePanel()
  }
  return (
    <>
      <div className="login-error-page">
        <div className="login-error-page__container">
          <div className="login-error-page__image">
            <img alt={texts.LOGIN.ERROR_CONFIRMATION_IMAGE_ALT} src={ERROR_CONFIRMATION_IMAGE} />
          </div>
          <div className="login-error-page__title">{texts.LOGIN.ERROR_CONFIRMATION_TITLE}</div>
          <div className="login-error-page__content">
            <p>{texts.LOGIN.GENERIC_ERROR}</p>
            <div className="login-error-page__button">
              <Button
                variant="primary"
                size="large"
                onClick={handleOnClick}
                isFullWidth
                type="submit"
                data-testid="login-error-page-button"
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ErrorPage.defaultProps = {
  onClosePanel: null,
}

ErrorPage.propTypes = {
  onClosePanel: PropTypes.func,
}

export default ErrorPage
