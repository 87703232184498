import { setRawItem, getRawItem } from '../../Helpers/localStorageHelper'

const ITEM_KEY_POP_UP = 'shouldOpenPromoteLiderAppPopUp'
const ITEM_KEY_TIME_OUT = 'lastTimeLiderAppPopUpShown'
const ONE_HOUR_MILLISECONDS = 60 * 60 * 1000

const setShouldOpenPromoteLiderAppPopUp = (value) => {
  setRawItem(ITEM_KEY_POP_UP, value)
}

const setLastTimeLiderAppPopUpShown = (value) => {
  setRawItem(ITEM_KEY_TIME_OUT, value)
}

const shouldOpenPromoteLiderAppPopUp = () => {
  const shouldOpenPromoteLiderAppPopUpValue = getRawItem(ITEM_KEY_POP_UP)

  if (!shouldOpenPromoteLiderAppPopUpValue) {
    setShouldOpenPromoteLiderAppPopUp('true')
    return true
  }

  const shouldOpenPromoteLiderAppPopUp = shouldOpenPromoteLiderAppPopUpValue === 'true'
  const lastTimeLiderAppPopUpShownValue = getRawItem(ITEM_KEY_TIME_OUT)

  if (!shouldOpenPromoteLiderAppPopUp && lastTimeLiderAppPopUpShownValue) {
    const lastTimeLiderAppPopUpShown = new Date(lastTimeLiderAppPopUpShownValue)
    const currentDate = new Date()
    const diffMilliseconds = currentDate - lastTimeLiderAppPopUpShown

    if (diffMilliseconds >= ONE_HOUR_MILLISECONDS) {
      setShouldOpenPromoteLiderAppPopUp('true')
      return true
    }
  }

  return shouldOpenPromoteLiderAppPopUp
}

export { shouldOpenPromoteLiderAppPopUp, setShouldOpenPromoteLiderAppPopUp, setLastTimeLiderAppPopUpShown }
