import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FirstLevelCategories from '../FirstSection/FirstLevelCategories'
import SecondLevelCategories from '../FirstSection/SecondSection/SecondLevelCategories'
import { CategoryMobileLabel, FirstLevelContainer, FirstLevelTitle } from './styled'
import FirstLevelLinks from '../FirstSection/FirstLevelLinks'

class MainCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  render() {
    const { show } = this.state
    const {
      activeCategory1,
      activeCategory2,
      activeCategory3,
      categories,
      currentCategory,
      handleClose,
      links,
      hideFirstLevelSection,
      isMobile,
      showCategories,
      hideCategories,
      showSecondLevelSection,
      showPreselectedCategory,
    } = this.props

    return (
      <div className="row" data-testid="main-categories-test-id">
        {!hideFirstLevelSection && (
          <FirstLevelContainer show={show}>
            {isMobile && (
              <CategoryMobileLabel>
                <FirstLevelTitle>Categorías</FirstLevelTitle>
              </CategoryMobileLabel>
            )}
            <FirstLevelLinks links={links} hideCategories={hideCategories} />
            <FirstLevelCategories
              isMobile={isMobile}
              currentCategory={currentCategory}
              showPreselectedCategory={showPreselectedCategory}
              showCategories={showCategories}
              activeCategory1={activeCategory1}
              categories={categories}
              showSecondLevelSection={showSecondLevelSection}
            />
          </FirstLevelContainer>
        )}
        {showSecondLevelSection && (
          <SecondLevelCategories
            currentCategory={currentCategory}
            activeCategory2={activeCategory2}
            activeCategory3={activeCategory3}
            isMobile={isMobile}
            hideCategories={hideCategories}
            handleClose={handleClose}
          />
        )}
      </div>
    )
  }
}

MainCategories.defaultProps = {
  activeCategory1: '',
  activeCategory2: '',
  activeCategory3: '',
  categories: [],
  currentCategory: {},
  links: [],
}

MainCategories.propTypes = {
  hideFirstLevelSection: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showPreselectedCategory: PropTypes.bool.isRequired,
  showSecondLevelSection: PropTypes.bool.isRequired,
  activeCategory1: PropTypes.string,
  activeCategory2: PropTypes.string,
  activeCategory3: PropTypes.string,
  categories: PropTypes.array,
  currentCategory: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  showCategories: PropTypes.func.isRequired,
  hideCategories: PropTypes.func.isRequired,
  links: PropTypes.array,
}

export default MainCategories
