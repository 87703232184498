import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import Overlay from '../../Overlay'

export const HeaderContext = createContext({
  loginMenuIsOpen: false,
  setLoginMenuIsOpen: () => {},
})

const Provider = ({ children }) => {
  const [loginMenuIsOpen, setLoginMenuIsOpen] = useState(false)

  return (
    <HeaderContext.Provider
      value={{
        loginMenuIsOpen,
        setLoginMenuIsOpen,
      }}
    >
      {children}
      {loginMenuIsOpen && <Overlay />}
    </HeaderContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useHeaderContext = () => useContext(HeaderContext)

export default Provider
