import React from 'react'

import { useShoppingCartContext } from '../context/ShoppingCartContext'

import texts from '../../../common/texts.json'

import './ShoppingCartTitle.css'
import GenericPanelTitle from '../../LateralPanel/components/GenericPanelTitle'

const ShoppingCartTitle = () => {
  const { cartQuantity } = useShoppingCartContext()
  return (
    <GenericPanelTitle>
      {texts.SHOPPING_CART.CART}
      {texts.COMMON.SPACE}
      <span className="shopping-cart__title-quantity">{`(${cartQuantity})`}</span>
    </GenericPanelTitle>
  )
}

export default ShoppingCartTitle
