import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Button, useSnackbar, Spinner } from '@walmart-web/livingdesign-components'
import { productUrl } from '../../../Helpers'
import { useTenant } from '../../../contexts/TenantContext'
import LazyLoadImageContainer from '../../../components/LazyLoadImageContainer'
import useShoppingCart from '../../ShoppingCart/hooks/useShoppingCart'
import getPromotionItemsEvaluate from '../../../common/clients/promotionEngineClient'
import WlmPrice from '../../ProductPrices/components/WlmPrice'
import RegularUnitPrice from '../../ProductPrices/components/RegularUnitPrice'
import Price from '../../ProductPrices/components/Price'
import { getItemSaleDiscount } from '../../ShoppingCart/price.utils'
import textTemplate from '../../../common/textTemplate'
import texts from '../../../common/texts.json'
import './SoldOutItem.css'

const SoldOutItem = ({ item, isOriginIntegrationEnable }) => {
  const { tenant } = useTenant()
  const { handleRemoveSoldOutItem, handleReplaceSoldOutItem } = useShoppingCart()
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)

  const { sku, displayName, brand, price, quantity, images } = item
  const packSize = price.packSize || 0
  const isPack = packSize > 0
  const itemPrice = isPack ? price.packPrice : price.BasePriceSales
  const itemDiscount = isPack ? 0 : getItemSaleDiscount(item)

  const handleRemoveFromCart = () => {
    handleRemoveSoldOutItem(item)
    getPromotionItemsEvaluate()
  }

  const handleReplaceItem = async () => {
    setIsSpinnerLoading(true)
    await handleReplaceSoldOutItem(item, isOriginIntegrationEnable)
    setIsSpinnerLoading(false)
  }

  const displayRegularPrice = () => {
    if (isPack) {
      return <RegularUnitPrice price={price.BasePriceSales} location="shopping-cart" />
    }
    return price.BasePriceReference > price.BasePriceSales ? (
      <div className="d-flex">
        <Price price={price.BasePriceReference} className="product-item__saving-price" />
      </div>
    ) : null
  }

  const displayItemAvailability = () => {
    if (!item.available || !item.maxAvailable) {
      return texts.SOLD_OUT_PRODUCTS.SOLD_OUT
    }
    return textTemplate(texts.SOLD_OUT_PRODUCTS.UNITS_LEFT, { units: item.maxAvailable })
  }

  const displayQuantityAdded = () => textTemplate(texts.SOLD_OUT_PRODUCTS.YOU_ADDED, { quantity })

  return (
    <div className="sold-out-item__container">
      <div className="sold-out-item__item">
        <div className="sold-out-item__image">
          <Link href={productUrl(tenant, item)}>
            <LazyLoadImageContainer
              id="lazy-img"
              className="img-fluid m-auto"
              alt={`${sku} - ${displayName}`}
              sku={sku}
              hitImage={images?.mediumImage}
            />
          </Link>
        </div>
        <div className="sold-out-item__description">
          <a className="sold-out-item__description-text" href={productUrl(tenant, item)}>
            {`${brand}, ${displayName}`}
          </a>
          <WlmPrice
            price={itemPrice}
            packQuantity={packSize}
            discount={itemDiscount}
            priceClass="product-item__price-promo-description"
            discountClass="product-item__discount-percentage"
          />
          {displayRegularPrice()}
        </div>
        <div className="sold-out-item__availability">
          <span className="sold-out-item__available">{displayItemAvailability()}</span>
          <span className="sold-out-item__quantity-added">{displayQuantityAdded()}</span>
        </div>
      </div>
      <div className="sold-out-item__buttons">
        <Button
          variant="tertiary"
          size="medium"
          onClick={handleRemoveFromCart}
          className="sold-out-item__button-delete"
        >
          {texts.COMMON.DELETE}
        </Button>
        <Button variant="secondary" size="small" onClick={handleReplaceItem} className="sold-out-item__button-replace">
          {isSpinnerLoading ? (
            <Spinner size="small" className="sold-out-item__button-replace__spinner" />
          ) : (
            <span>{texts.COMMON.REPLACE}</span>
          )}
        </Button>
      </div>
    </div>
  )
}

SoldOutItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default SoldOutItem
