import React from 'react'
import PropTypes from 'prop-types'
import './SavingText.css'

const SavingText = ({ className, children }) => <span className={`saving-text ${className}`}>{children}</span>

SavingText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
}

SavingText.defaultProps = {
  className: '',
}

export default SavingText
