const valiedateSessionStorage = () => {
  return typeof localStorage !== 'undefined' && localStorage !== null
}

const getItem = (itemKey) => {
  let result
  try {
    if (valiedateSessionStorage()) {
      result = sessionStorage.getItem(itemKey)
    }
  } catch (error) {
    console.error(error)
  }

  return result
}

const setItem = (itemKey, value) => {
  try {
    if (valiedateSessionStorage()) {
      sessionStorage.setItem(itemKey, value)
    }
  } catch (error) {
    console.error(error)
  }
}

const removeItem = (itemKey) => {
  try {
    if (valiedateSessionStorage()) {
      sessionStorage.removeItem(itemKey)
    }
  } catch (error) {
    console.error(error)
  }
}

const getJSONSessionItem = (itemKey) => {
  const result = getItem(itemKey)
  return result ? JSON.parse(result) : undefined
}

const saveSessionItemAsJSON = (itemKey, value) => setItem(itemKey, JSON.stringify(value))

export { getItem, setItem, removeItem, getJSONSessionItem, saveSessionItemAsJSON }
