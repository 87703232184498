import React from 'react'
import { Skeleton } from '@walmart-web/livingdesign-components'
import { MaxWidthContainerBanners } from '../../../../components/MaxWidthContainer/index.js'
import {
  CarouselWrapper as CarouselWrapperStyled,
  BannersSection as BannersSectionStyled,
} from '../../../../components/CampaignHomeStyledComponents/index.js'
const BannersSectionLoader = () => (
  <>
    <BannersSectionStyled
      className="container-carousel walmart-home-scroll-carousel-mb"
      data-testid="banners-section-loader"
    >
      <MaxWidthContainerBanners>
        <CarouselWrapperStyled
          customStyle={{
            borderRadius: '0 0 16px 16px',
            imageMinHeight: '20vh',
            marginBottom: '30px',
            buttonSize: '28px',
          }}
        >
          <Skeleton height="390px" variant="rectangle" />
        </CarouselWrapperStyled>
      </MaxWidthContainerBanners>
    </BannersSectionStyled>
  </>
)

export default BannersSectionLoader
