import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@walmart-web/livingdesign-components'
import { ChevronLeft as ChevronLeftIcon } from '@walmart-web/livingdesign-icons'

import './GenericPanelTitle.css'

const GenericPanelTitle = ({ onClickBack, children }) => {
  const shouldDisplayBackIcon = onClickBack !== undefined
  return (
    <div className="generic-panel-title__container">
      {shouldDisplayBackIcon && (
        <div className="generic-panel-title__back-button">
          <Button variant="tertiary" onClick={onClickBack} leading={<ChevronLeftIcon />} />
        </div>
      )}
      <div className="generic-panel-title__content">{children}</div>
    </div>
  )
}

GenericPanelTitle.propTypes = {
  onClickBack: PropTypes.func,
  children: PropTypes.node.isRequired,
}

GenericPanelTitle.defaultProps = {
  onClickBack: undefined,
}

export default GenericPanelTitle
