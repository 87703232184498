import { SET_SELECTED_WALSTORE_STORE } from '../types'

export default (selectedWalstoreStore) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_WALSTORE_STORE,
    payload: {
      selectedWalstoreStore,
    },
  })
}
