import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from '@walmart-web/livingdesign-components'
import './index.css'

const ErrorModal = ({ headLineOne, headLineTwo, isOpen, onClose, acceptButtonText }) => (
  <Modal isOpen={isOpen} onClose={onClose} size="small" title={<div />}>
    <div className="lateral-panel__modal">
      <div className="lateral-panel__modal__image-container">
        <img src="/catalogo/images/error-message-media.svg" alt="" />
      </div>
      <div className="lateral-panel__modal__head-line">
        <h1>{headLineOne}</h1>
      </div>
      <div className="lateral-panel__modal__head-line-two">
        <h3>{headLineTwo}</h3>
      </div>
      <div>
        <Button variant="primary" size="medium" onClick={onClose}>
          {acceptButtonText}
        </Button>
      </div>
    </div>
  </Modal>
)

ErrorModal.propTypes = {
  headLineOne: PropTypes.string.isRequired,
  headLineTwo: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  acceptButtonText: PropTypes.string.isRequired,
}

export default ErrorModal
