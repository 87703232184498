import { SET_SUPERMARKET_ITEMS } from '../types'

export default (supermarketItems) => (dispatch) => {
  dispatch({
    type: SET_SUPERMARKET_ITEMS,
    payload: {
      supermarketItems,
    },
  })
}
