import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from '@walmart-web/livingdesign-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useTenant, useIsTenant } from '../../contexts/TenantContext'
import getPromotionalPopUpClient from '../../common/clients/getPromotionalPopUpClient'
import getConfig from '../../Helpers/getConfig'
import { shouldOpenPromotionalPopUp, setShouldOpenPromotionalPopUp } from './utils'
import constants from '../../common/constants'
import './index.css'

function PromotionalPopUp({ isPromoteLiderAppPopUpClosed }) {
  const isMobile = window.innerWidth < 768
  const [isOpen, setIsOpen] = useState(false)
  const [promotionalPopUp, setPromotionalPopUp] = useState({})
  const { tenant } = useTenant()
  const isTenantSod = useIsTenant(constants.tenant.SOD)
  const storageBaseUrl = `${getConfig('storageBaseUrl')}${isTenantSod ? '-sod' : ''}`
  const EVENT_TYPE_CLICK = 'click'

  const fetchPromotionalPopUp = async () => {
    const promotionalPopUpResponse = await getPromotionalPopUpClient()

    if (promotionalPopUpResponse && Object.keys(promotionalPopUpResponse).length) {
      const itemKey = `${promotionalPopUpResponse.name}_${tenant}`
      setPromotionalPopUp(promotionalPopUpResponse)
      setIsOpen(shouldOpenPromotionalPopUp(itemKey))
    }
  }

  const onClose = (eventType) => {
    const { name, buttonLink } = promotionalPopUp
    const itemKey = `${name}_${tenant}`
    setShouldOpenPromotionalPopUp(itemKey, 'false')
    setIsOpen(false)

    if (eventType === EVENT_TYPE_CLICK && buttonLink) {
      document.location.href = buttonLink
    }
  }

  useEffect(() => {
    if (!isMobile || (isMobile && isPromoteLiderAppPopUpClosed)) {
      fetchPromotionalPopUp()
    }
  }, [isMobile, isPromoteLiderAppPopUpClosed])

  return (
    <Modal title={<Fragment />} isOpen={isOpen} onClose={() => onClose('')} size="medium">
      <div data-testid="promotional-pop-up-test-id" className="promotional-popup__container">
        <div className={`promotional-popup__container__image${isMobile ? '-mobile' : ''}`}>
          <LazyLoadImage src={`${storageBaseUrl}/${promotionalPopUp.image}`} alt={promotionalPopUp.title} />
        </div>
        <div className="promotional-popup__container__head-line">
          <h1>{promotionalPopUp.title}</h1>
        </div>
        <div className="promotional-popup__container__head-line-two">
          <h3>{promotionalPopUp.content}</h3>
        </div>
        <div>
          <Button
            className="promotional-popup__container__button"
            variant="primary"
            size="medium"
            onClick={() => onClose(EVENT_TYPE_CLICK)}
          >
            <span>{promotionalPopUp.buttonText}</span>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

PromotionalPopUp.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isPromoteLiderAppPopUpClosed: PropTypes.bool.isRequired,
}

export default PromotionalPopUp
