import React from 'react'
import PropTypes from 'prop-types'
import { Modal, BottomSheet } from '@walmart-web/livingdesign-components'
import useWindowSize from '../../hooks/useWindowSize'
import AlternativeProductsContent from './components/AlternativeProductsContent'
import { useShoppingCartContext } from '../ShoppingCart/context/ShoppingCartContext'
import texts from '../../common/texts.json'
import './index.css'

const AlternativeProducts = ({ isOpen, onClose, bannerSlotInfo }) => {
  const { isMobile } = useWindowSize()
  const {
    substituteProducts: { error, recommendations },
  } = useShoppingCartContext()

  const isError = error
  const isEmpty = !recommendations || recommendations.length === 0
  const modalTitle = isError || isEmpty ? <></> : texts.ALTERNATIVES_PRODUCTS.MODAL_TITLE
  const modalSize = isError || isEmpty ? 'small' : 'medium'

  return (
    <>
      {isMobile ? (
        <BottomSheet
          className="alternative-products__bottom-sheet"
          isOpen={isOpen}
          onClose={onClose}
          title={texts.ALTERNATIVES_PRODUCTS.BOTTOM_SHEET_TITLE}
        >
          <AlternativeProductsContent isMobile={isMobile} onClose={onClose} bannerSlotInfo={bannerSlotInfo} />
        </BottomSheet>
      ) : (
        <Modal
          className="alternative-products__modal"
          isOpen={isOpen}
          onClose={onClose}
          size={modalSize}
          title={modalTitle}
          cl
        >
          <AlternativeProductsContent isMobile={isMobile} onClose={onClose} bannerSlotInfo={bannerSlotInfo} />
        </Modal>
      )}
    </>
  )
}

AlternativeProducts.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bannerSlotInfo: PropTypes.object,
}

export default AlternativeProducts
