import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Spinner } from '@walmart-web/livingdesign-components'
import PickupChip from './components/PickupChip'
import DeliveryChip from './components/DeliveryChip'

import './index.css'
import PickupForm from './components/PickupForm'
import DeliveryForm from './components/DeliveryForm'
import texts from '../../common/texts.json'
import GenericPanelTitle from '../LateralPanel/components/GenericPanelTitle'
import DeliveryAddresses from './components/DeliveryAddresses'
import { postDeliveryAddresses } from '../../common/clients/postDeliveryAddresses'
import { getSODSessionToken, deleteSODSessionToken } from '../../Helpers/sessionHelper'
import { getConfigData } from '../../Helpers'
import { useDeliveryContext } from './context/DeliveryContext'
import { useLateralPanelContext } from '../LateralPanel/context/LateralPanelContext'
import constants from '../../common/constants'
import { CONSTANTS as lateralPanelConstant } from '../../functional-components/LateralPanel/constants'

const {
  HTTP_CODES: { CODE_401 },
} = constants

export const StoreSelectorTitle = ({ onClickBack }) => (
  <GenericPanelTitle onClickBack={onClickBack}>{texts.STORE_SELECTOR.TITLE}</GenericPanelTitle>
)

StoreSelectorTitle.propTypes = {
  onClickBack: PropTypes.func,
}

StoreSelectorTitle.defaultProps = {
  onClickBack: undefined,
}

const StoreSelector = ({ onSelectStore }) => {
  const [showDelivery, setShowDelivery] = useState(true)
  const [showPickup, setShowPickup] = useState(false)

  const sodSessionToken = getSODSessionToken()
  const { deliveryAddresses, saveDeliveryAddresses } = useDeliveryContext()
  const { isEnabledHurricaneAddresses } = getConfigData()
  const { showLogin, storeSelectorTabShowChoosen, setStoreSelectorTabDefault } = useLateralPanelContext()

  const handleDeliveryClick = () => {
    setShowDelivery(true)
    setShowPickup(false)
  }

  const getDeliveryAddresses = async () => {
    const response = await postDeliveryAddresses()

    if (response?.data?.status === CODE_401) {
      deleteSODSessionToken()
      showLogin()
      return
    }

    saveDeliveryAddresses(response && response.length > 0 ? response : [])
  }

  useEffect(() => {
    if (isEnabledHurricaneAddresses) {
      if (showDelivery && sodSessionToken && deliveryAddresses === null) {
        getDeliveryAddresses()
      }
    } else {
      saveDeliveryAddresses([])
    }
  }, [showDelivery])

  useEffect(() => {
    if (storeSelectorTabShowChoosen === lateralPanelConstant.pickupMethod) {
      handlePickupClick()
      setStoreSelectorTabDefault()
    } else if (storeSelectorTabShowChoosen === lateralPanelConstant.homeDeliveryMethod) {
      handleDeliveryClick()
      setStoreSelectorTabDefault()
    }
  }, [storeSelectorTabShowChoosen])

  const handlePickupClick = () => {
    setShowDelivery(false)
    setShowPickup(true)
  }

  const chooseDeliveryForm = () => {
    if (!sodSessionToken) {
      return <DeliveryForm onSaveStore={onSelectStore} />
    }
    if (deliveryAddresses === null) {
      return (
        <div className="store-selector__spinner-container">
          <Spinner className="store-selector___spinner-container___spinner" />
        </div>
      )
    }
    if (deliveryAddresses.length === 0) {
      return <DeliveryForm onSaveStore={onSelectStore} />
    }
    return <DeliveryAddresses deliveryAddresses={deliveryAddresses} onSaveStore={onSelectStore} />
  }

  return (
    <div className="store-selector" data-testid="store-selector-test-id">
      <div className="store-selector__chip-container">
        <div
          className={
            showDelivery
              ? 'store-selector__chip store-selector__chip--selected'
              : 'store-selector__chip store-selector__chip--normal'
          }
        >
          <DeliveryChip showDelivery={showDelivery} onClick={handleDeliveryClick}>
            {texts.STORE_SELECTOR.DELIVERY}
          </DeliveryChip>
        </div>
        <div
          className={
            showPickup
              ? 'store-selector__chip store-selector__chip--selected'
              : 'store-selector__chip store-selector__chip--normal'
          }
        >
          <PickupChip showPickup={showPickup} onClick={handlePickupClick}>
            {texts.STORE_SELECTOR.PICKUP_IN_STORE}
          </PickupChip>
        </div>
      </div>

      {showPickup && <PickupForm onSaveStore={onSelectStore} />}
      {showDelivery && chooseDeliveryForm()}
    </div>
  )
}

StoreSelector.propTypes = {
  onSelectStore: PropTypes.func.isRequired,
}

export default StoreSelector
