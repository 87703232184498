import React from 'react'
import { useSelector } from 'react-redux'
import { Alert } from '@walmart-web/livingdesign-components'
import texts from '../../../../common/texts.json'
import PropTypes from 'prop-types'
import './index.css'

const TopMessages = ({ goToPayEnabled, isPurchaseAmountLimitVisible, isQuantityLimitVisible }) => {
  const { goToPayMessageConfig } = useSelector((state) => state.campaignConfigReducer)
  const goToPayMessage = goToPayMessageConfig ? goToPayMessageConfig.message : ''
  const digitLimitToPayConfig = goToPayMessageConfig?.digitLimitToPayConfig
    ? goToPayMessageConfig.digitLimitToPayConfig
    : {}
  const digitLimitToCarryConfig = goToPayMessageConfig?.digitLimitToCarryConfig
    ? goToPayMessageConfig.digitLimitToCarryConfig
    : {}

  return (
    <>
      {!goToPayEnabled && (
        <div className="top-messages__alert">
          <Alert variant="error">
            {goToPayMessage ? goToPayMessage : texts.SHOPPING_CART.TECHNICAL_PROBLEMS_DEFAULT_MESSAGE}
          </Alert>
        </div>
      )}
      {isPurchaseAmountLimitVisible && (
        <div className="top-messages__alert">
          <Alert variant="warning">
            {
              <>
                {digitLimitToPayConfig.messages?.title}
                {texts.SHOPPING_CART.SPACE}
                <strong>{digitLimitToPayConfig.messages?.subtitle}</strong>
              </>
            }
          </Alert>
        </div>
      )}
      {isQuantityLimitVisible && (
        <div className="top-messages__alert">
          <Alert variant="warning">
            <>
              {digitLimitToCarryConfig.messages?.title}
              {texts.SHOPPING_CART.SPACE}
              <strong>{digitLimitToCarryConfig.messages?.subtitle}</strong>
            </>
          </Alert>
        </div>
      )}
    </>
  )
}

TopMessages.propTypes = {
  isPurchaseAmountLimitVisible: PropTypes.bool,
  goToPayEnabled: PropTypes.bool,
  isQuantityLimitVisible: PropTypes.bool,
}

export default TopMessages
