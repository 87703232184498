import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@walmart-web/livingdesign-components'
import './index.css'

const TextFieldTrailing = ({ children, variant, size, ...rest }) => (
  <Button variant={variant} size={size} className="text-field-trailing" {...rest}>
    {children}
  </Button>
)

TextFieldTrailing.defaultProps = {
  variant: 'tertiary',
  size: 'medium',
}

TextFieldTrailing.propTypes = {
  children: PropTypes.element.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
}

export default TextFieldTrailing
