import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Category from './Category'
import { Slider, Container } from './styled'

export default class CategoriesSlider extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
  }

  render() {
    const {
      location: { pathname = '' },
      categories,
    } = this.props

    return (
      <Container>
        <Slider>
          {categories &&
            categories.map((category, index) => (
              <Category index={index} key={category.name} isSelected={pathname.includes(category.link)} {...category} />
            ))}
        </Slider>
      </Container>
    )
  }
}
