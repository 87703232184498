import React from 'react'
import texts from '../../../../common/texts.json'
import PropTypes from 'prop-types'
import Dotdotdot from 'react-dotdotdot'
import './index.css'

const MarketPlaceLabel = ({ product }) => {
  const { winningOffer = {}, isMKP } = product
  const { COMMON } = texts
  const marketPlaceSeller = isMKP && winningOffer.sellerName ? winningOffer.sellerName : undefined
  const seller = marketPlaceSeller || COMMON.SOLD_BY_WALMART_LABEL

  return (
    <Dotdotdot className="marketplace-label" clamp={2}>
      {COMMON.SOLD_BY}
      {COMMON.SPACE}
      <b className="marketplace-label__seller-name">{seller}</b>
      {product.wfsEligible && (
        <div className="marketplace-delivered_by">
          {COMMON.DELIVERED_BY_WALMART_LABEL}
          <span className="delivered_by_logo" />
        </div>
      )}
    </Dotdotdot>
  )
}

MarketPlaceLabel.propTypes = {
  product: PropTypes.object.isRequired,
}

export default MarketPlaceLabel
