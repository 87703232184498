import { SET_CATEGORY_PAGE_CONFIG } from '../types'

const initState = {}
export default (state = initState, action) => {
  switch (action.type) {
    case SET_CATEGORY_PAGE_CONFIG:
      return Object.assign({}, state, action.payload.config)
    default:
      return state
  }
}
