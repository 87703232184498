import { SET_CAMPAIGN_CONFIG } from '../types'

const initState = {
  goToPayMessageConfig: { digitLimitToCarryConfig: { active: true, limit: 8 } },
  groceryBoxSets: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_CONFIG:
      return Object.assign({}, state, action.payload.config)
    default:
      return state
  }
}
