import { getConfigData } from '.'

const urlToggleHelper = () => {
  const { newRegisterUrl, newPasswordRecoveryUrl, loginEndpointV2 } = getConfigData()

  const loginUrl = loginEndpointV2
  const passwordRecoveryUrl = newPasswordRecoveryUrl
  const registerUrl = newRegisterUrl
  return { loginUrl, passwordRecoveryUrl, registerUrl, isOriginIntegrationEnable: true }
}

export default urlToggleHelper
