import React from 'react'
import PropTypes from 'prop-types'
import { Body as BodyText, Link } from '@walmart-web/livingdesign-components'
import { Location as LocationIcon } from '@walmart-web/livingdesign-icons'
import texts from '../../../common/texts.json'
import './WalStoreSelector.css'

const WalStoreSelector = ({ walStoreValue, className }) => {
  return (
    <div className={className}>
      <div className="walstore-selector__location-icon">
        <LocationIcon size="medium" />
      </div>
      <div className="walstore-selector__store-information">
        <div className="walstore-selector__store-name">
          <BodyText as="p">{walStoreValue}</BodyText>
        </div>
        <div>
          <Link href="/catalogo/config">
            <BodyText as="p">{texts.NOT_STORE}</BodyText>
          </Link>
        </div>
      </div>
    </div>
  )
}

WalStoreSelector.propTypes = {
  walStoreValue: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export default WalStoreSelector
