import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, TextField, Spinner, Link } from '@walmart-web/livingdesign-components'
import { Eye as EyeIcon, EyeSlash as EyeSlashIcon } from '@walmart-web/livingdesign-icons'
import TrailingButton from '../../TextFieldTrailing'
import { NavLink } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import useWindowSize from '../../../hooks/useWindowSize'
import texts from '../../../common/texts.json'
import constants from '../../../common/constants'
import { logEventToDataCollection } from '../../../data-tagging'
import { FLOW_TYPES, EVENTS } from '../../../data-tagging/constants'

const InnerForm = ({ ctx }) => {
  const {
    alertToShow,
    userInput,
    userInputPass,
    errors,
    refReCaptcha,
    reCaptchaValidationCode,
    isLoading,
    isShowErrorAlert,
    handleUserInputChange,
    handleUserInputOnBlur,
    handleUserPassChange,
    handleSubmit,
    recoveryPassUrl,
    registerUrl,
    isOriginIntegrationEnable,
    analyticsOrigin,
  } = ctx

  const [isShowPass, setIsShowPass] = useState(false)
  const { isMobile } = useWindowSize()
  const screen = isMobile ? 'mobile' : ''

  useEffect(() => {
    if (isMobile) setIsShowPass(true)
  }, [isMobile])

  const toggleShowPassword = () => {
    setIsShowPass(!isShowPass)
  }

  const createAccountButton = () => {
    return (
      <Button variant="secondary" size="large" isFullWidth>
        {texts.LOGIN.CREATE_ACCOUNT_LABEL}
      </Button>
    )
  }

  const passRecoveryClick = () => {
    tagEvent(EVENTS.LOGIN.PASS_RECOVERY)
  }

  const createAccountClick = () => {
    tagEvent(EVENTS.LOGIN.SIGNUP)
  }

  const tagEvent = (eventName) => {
    logEventToDataCollection({
      origin: analyticsOrigin,
      flowType: FLOW_TYPES.LOGIN,
      eventName,
      data: {},
    })
  }

  return (
    <div className="login-form">
      <form className={`login-form__container ${screen}`} onSubmit={handleSubmit} data-testid="address-form">
        {isShowErrorAlert && (
          <div className="login-form__alert">
            <Alert variant="error"> {alertToShow} </Alert>
          </div>
        )}
        <div className="login-form__title">
          <span>{texts.LOGIN.LOGIN_TITLE}</span>
        </div>
        <div className="login-form__input">
          <label className="login-form__input-label">{texts.LOGIN.LOGIN_EMAIL_LABEL}</label>
          <TextField
            id="login-email-input"
            type="email"
            error={
              errors[constants.LOGIN_ERROR_RESPONSES.USER_DOES_NOT_EXIST] ||
              errors.EMAIL_VALIDATION_ERROR ||
              errors.EMAIL_NOT_EMPTY
            }
            textFieldProps={{ 'aria-label': 'email-input', 'data-testid': 'login-email-input' }}
            value={userInput}
            onChange={handleUserInputChange}
            onBlur={handleUserInputOnBlur}
          />
        </div>
        <div className="login-form__input">
          <label htmlFor="login-pass-input" className="login-form__input-label">
            {texts.LOGIN.LOGIN_PASSWORD_LABEL}
          </label>
          <TextField
            id="login-pass-input"
            type={isShowPass ? 'text' : 'password'}
            error={errors[constants.LOGIN_ERROR_RESPONSES.USER_IS_LOCKED] || errors.PASSWORD_NOT_EMPTY}
            textFieldProps={{ 'aria-label': 'pass-input', 'data-testid': 'login-pass-input' }}
            value={userInputPass}
            trailing={
              <TrailingButton
                onClick={toggleShowPassword}
                data-testid="trailing"
                className="login-form__login-pass-input__trailing"
              >
                {isShowPass ? (
                  <EyeIcon size="large" data-testid="show-pass" />
                ) : (
                  <EyeSlashIcon size="large" data-testid="hide-pass" />
                )}
              </TrailingButton>
            }
            onChange={handleUserPassChange}
          />
        </div>
        <div className="login-form__pass-recovery">
          {isOriginIntegrationEnable ? (
            <NavLink to={recoveryPassUrl} onClick={passRecoveryClick}>
              {texts.LOGIN.PASSWORD_RECOVERY_LABEL}
            </NavLink>
          ) : (
            <Link href={recoveryPassUrl} onClick={passRecoveryClick}>
              {texts.LOGIN.PASSWORD_RECOVERY_LABEL}
            </Link>
          )}
        </div>

        <div className="login-form__recaptcha">
          <ReCAPTCHA sitekey={reCaptchaValidationCode} size="invisible" ref={refReCaptcha} />
        </div>

        <div className="login-form__button">
          <Button isFullWidth variant="primary" size="large" type="submit" data-testid="login-button">
            {isLoading ? <Spinner className="login-form__button__spinner" /> : <span>{texts.LOGIN.LOGIN_LABEL}</span>}
          </Button>
        </div>

        <div className="login-form__create-account">
          {texts.LOGIN.CREATE_ACCOUNT_TITLE}
          <div className="login-form__create-account-button">
            {isOriginIntegrationEnable ? (
              <NavLink to={registerUrl} onClick={createAccountClick}>
                {createAccountButton()}
              </NavLink>
            ) : (
              <Link href={registerUrl} onClick={createAccountClick}>
                {createAccountButton()}
              </Link>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

InnerForm.propTypes = {
  ctx: PropTypes.object.isRequired,
}

export default InnerForm
