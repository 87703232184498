import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from '@walmart-web/livingdesign-components'
import CleanupCartWarning from '../ShoppingCart/components/CleanupCartWarning'
import { CONSTANTS, SUB_STEP } from './constants'
import constants from './../../common/constants'
import { useLateralPanelContext } from './context/LateralPanelContext'
import { useDeliveryContext } from '../StoreSelector/context/DeliveryContext'
import { useShoppingCartContext } from '../ShoppingCart/context/ShoppingCartContext.js'
import { useTenant } from '../../contexts/TenantContext.js'
import getHDStoreFromCoordinatesClient from '../../common/clients/getHDStoreFromCoordinatesClient'
import useCheckout from '../../hooks/useCheckout'
import ShoppingCart from '../ShoppingCart'
import PolygonCartWarning from '../../functional-components/ShoppingCart/components/PolygonCartWarning'
import ShoppingCartTitle from '../ShoppingCart/panel/ShoppingCartTitle'
import LoginTitle from '../Login/panel/LoginTitle'
import LoginForm from '../LoginForm'
import StoreSelectorView, { StoreSelectorTitle } from '../StoreSelector'
import DeliveryForm, { DeliveryFormTitle } from '../StoreSelector/components/DeliveryForm'
import texts from '../../common/texts.json'
import './index.css'
import Overlay from '../Overlay'
import ErrorModal from '../ErrorModal'
import { getSODSessionToken, deleteSODSessionToken } from '../../Helpers/sessionHelper'
import useShoppingCart from '../ShoppingCart/hooks/useShoppingCart'
import SoldOutProducts, { SoldOutProductsPanelTitle } from '../SoldOutProducts'
import SoldOutItemsLoading, { SoldOutLoadingTitle } from '../SoldOutProducts/components/SoldOutItemsLoading'
import SubstituteProducts from '../SubstituteProducts'
import { useSelector } from 'react-redux'

import urlToggleHelper from '../../Helpers/urlToggleHelper'
import { ORIGINS } from '../../data-tagging/constants'
import AlternativeProducts from '../AlternativeProducts'
import PromotionsValidationLoading, { PromotionsValidationTitle } from '../ShoppingCart/components/PromotionsValidation'
import { goToLandingOrCompleteAccount } from '../../Helpers/completeCustomerHelper'

const getFromObject = (obj, key) => (Object.prototype.hasOwnProperty.call(obj, key) ? obj[key] : null)

const {
  HTTP_CODES,
  delivery: { HOME_DELIVERY_TYPE },
  tenant: { SOD },
} = constants

const LateralPanel = ({ history }) => {
  const {
    isOpen,
    activeComponent,
    cartModalIsOpen,
    cartModalCoordinatesErrorIsOpen,
    isLoadingOverlay,
    isErrorModalOpen,
    subStep,
    closeCartModal,
    handleClosePanel,
    closeCartModalCoordinatesError,
    openCartModalCoordinatesError,
    closePanel,
    openCartModal,
    showSoldOutValidation,
    showSoldOutProducts,
    showStoreSelectorFromCart,
    handleStoreSelectorAction,
    showCart,
    showModalError,
    closeErrorModal,
    showLogin,
    isSubstitutionViewOpen,
    closeSubstitutionView,
    isAlternativeProductsModalOpen,
    closeAlternativeProducts,
  } = useLateralPanelContext()

  const { isOriginIntegrationEnable } = urlToggleHelper()
  const { goToCheckout, handleCheckoutCatex } = useCheckout()
  const { handleSoldOutItems } = useShoppingCart()
  const { saveStore } = useShoppingCartContext()
  const { address, dispatchType, saveAddress } = useDeliveryContext()
  const { tenant } = useTenant()
  const selectedWalstoreStore = useSelector((state) => state.selectedWalstoreStore)
  const pickupStore = useSelector((state) => state.pickupStore)

  const goToNextStep = (customer) => {
    goToLandingOrCompleteAccount(history, customer, ORIGINS.LOGIN_LATERAL_PANEL)
    closePanel()
  }

  const handleCheckoutCall = async () => {
    if (!dispatchType) {
      showStoreSelectorFromCart()
      return
    }
    try {
      await goToCheckout(isOriginIntegrationEnable)
    } catch (error) {
      if (error.response?.status === HTTP_CODES.CODE_401) {
        deleteSODSessionToken()
        showLogin()
        return
      }
      showModalError()
    }
  }

  const updateStoreForClientAddress = async () => {
    if (dispatchType === HOME_DELIVERY_TYPE && tenant === SOD) {
      const {
        location: { lat: latitude, lng: longitude },
      } = address
      const coordinates = { latitude, longitude }
      const response = await getHDStoreFromCoordinatesClient(coordinates, tenant)
      if (response.storeId === undefined) throw Error(response.message)
      const newStore = { id: response.storeId }
      const newAddress = {
        ...address,
        selectedCommune: {
          ...address.selectedCommune,
          srsId: response.communeId,
        },
        selectedState: {
          ...address.selectedState,
          srsId: response.regionId,
        },
      }
      saveStore(newStore)
      saveAddress(newAddress)
    }
  }

  const handleShoppingCartContinue = async () => {
    if (tenant === SOD) {
      return await handleSOD()
    }
    await handleCatex()
  }

  const handleSOD = async () => {
    if (!getSODSessionToken()) {
      showLogin()
      return
    }
    if (!dispatchType) {
      showStoreSelectorFromCart()
      return
    }

    showSoldOutValidation()
    updateStoreForClientAddress()
      .then(async () => {
        try {
          const shouldDisplaySoldOutView = await handleSoldOutItems()
          if (shouldDisplaySoldOutView) {
            showSoldOutProducts()
            return
          }
        } catch (e) {
          showModalError()
          return
        }
        handleCheckoutCall()
      })
      .catch((error) => {
        if (error == 'Error: REQUEST_FAILED_WITH_STATUS_CODE_404') {
          openCartModalCoordinatesError()
        } else {
          showModalError()
        }
      })
  }
  const handleCatex = async () => {
    const redirectURL = await handleCheckoutCatex({ selectedWalstoreStore, pickupStore })
    window.location.href = redirectURL
  }

  const Components = Object.freeze({
    [CONSTANTS.none]: null,
    [CONSTANTS.deliveryAddressForm]: <DeliveryForm onSaveStore={handleStoreSelectorAction} />,
    [CONSTANTS.shoppingCart]: (
      <ShoppingCart
        onClose={closePanel}
        onClickCleanUp={openCartModal}
        onContinueClick={handleShoppingCartContinue}
        onFulfillmentClick={showStoreSelectorFromCart}
        onUpdateStoreForClientAddress={updateStoreForClientAddress}
      />
    ),
    [CONSTANTS.login]: (
      <div className="lateral-panel__login-container">
        <LoginForm
          onSuccessLogin={goToNextStep}
          onClosePanel={closePanel}
          analyticsOrigin={ORIGINS.LOGIN_LATERAL_PANEL}
        />
      </div>
    ),
    [CONSTANTS.storeSelector]: <StoreSelectorView onSelectStore={handleStoreSelectorAction} />,
    [CONSTANTS.soldOutItemsValidation]: <SoldOutItemsLoading />,
    [CONSTANTS.soldOutProducts]: <SoldOutProducts onCleanSoldOut={handleSOD} showCart={showCart} />,
    [CONSTANTS.promotionsValidation]: <PromotionsValidationLoading />,
  })

  const Titles = Object.freeze({
    [CONSTANTS.none]: () => null,
    [CONSTANTS.deliveryAddressForm]: (
      <DeliveryFormTitle onClickBack={showStoreSelectorFromCart} key="deliveryFormTitle" />
    ),
    [CONSTANTS.shoppingCart]: <ShoppingCartTitle key="shoppingCartTitle" />,
    [CONSTANTS.storeSelector]: (
      <StoreSelectorTitle onClickBack={subStep === SUB_STEP.FROM_CART ? showCart : undefined} />
    ),
    [CONSTANTS.login]: () => <LoginTitle onClickBack={showCart} onClose={closePanel} key="loginTitle" />,
    [CONSTANTS.soldOutItemsValidation]: <SoldOutLoadingTitle />,
    [CONSTANTS.soldOutProducts]: <SoldOutProductsPanelTitle onClickBack={showCart} key="soldOutProductsTitle" />,
    [CONSTANTS.promotionsValidation]: <PromotionsValidationTitle />,
  })

  const getActiveComponent = () => getFromObject(Components, activeComponent)

  const getTitle = () => getFromObject(Titles, activeComponent)

  return (
    <>
      {isLoadingOverlay && <Overlay coverAll />}
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        headLineOne={texts.ERROR_MODAL.GENERIC_HEAD_LINE_ONE}
        headLineTwo={texts.ERROR_MODAL.GENERIC_HEAD_LINE_TWO}
        acceptButtonText={texts.ERROR_MODAL.GENERIC_ACCEPT_BUTTON_TEXT}
      />
      <PolygonCartWarning
        onClose={() => {
          closeCartModalCoordinatesError()
          showStoreSelectorFromCart()
        }}
        title={texts.SHOPPING_CART.POLYGON_MODAL_WARNING.TITLE}
        message={
          <span>
            <span>{texts.SHOPPING_CART.POLYGON_MODAL_WARNING.MESSAGE_1} </span>
            <strong>{texts.SHOPPING_CART.POLYGON_MODAL_WARNING.MESSAGE_2} </strong>
            <span>{texts.SHOPPING_CART.POLYGON_MODAL_WARNING.MESSAGE_3} </span>
            <span>{texts.SHOPPING_CART.POLYGON_MODAL_WARNING.MESSAGE_4} </span>
            <strong>{texts.SHOPPING_CART.POLYGON_MODAL_WARNING.MESSAGE_5} </strong>
            <span>{texts.SHOPPING_CART.POLYGON_MODAL_WARNING.MESSAGE_6}</span>
          </span>
        }
        primaryButtonText={texts.SHOPPING_CART.POLYGON_MODAL_WARNING.PICKUP_BUTTON_TEXT}
        secondaryButtonText={texts.SHOPPING_CART.POLYGON_MODAL_WARNING.HOME_DELIVERY_BUTTON_TEXT}
        isVisible={cartModalCoordinatesErrorIsOpen}
      />
      <Panel
        onClose={handleClosePanel}
        isOpen={isOpen}
        position="right"
        size="medium"
        className="lateral-panel__panel"
        title={getTitle()}
      >
        {getActiveComponent()}
      </Panel>
      <SubstituteProducts isOpen={isSubstitutionViewOpen} onCloseSubstitutionView={closeSubstitutionView} />
      <CleanupCartWarning modalIsOpen={cartModalIsOpen} onClose={closeCartModal} />
      <AlternativeProducts isOpen={isAlternativeProductsModalOpen} onClose={closeAlternativeProducts} />
    </>
  )
}

export default LateralPanel

LateralPanel.defaultProps = {
  history: {},
}

LateralPanel.propTypes = {
  history: PropTypes.object,
}
