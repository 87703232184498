import React from 'react'
import PropTypes from 'prop-types'

import { useShoppingCartContext } from '../context/ShoppingCartContext'
import QuantityBadge from '../components/QuantityBadge'
import CartIcon from './CartIcon'
import './ShoppingCartIcon.css'

const ShoppingCartIcon = ({ onClick }) => {
  const { cartQuantity } = useShoppingCartContext()

  return (
    <div className="shopping-cart-icon" role="presentation" data-testid="shopping-cart-icon" onClick={onClick}>
      <CartIcon />
      <div className="shopping-cart-icon__quantity">
        <QuantityBadge>{cartQuantity}</QuantityBadge>
      </div>
    </div>
  )
}

ShoppingCartIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ShoppingCartIcon
