import React from 'react'
import PropTypes from 'prop-types'
import { Location } from '@walmart-web/livingdesign-icons'
import HeaderBand from '../HeaderBand'

const PickupHeaderBand = ({ onClick, headingText, bodyText, leadingSize, ...rest }) => (
  <HeaderBand
    onClick={onClick}
    headingText={headingText}
    bodyText={bodyText}
    color="orange"
    leading={<Location size={leadingSize} />}
    {...rest}
  />
)

PickupHeaderBand.defaultProps = {
  leadingSize: 'small',
}

PickupHeaderBand.propTypes = {
  leadingSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
}

export default PickupHeaderBand
