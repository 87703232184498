import { getTotalItemPrice } from '../../price.utils'

const getSubTotal = (productList, isSod = false) =>
  productList.reduce((acc, productItem) => acc + getTotalItemPrice(productItem, isSod), 0)

const getTLMCSubTotal = (productList) => {
  return productList.reduce((acc, productItem) => {
    const {
      quantity,
      price: { BasePriceTLMC, BasePriceSales },
    } = productItem
    return (
      acc +
      (parseInt(BasePriceTLMC, 0) > 0 ? parseInt(BasePriceTLMC, 0) * quantity : parseInt(BasePriceSales, 0) * quantity)
    )
  }, 0)
}

const getLiderCardTotal = (_subTotal, discount) => {
  if (discount && discount.totalDiscountPercent > 0) {
    return Math.round((_subTotal * (100 - discount.totalDiscountPercent)) / 100)
  }
  return _subTotal
}

export { getSubTotal, getLiderCardTotal, getTLMCSubTotal }
