import styled from 'styled-components'

export const MaxWidthContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 0 70px;
   @media (max-width: 768px) {
    padding: 0 5px;
  }
`

export const MaxWidthContainerBanners = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 0 70px;
   @media (max-width: 768px) {
    padding: 0;
  }
`

export default MaxWidthContainer
