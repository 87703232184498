import React from 'react'
import { Divider } from '@walmart-web/livingdesign-components'
import { Location as LocationIcon } from '@walmart-web/livingdesign-icons'
import Card from '../components/Card'
import PropTypes from 'prop-types'

import './AddressSuggestions.css'

import texts from '../../../common/texts.json'

const AddressSuggestions = ({ options = [], setMenuOpen, selectOption }) => {
  const showGoogleImg = () => (
    <div className="address-suggestion__img">
      <img alt={texts.DELIVERY_FORM.GOOGLE_LOGO} src="/catalogo/images/deliveryForm/powered_by_google_on_white.png" />
    </div>
  )

  return (
    options.length > 0 && (
      <Card>
        {options.map((suggestion, idx) => (
          <div
            className="address-suggestion"
            role="button"
            key={suggestion.value.place_id}
            tabIndex={0}
            onMouseDown={() => {
              selectOption(options[idx])
              setMenuOpen(false)
            }}
            onKeyPress={() => {
              selectOption(options[idx])
              setMenuOpen(false)
            }}
          >
            <div className="address-suggestion__card">
              <div className="address-suggestion__icon">
                <LocationIcon />
              </div>

              <div className="address-suggestion__text">
                <div className="address-suggestion__main-text">{suggestion.value.structured_formatting.main_text}</div>
                <div>{suggestion.value.structured_formatting.secondary_text}</div>
              </div>
            </div>
            <div className="address-suggestion__divider">{options.length - 1 > idx && <Divider />}</div>
          </div>
        ))}
        {options.length > 0 && showGoogleImg()}
      </Card>
    )
  )
}

AddressSuggestions.propTypes = {
  options: PropTypes.array.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
}
export default AddressSuggestions
