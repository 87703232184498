import React from 'react'
import PropTypes from 'prop-types'
import { StyledText } from '@walmart-web/livingdesign-components'
import { ChevronRight } from '@walmart-web/livingdesign-icons'

import './index.css'

const HeaderBand = ({ onClick, headingText, bodyText, leading, color, iconSize, textSize, ...rest }) => (
  <div className={`header-band--${color}`} role="presentation" onClick={onClick} {...rest}>
    <StyledText leading={leading} className={`header-band__span--${color}`} size={textSize}>
      <div className={`header-band__text-container ${bodyText ? 'header-band__text-container--text-margin-top' : ''}`}>
        <span className="header-band__heading-text">{headingText}</span>
        {bodyText && <span className="header-band__body-text">{bodyText}</span>}
      </div>
    </StyledText>
    <div className="header-band__header-band-icon">
      <ChevronRight size={iconSize} />
    </div>
  </div>
)

HeaderBand.defaultProps = {
  color: 'blue',
  iconSize: 'medium',
  textSize: 'medium',
}

HeaderBand.propTypes = {
  color: PropTypes.string,
  iconSize: PropTypes.string,
  textSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
  leading: PropTypes.element.isRequired,
}

export default HeaderBand
