import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CategoryContainer, CategoryLink } from './styled'
import { sendClickPromoDataToAnalytics } from '../../../common/GoogleTagManager'

export default class Category extends Component {
    static propTypes = {
      link: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      isSelected: PropTypes.bool.isRequired,
    }

    constructor(props) {
      super(props)
      this.configData = window.__ENV__
    }

    render() {
      const {
        name, link, image, isSelected, index,
      } = this.props
      return (
        <CategoryContainer isSelected={isSelected} title={name}>
          <CategoryLink onClick={() => sendClickPromoDataToAnalytics(name, 'global_slider', index)} to={link}>
            <img
              height="26px"
              width="26px"
              alt={`category-${name}`}
              src={`${this.configData.storageBaseUrl}${image}`}
            />
            <span>{name}</span>
          </CategoryLink>
        </CategoryContainer>
      )
    }
}
