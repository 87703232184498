import React from 'react'
import PropTypes from 'prop-types'
import { Truck } from '@walmart-web/livingdesign-icons'
import HeaderBand from '../HeaderBand'

const DeliveryHeaderBand = ({ onClick, headingText, bodyText, leadingSize, ...rest }) => (
  <HeaderBand
    onClick={onClick}
    headingText={headingText}
    bodyText={bodyText}
    leading={<Truck size={leadingSize} />}
    {...rest}
  />
)

DeliveryHeaderBand.defaultProps = {
  leadingSize: 'small',
}

DeliveryHeaderBand.propTypes = {
  leadingSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
}

export default DeliveryHeaderBand
