import styled from 'styled-components'
import { Link } from 'react-router-dom'

const activeContainer = `
    border-bottom: 4px solid #ffc220;
    font-weight: bold;
    color: #0071ce;

    img{
        filter: invert(26%) sepia(53%) saturate(2500%) hue-rotate(193deg) brightness(80%) contrast(102%);
    }
`

export const CategoryContainer = styled.div`
    padding: 6px 6px;
    max-width: 105px;
    min-width: 87px;
    height: 60px;
    font-size: 12px;
    color: #414042;


    &:hover {
        ${activeContainer}
    }

    ${(props) => {
    if (props.isSelected) {
      return activeContainer
    }
    return ''
  }}
`
export const CategoryLink = styled(Link)`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: inherit;
    padding-top: 4px;

    transition: none;
`
