import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Radio, Divider, Spinner } from '@walmart-web/livingdesign-components'
import PickupTextField from './PickupTextField'

import texts from '../../../common/texts.json'
import getPickupOrS2SStoresClient from '../../../common/clients/getPickupOrS2SStoresClient'

import { normalizeText } from '../../../Helpers'

import './PickupForm.css'
import { useDeliveryContext } from '../context/DeliveryContext'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'
import constants from '../../../common/constants'
import { useTenant } from '../../../contexts/TenantContext'
import LateralPanelMessage from '../../LateralPanel/components/LateralPanelMessage'

const PickupForm = ({ onSaveStore }) => {
  const { saveStore, store: currentStore } = useShoppingCartContext()
  const { savePickup, dispatchType } = useDeliveryContext()
  const isPickupSelected = dispatchType === constants.delivery.PICKUP_TYPE

  const [pickupStores, setPickupStores] = useState([])
  const [filteredStores, setFilteredStores] = useState(isPickupSelected ? [currentStore] : [])
  const [radioIdChecked, setRadioIdChecked] = useState(isPickupSelected ? currentStore.id : '')
  const [searchVal, setSearchVal] = useState('')
  const [timerId, setTimerId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const { tenant } = useTenant()

  const MSDEBOUNCETIME = 300

  const shouldDisplayErrorMessage = () => searchVal.length > 0 && errorMsg.length > 0 && !loading && filteredStores < 1

  const displayErrorMessage = () =>
    shouldDisplayErrorMessage() ? (
      <LateralPanelMessage
        message={errorMsg}
        imgProps={{ alt: texts.PICKUP_FORM.ALT_WALMART_STORE, src: 'pickupForm/walmartStore.svg' }}
      />
    ) : null

  const populatePickupStores = async () => {
    setLoading(true)
    setPickupStores(await getPickupOrS2SStoresClient(tenant))
    setLoading(false)
  }

  useEffect(() => {
    populatePickupStores()
  }, [])

  const filterStores = async (target) => {
    if (target.length < 1) return
    setLoading(true)
    let results = []
    try {
      const stores = pickupStores.length ? pickupStores : await getPickupOrS2SStoresClient(tenant)
      if (!pickupStores.length) setPickupStores(stores)
      if (target && stores) {
        results = stores.filter(({ address, name }) => {
          const normalizedTarget = normalizeText(target)
          return normalizeText(address).includes(normalizedTarget) || normalizeText(name).includes(normalizedTarget)
        })
        setFilteredStores(results)
      } else {
        setFilteredStores([])
      }
    } catch (e) {
      setFilteredStores([])
    }

    if (results.length < 1) {
      setErrorMsg(texts.PICKUP_FORM.ERROR_NO_STORES_FOUND)
    }
    setLoading(false)
  }

  const onChangeDebounced = (event) => {
    const target = event.target.value
    setErrorMsg('')
    clearTimeout(timerId)
    setFilteredStores([])
    setSearchVal(target)
    setTimerId(setTimeout(() => filterStores(target), MSDEBOUNCETIME))
  }

  const setStoreLabelInfo = (store) => (
    <React.Fragment>
      <p className="pickup-form-store-info-name__text">{store.name}</p>
      <p className="pickup-form-store-info-address__text">
        {store.location.address}, {store.location.city}
      </p>
    </React.Fragment>
  )

  const showStore = (store, showDivider) => (
    <React.Fragment key={store.id}>
      <Radio
        UNSAFE_className="pickup-form-store-info__radio"
        checked={radioIdChecked === store.id}
        label={setStoreLabelInfo(store)}
        size="small"
        name={store.id}
        id={store.id}
        onChange={() => {
          setRadioIdChecked(store.id)
          saveStore(store)
          savePickup()
          onSaveStore()
        }}
      />
      {showDivider && <Divider />}
    </React.Fragment>
  )

  return (
    <div className="pickup-form" data-testid="pickup-form-test-id">
      <PickupTextField
        label={texts.PICKUP_FORM.FIND_A_STORE}
        placeholder={texts.PICKUP_FORM.FIND_CRITERIA}
        onChange={onChangeDebounced}
        value={searchVal}
      />
      {loading && (
        <div className="pickup-form-search__loading">
          <Spinner color="gray" size="large" a11yLabel={texts.PICKUP_FORM.LOADING_STORES} />
        </div>
      )}

      {displayErrorMessage()}

      <div className="pickup-form-search-store__list">
        {!loading &&
          filteredStores.map((store, index, arr) => {
            if (arr.length - 1 === index) {
              return showStore(store, false)
            }
            return showStore(store, true)
          })}
      </div>
    </div>
  )
}

PickupForm.propTypes = {
  onSaveStore: PropTypes.func.isRequired,
}

export default PickupForm
