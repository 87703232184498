import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ClientAPI from '../../common/ClientAPI'
import './index.css'
import LoadingLayout from '../LoadingLayout'
import LoadingProductDetail from '../LoadingProductDetail'
import constants from '../../common/constants'

const RedirectProductDetail = ({ match }) => {
  const [product, setProduct] = useState({ sku: 0 })
  const [loading, setLoading] = useState(null)

  const { selectedWalstoreStore } = useSelector((s) => s)

  const configData = window.__ENV__
  const clientAPI = new ClientAPI()
  const isWalstore = configData.isWalstore === 'true'
  let storeId = ''
  if (isWalstore && selectedWalstoreStore.id) {
    storeId = selectedWalstoreStore.id
  }

  useEffect(() => {
    const { sku } = match.params
    if (loading === null) {
      setLoading(true)
      clientAPI
        .getProductBySKU(sku, constants.APP_ID, storeId)
        .then((response) => {
          setProduct(response)
          setLoading(false)
        })
        .catch(() => {
          setProduct({ sku: '000000', slug: 'product-not-found' })
        })
    }
  }, [product, setProduct, match, loading, setLoading])

  if (product.sku !== 0) return <Redirect to={`/product/sku/${product.sku}/${product.slug}`} />
  return (
    <LoadingLayout>
      <LoadingProductDetail />
    </LoadingLayout>
  )
}

RedirectProductDetail.propTypes = {
  match: PropTypes.object.isRequired,
}

export default RedirectProductDetail
