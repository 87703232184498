import { OPEN_CART, CLOSE_CART } from '../types'

const initState = {
  isCartOpen: false,
}
export default (state = initState, action) => {
  switch (action.type) {
    case OPEN_CART:
      return { ...state, isCartOpen: true }
    case CLOSE_CART:
      return { ...state, isCartOpen: false }
    default:
      return state
  }
}
