import React from 'react'
import LoadingSkeleton from '../LoadingSkeleton'
import './index.css'

const LoadingBreadcrum = () => (
  <div className="loading-breadcrum">
    <LoadingSkeleton type="breadcrum-step" />
    <div><i className="zmdi zmdi-chevron-right" /></div>
    <LoadingSkeleton type="breadcrum-step" />
    <div><i className="zmdi zmdi-chevron-right" /></div>
    <LoadingSkeleton type="breadcrum-step" />
  </div>
)

export default LoadingBreadcrum
