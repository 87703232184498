import constants from '../../../common/constants'

export const makeAddressObject = (
  { street: streetInput, regionName, provinceName, communeName, location },
  streetNumber,
  storeInfo
) => {
  const normalizedAddressText = `${streetInput} ${streetNumber}, ${communeName}, ${regionName}`

  return {
    streetInput,
    streetNumber,
    comments: '',
    apartment: '',
    location,
    selectedState: {
      regionName,
      srsId: storeInfo.regionId,
    },
    selectedCommune: {
      communeName,
      srsId: storeInfo.communeId,
    },
    normalizedAddress: {
      direccion_formateada: normalizedAddressText,
      nombre_via_corto: streetInput,
      comuna_largo: communeName,
      provincia_largo: provinceName,
      region_largo: regionName,
      tipo_via_corto: '',
      nombre_via_largo: streetInput,
      altura: streetNumber,
    },
    type: constants.delivery.HOME_DELIVERY_TYPE,
    dispatchType: constants.delivery.HOME_DELIVERY_TYPE,
    formattedAddress: normalizedAddressText,
  }
}

export const filterAllAddressInfo = (result) => {
  const getAddressComponent = (addressType) =>
    result.address_components
      .filter((component) => component.types.includes(addressType))
      .reduce((_initial, current) => current.long_name, '')
  const number = getAddressComponent('street_number') || constants.DEFAULT_STREET_NUMBER
  const street = getAddressComponent('route')
  const regionName = getAddressComponent('administrative_area_level_1')
  const provinceName = getAddressComponent('administrative_area_level_2')
  const communeName = getAddressComponent('administrative_area_level_3') || getAddressComponent('locality')
  const { location } = result.geometry
  return { placeId: result.place_id, number, street, regionName, provinceName, communeName, location }
}
