import axios from 'axios'
import constants from '../constants'
import getConfig from '../../Helpers/getConfig'
import { getFlowId } from '../../Helpers'

const getHDStoreFromCoordinatesClient = async (coordinates, tenant) => {
  const baseURLbff = getConfig('baseURLbff')
  const clientInstance = axios.create({
    baseURL: baseURLbff,
    timeout: constants.timeout.TWENTY_SECONDS,
    headers: {
      tenant,
      Accept: 'application/json',
      'X-FlowId': getFlowId(),
    },
  })
  const { latitude, longitude } = coordinates
  const url = decodeURIComponent(`${baseURLbff}stores/coordinates?latitude=${latitude}&longitude=${longitude}`)
  try {
    const response = await clientInstance.request({
      url,
      method: 'get',
    })
    return response.data
  } catch (error) {
    const responseMessage = error?.response?.data?.message
    return {
      message: responseMessage || constants.clients.COMMUNICATION_ERROR,
    }
  }
}

export default getHDStoreFromCoordinatesClient
