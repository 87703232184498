import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, TextArea, Checkbox, Button, Divider, Spinner, Alert } from '@walmart-web/livingdesign-components'
import { NavLink } from 'react-router-dom'

import GenericPanelTitle from '../../LateralPanel/components/GenericPanelTitle'
import AddressAutocomplete from './AddressAutocomplete'
import { useDeliveryContext } from '../context/DeliveryContext'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'
import getHDStoreFromCoordinatesClient from '../../../common/clients/getHDStoreFromCoordinatesClient'
import { getSODSessionToken, deleteSODSessionToken } from '../../../Helpers/sessionHelper'
import { postAddAddress } from '../../../common/clients/postDeliveryAddresses'
import { useLateralPanelContext } from '../../LateralPanel/context/LateralPanelContext'

import { getAddressObject } from './utils'
import { formatAddress } from '../../../Helpers/addressHelper'
import texts from '../../../common/texts.json'
import constants from '../../../common/constants'
import './DeliveryForm.css'
import { useTenant } from '../../../contexts/TenantContext'
import { useDebounce } from '../../ShoppingCart/hooks/useDebounce'

const {
  HTTP_CODES: { CODE_401, CODE_404 },
  delivery: { COMMUNE_NOT_FOUND, ADDRESS_COUNT_LIMIT },
} = constants

export const DeliveryFormTitle = ({ onClickBack }) => (
  <GenericPanelTitle onClickBack={onClickBack}>{texts.DELIVERY_FORM.NEW_ADDRESS}</GenericPanelTitle>
)

DeliveryFormTitle.propTypes = {
  onClickBack: PropTypes.func.isRequired,
}

const DeliveryForm = ({ onSaveStore, onClose }) => {
  const [placeInformation, setPlaceInformation] = useState({})
  const [placeId, setPlaceId] = useState('')
  const [addressLineOneValue, setAddressLineOneValue] = useState('')
  const [addressNumber, setAddressNumber] = useState('')
  const [addressHelp, setAddressHelp] = useState('')
  const [addressReference, setAddressReference] = useState('')
  const [storeInfo, setStoreInfo] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [addressNumberErrorMessage, setAddressNumberErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isAddAddressError, setIsAddAddressError] = useState(false)
  const [addAddressErrorMessage, setAddAddressErrorMessage] = useState('')
  const [isStreetWithoutNumber, setIsStreetWithoutNumber] = useState(false)
  const [isAddressNumberDisabled, setIsAddressNumberDisabled] = useState(false)

  const { saveStore, setIsAddedAddressNumber } = useShoppingCartContext()
  const sodSessionToken = getSODSessionToken()

  const addressNumberRef = useRef()
  const addressHelperRef = useRef()
  const addressReferenceRef = useRef()
  const addressInputRef = useRef()

  const { saveAddress, saveHomeDelivery, deliveryAddresses, addDeliveryAddressesToList } = useDeliveryContext()
  const { showLogin } = useLateralPanelContext()
  const { tenant } = useTenant()
  const isNotDefaultStreetNumber = placeInformation.number !== constants.DEFAULT_STREET_NUMBER

  const hasLocationInfo = placeInformation.location !== undefined

  const debouncedAddressNumber = useDebounce(
    () => {
      setIsAddedAddressNumber(true)
      setStoreInfo(null)
      addressInputRef.current.focus()
    },
    1000,
    []
  )

  const addCustomerAddresses = async (addressObj) => {
    const response = await postAddAddress(formatAddress(addressObj))

    if (response?.status === CODE_401) {
      deleteSODSessionToken()
      showLogin()

      return null
    } else if (
      response?.status === CODE_404 &&
      response?.data?.message !== COMMUNE_NOT_FOUND &&
      response?.data?.message !== ADDRESS_COUNT_LIMIT
    ) {
      const errorMessage = texts.DELIVERY_FORM.ERRORS[response?.data?.message]
        ? texts.DELIVERY_FORM.ERRORS[response?.data?.message]
        : texts.DELIVERY_FORM.ERRORS.DEFAULT_ERROR
      setAddAddressErrorMessage(errorMessage)
      setIsAddAddressError(true)

      return null
    }

    const addressId = response?.id || `${constants.delivery.PREFIX_LOCAL_ADDRESSES_LIST}${deliveryAddresses.length + 1}`
    const newAddress = {
      ...addressObj,
      id: addressId,
    }

    saveHomeDelivery()
    saveStore({ id: storeInfo.storeId })
    saveAddress(newAddress)
    addDeliveryAddressesToList(newAddress)
    setIsSubmitLoading(false)

    return addressId
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const addressObj = getAddressObject({ placeInformation, addressNumber, storeInfo, addressHelp, addressReference })
    setIsSubmitLoading(true)

    saveHomeDelivery()
    saveStore({ id: storeInfo.storeId })

    if (sodSessionToken) {
      const addressesId = await addCustomerAddresses(addressObj)

      if (!addressesId) {
        setIsSubmitLoading(false)
        return
      }
    } else {
      saveHomeDelivery()
      saveStore({ id: storeInfo.storeId })
      saveAddress(addressObj)
    }

    onSaveStore()

    if (onClose) {
      onClose()
    }

    setIsSubmitLoading(false)
  }

  const replaceAddressLineOneValue = (replaceValue) => {
    if (addressLineOneValue) {
      const destructuredAddress = addressLineOneValue.split(',')

      setAddressLineOneValue(addressLineOneValue.replace(destructuredAddress[0], replaceValue))
    }
  }

  const onChangeAddressNumber = (event) => {
    if (addressLineOneValue && !errorMessage) {
      const changeAddressNumber = event.target.value.replace(/[^0-9]/g, '')

      setAddressNumber(changeAddressNumber)
      setAddressNumberErrorMessage(!changeAddressNumber ? texts.DELIVERY_FORM.ADDRESS_NUMBER_ERROR_MESSAGE : '')
      replaceAddressLineOneValue(`${placeInformation.street} ${changeAddressNumber}`)
      debouncedAddressNumber()
    } else {
      setIsAddressNumberDisabled(true)
    }
  }

  const onChangeAddressHelp = (event) => {
    setAddressHelp(event.target.value)
  }

  const onChangeAddressReference = (event) => {
    setAddressReference(event.target.value)
  }

  const onChangeIsStreetWithoutNumber = () => {
    if (addressLineOneValue && isNotDefaultStreetNumber) {
      setAddressNumber(!isStreetWithoutNumber ? texts.DELIVERY_FORM.ADDRESS_WITHOUT_NUMBER : placeInformation.number)
      replaceAddressLineOneValue(`${placeInformation.street} ${isStreetWithoutNumber ? placeInformation.number : ''}`)
      debouncedAddressNumber()
    } else {
      setAddressNumber(!isStreetWithoutNumber ? texts.DELIVERY_FORM.ADDRESS_WITHOUT_NUMBER : '')
      setIsAddressNumberDisabled(!isStreetWithoutNumber)
      setAddressNumberErrorMessage(
        isStreetWithoutNumber && addressLineOneValue ? texts.DELIVERY_FORM.ADDRESS_NUMBER_ERROR_MESSAGE : ''
      )
      replaceAddressLineOneValue(placeInformation.street)
    }

    setIsStreetWithoutNumber(!isStreetWithoutNumber)
  }

  const validateAddressNumber = () => {
    if (isNotDefaultStreetNumber) {
      setAddressNumber(placeInformation.number)
      setIsAddressNumberDisabled(true)
      setAddressNumberErrorMessage('')

      if (isStreetWithoutNumber) {
        setIsStreetWithoutNumber(false)
      }
    } else {
      if (!isStreetWithoutNumber) {
        setAddressNumber('')
        setIsAddressNumberDisabled(false)
        setAddressNumberErrorMessage(texts.DELIVERY_FORM.ADDRESS_NUMBER_ERROR_MESSAGE)
      }
    }
  }

  useEffect(() => {
    if (addressHelperRef != null && addressHelp) {
      addressHelperRef.current.focus()
    }
  }, [addressHelp])

  useEffect(() => {
    if (addressReferenceRef != null && addressReference) {
      addressReferenceRef.current.focus()
    }
  }, [addressReference])

  useEffect(() => {
    setErrorMessage('')
    const getStoreInfo = async () => {
      setLoading(true)
      const {
        location: { lat, lng },
      } = placeInformation
      const coordinates = { latitude: lat(), longitude: lng() }
      const homeDeliveryStore = await getHDStoreFromCoordinatesClient(coordinates, tenant)
      setLoading(false)
      if (homeDeliveryStore.message) {
        setErrorMessage(texts.DELIVERY_FORM[homeDeliveryStore.message])
      } else {
        setStoreInfo(homeDeliveryStore)
        validateAddressNumber()
      }
    }
    if (hasLocationInfo) {
      getStoreInfo()
    }
  }, [placeInformation])

  const setInvalidAddressError = () => {
    setErrorMessage(texts.DELIVERY_FORM.SUGGESTION_ERROR)
  }

  useEffect(() => {
    if (addressLineOneValue.trim() === '') {
      setPlaceId('')
      setStoreInfo(null)
    }
  }, [addressLineOneValue])

  const isSaveButtonDisabled = storeInfo === null || addressNumber === ''

  return (
    <>
      {!sodSessionToken && (
        <Alert className="delivery-form__alert" actionbuttonprops={{}} variant="info">
          {texts.DELIVERY_FORM.HAS_SAVED_ADDRESS}{' '}
          <NavLink to="/login" className="delivery-form__link">
            {texts.DELIVERY_FORM.LOGIN}
          </NavLink>
        </Alert>
      )}
      {isAddAddressError && (
        <Alert className="delivery-form__alert" actionbuttonprops={{}} variant="error">
          {addAddressErrorMessage}
        </Alert>
      )}
      <form onSubmit={onSubmit} data-testid="address-form">
        <div className="address-form-fields">
          <div className="address-form-fields__places">
            <AddressAutocomplete
              setAddressLineOneValue={setAddressLineOneValue}
              addressLineOneValue={addressLineOneValue}
              setPlaceInformation={setPlaceInformation}
              setPlaceId={setPlaceId}
              placeId={placeId}
              errorMessage={errorMessage}
              handleErrorMessage={setInvalidAddressError}
              addressInputRef={addressInputRef}
            />
            {loading && (
              <div className="address-form-fields__loading">
                <Spinner color="gray" size="small" a11yLabel={texts.DELIVERY_FORM.LOADING_STORES} />
              </div>
            )}
          </div>
          <div className="address-form-fields__address-number">
            <label
              className={`delivery-form__input-label ${
                isAddressNumberDisabled || loading ? 'delivery-form__input-label--disabled' : ''
              }`}
            >
              {texts.DELIVERY_FORM.ADDRESS_NUMBER}
            </label>
            <TextField
              type="text"
              defaultValue=""
              disabled={isAddressNumberDisabled || loading}
              placeholder={texts.DELIVERY_FORM.ADDRESS_NUMBER_EXAMPLE}
              textFieldProps={{ name: 'addressNumber', autoComplete: 'off', 'aria-label': 'addressNumber' }}
              value={addressNumber}
              error={
                addressNumberErrorMessage !== '' ? (
                  <span data-testid="address-number-error-message">{addressNumberErrorMessage}</span>
                ) : (
                  ''
                )
              }
              onChange={onChangeAddressNumber}
              ref={addressNumberRef}
            />
          </div>
          <div className="address-form__checkbox">
            <Checkbox
              checked={isStreetWithoutNumber}
              label={texts.DELIVERY_FORM.STREET_WITHOUT_NUMBER}
              onChange={onChangeIsStreetWithoutNumber}
            />
          </div>
          <div className="address-form-fields__address-help">
            <label className="delivery-form__input-label">{texts.DELIVERY_FORM.ADDRESS_HELP}</label>
            <TextField
              type="text"
              defaultValue=""
              placeholder={texts.DELIVERY_FORM.ADDRESS_EXAMPLE}
              textFieldProps={{ name: 'addressHelp', autoComplete: 'off' }}
              value={addressHelp}
              onChange={onChangeAddressHelp}
              ref={addressHelperRef}
            />
          </div>
          <div className="address-form-fields__address-reference">
            <label className="delivery-form__input-label">{texts.DELIVERY_FORM.ADDRESS_REFERENCE}</label>
            <TextArea
              placeholder={texts.DELIVERY_FORM.ADDRESS_REFERENCE_EXAMPLE}
              textAreaProps={{ name: 'addressReference', autoComplete: 'off' }}
              size="large"
              value={addressReference}
              onChange={onChangeAddressReference}
              ref={addressReferenceRef}
            />
          </div>

          <div className="address-form-fields__sticky-division">{texts.COMMON.SPACE}</div>

          <div className="address-form-fields__footer">
            <div className="address-form-fields__bottom-divider">
              <Divider />
            </div>

            <div className="address-form-fields__submit-button">
              <Button disabled={isSaveButtonDisabled} type="submit" isFullWidth size="medium" variant="primary">
                {isSubmitLoading ? (
                  <Spinner className="address-form__button__spinner" />
                ) : (
                  <span>{texts.COMMON.SAVE}</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

DeliveryForm.propTypes = {
  onSaveStore: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

DeliveryForm.defaultProps = {
  onClose: undefined,
}
export default DeliveryForm
