import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@walmart-web/livingdesign-components'
import './LateralPanelMessage.css'
import constants from '../../../common/constants'
import texts from '../../../common/texts.json'

const getImageFullPath = (imgSrc) => `${constants.STATIC_PATH}${imgSrc}`

const LateralPanelMessage = ({ message, imgProps, loading }) => (
  <div className="lateral-panel-message__container">
    <img
      alt={imgProps.alt}
      className="lateral-panel-message__image"
      src={getImageFullPath(imgProps.src)}
    />
    <span>{message}</span>
    {loading && (
      <div className="lateral-panel-message__loading">
        <Spinner color="gray" size="large" a11yLabel={texts.LATERAL_PANEL.LOADING} />
      </div>
    )}
  </div>
)

LateralPanelMessage.propTypes = {
  message: PropTypes.string.isRequired,
  imgProps: PropTypes.object.isRequired,
  loading: PropTypes.bool,
}

LateralPanelMessage.defaultProps = {
  loading: false,
}

export default LateralPanelMessage
