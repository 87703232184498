import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'
import { getRawItem, getJSONItem, setRawItem, saveItemAsJSON, removeItem } from '../../../Helpers/localStorageHelper'
import { getJSONSessionItem, saveSessionItemAsJSON } from '../../../Helpers/sessionStorageHelper'
import getFirstStoreSlots from '../../../common/clients/getFirstStoreSlotsClient'

import constants from '../../../common/constants'
import getNearestWindow from './utils'

export const DeliveryContext = createContext({
  dispatchType: null,
  setDispatchType: () => {},
  saveHomeDelivery: () => {},
  savePickup: () => {},
  address: null,
  saveAddress: () => {},
  slotText: '',
  deliveryAddresses: [],
  saveDeliveryAddresses: () => {},
  addDeliveryAddressesToList: () => {},
  clearAddresses: () => {},
})

const Provider = ({ children }) => {
  const { store } = useShoppingCartContext()

  const [dispatchType, setDispatchType] = useState(null)
  const [address, setAddress] = useState(null)
  const [deliveryAddresses, setDeliveryAddresses] = useState(null)
  const [slotText, setSlotText] = useState('')

  const handleStorageEvent = (event) => {
    if (event.key === constants.delivery.STORAGE_KEY_DISPATCH) {
      setDispatchType(event.newValue)
    } else if (event.key === constants.delivery.STORAGE_KEY_ADDRESS) {
      try {
        const newAddress = JSON.parse(event.newValue)
        setAddress(newAddress)
      } catch (e) {
        // continue regardless of error
      }
    }
  }

  const removeSelectedAddress = () => {
    const localStorageSelectedAddress = getJSONItem(constants.delivery.STORAGE_KEY_ADDRESS)
    if (localStorageSelectedAddress) {
      const isSelectedAddressGonnaBeShown =
        getRawItem(constants.delivery.STORAGE_KEY_IS_SELECTED_ADDRESS_GONNA_BE_SHOWN, 'false') === 'true'
      if (!isSelectedAddressGonnaBeShown) {
        removeItem(constants.delivery.STORAGE_KEY_ADDRESS)
        removeItem(constants.delivery.STORAGE_KEY_DISPATCH)
      }
    }
    setRawItem(constants.delivery.STORAGE_KEY_IS_SELECTED_ADDRESS_GONNA_BE_SHOWN, 'true')
  }

  useEffect(() => {
    removeSelectedAddress()
    setDispatchType(getRawItem(constants.delivery.STORAGE_KEY_DISPATCH))
    setAddress(getJSONItem(constants.delivery.STORAGE_KEY_ADDRESS))

    window.addEventListener(constants.STORAGE_EVENT, handleStorageEvent)

    return () => {
      window.removeEventListener(constants.STORAGE_EVENT, handleStorageEvent)
    }
  }, [])

  const saveHomeDelivery = () => {
    setDispatchType(constants.delivery.HOME_DELIVERY_TYPE)
    setRawItem(constants.delivery.STORAGE_KEY_DISPATCH, constants.delivery.HOME_DELIVERY_TYPE)
  }

  const savePickup = () => {
    setDispatchType(constants.delivery.PICKUP_TYPE)
    setRawItem(constants.delivery.STORAGE_KEY_DISPATCH, constants.delivery.PICKUP_TYPE)
  }

  const saveAddress = (newAddress) => {
    setAddress(newAddress)
    saveItemAsJSON(constants.delivery.STORAGE_KEY_ADDRESS, newAddress)
  }

  const saveDeliveryAddresses = (userDeliveryAddresses) => {
    setDeliveryAddresses(userDeliveryAddresses)
  }

  const addDeliveryAddressesToList = (newAddress) => {
    const deliveryAddressesAux = deliveryAddresses || []
    deliveryAddressesAux.push(newAddress)
    setDeliveryAddresses(deliveryAddressesAux)
  }

  const getStoreSlot = async () => {
    const storageSlotInfo = getJSONSessionItem(constants.delivery.STORAGE_SLOT_INFO)

    if (
      storageSlotInfo &&
      store &&
      storageSlotInfo.storeId === store.id &&
      storageSlotInfo.dispatchType === dispatchType
    ) {
      return storageSlotInfo.slot
    }

    const storeSlot = await getFirstStoreSlots(dispatchType, store, address)

    if (!storeSlot || !storeSlot.slot) {
      return null
    }

    const { slot } = storeSlot

    saveSessionItemAsJSON(constants.delivery.STORAGE_SLOT_INFO, {
      storeId: store.id,
      dispatchType,
      slot,
    })

    return slot
  }

  const setSlotTextForDispatchType = async () => {
    const storeSlot = await getStoreSlot()
    setSlotText(storeSlot ? getNearestWindow(storeSlot) : '')
  }

  useEffect(() => {
    if (dispatchType && store.id) {
      setSlotTextForDispatchType()
    } else {
      setSlotText('')
    }
  }, [dispatchType, store])

  const clearAddresses = () => {
    setAddress(null)
    removeItem(constants.delivery.STORAGE_KEY_ADDRESS)
  }

  return (
    <DeliveryContext.Provider
      value={{
        dispatchType,
        setDispatchType,
        saveHomeDelivery,
        savePickup,
        address,
        saveAddress,
        slotText,
        deliveryAddresses,
        saveDeliveryAddresses,
        addDeliveryAddressesToList,
        clearAddresses,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useDeliveryContext = () => useContext(DeliveryContext)

export default Provider
