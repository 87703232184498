import React from 'react'
import PropTypes from 'prop-types'
import texts from '../../../common/texts.json'

import './HeaderInfoPDC.css'

const HeaderInfoPDC = ({ isWalstore, showCustomerServiceInfo }) => {
  const catexOrWalstore = isWalstore ? 'WALSTORE' : 'CATEX'
  const walStorePhone = texts.CUSTOMER_SERVICE.SUPPORT_PHONE_WALSTORE
  const catexPhone = texts.CUSTOMER_SERVICE.SUPPORT_PHONE_CATEX
  const href = isWalstore
    ? `https://api.whatsapp.com/send?phone=${walStorePhone}&text=Hola!%20Tengo%20una%20consulta`
    : `tel:${catexPhone.replace(/ /g, '')}`

  return (
    <>
      {showCustomerServiceInfo && (
        <div role="presentation" id="header" className="header-info">
          <div className="header-info__wrapper header-info__expanded">
            <span>
              {texts.CUSTOMER_SERVICE[`TITLE_${catexOrWalstore}`]}
              <a href={href}>{isWalstore ? `+${walStorePhone}` : catexPhone}</a>
              {texts.CUSTOMER_SERVICE[`SUPPORT_HOURS_${catexOrWalstore}`]}
            </span>
            {!isWalstore && (
              <div className="header-info__stores-hours">
                <a href="https://www.lider.cl/tiendas">
                  <img
                    height="13px"
                    alt="{texts.CUSTOMER_SERVICE.STORES_AND_HOURS}"
                    src="/catalogo/images/locales_icon.svg"
                  />
                  <span>{texts.CUSTOMER_SERVICE.STORES_AND_HOURS}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

HeaderInfoPDC.propTypes = {
  isWalstore: PropTypes.bool.isRequired,
  showCustomerServiceInfo: PropTypes.bool,
}

HeaderInfoPDC.defaultProps = {
  showCustomerServiceInfo: true,
}

export default HeaderInfoPDC
