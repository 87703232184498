import { UPDATE_PROMOTIONS } from '../types'

const initState = {}
export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_PROMOTIONS:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}
