import ClientAPI from '../ClientAPI'
import constants from '../constants'

const createOrderV1Url = 'orders'
const createOrderV2Url = 'orders/v2'
const createOrderV3Url = 'orders/v3'

const createRequest = ({ walmartId, email, products, storeId, dispatchType, address }) => {
  const requestBody = {
    products,
    storeId,
    walmartId,
    email,
    dispatchType,
  }
  return dispatchType === constants.delivery.PICKUP_TYPE ? requestBody : { ...requestBody, address }
}

const createOrderV1 = (createOrderParams) => {
  const clientAPI = new ClientAPI()
  const { orderToCreate, selectedWalstoreStore, isWalstore } = createOrderParams
  const request = orderToCreate

  if (isWalstore) {
    request.creationStore = {
      id: selectedWalstoreStore.id,
      format: selectedWalstoreStore.format,
      name: selectedWalstoreStore.name,
    }
    request.format = selectedWalstoreStore.format
  }

  return clientAPI.getCheckoutURL(createOrderV1Url, request)
}

const createOrderV2 = (createOrderParams) => {
  const clientAPI = new ClientAPI()
  const request = createRequest(createOrderParams)
  return clientAPI.getCheckoutURL(createOrderV2Url, request)
}

const createOrderV3 = (createOrderParams) => {
  const clientAPI = new ClientAPI()
  const request = createRequest(createOrderParams)
  return clientAPI.getCheckoutURL(createOrderV3Url, request)
}

export { createOrderV1, createOrderV2, createOrderV3 }
