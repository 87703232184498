import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Button, Spinner } from '@walmart-web/livingdesign-components'
import { Close as CloseIcon, Menu as MenuIcon } from '@walmart-web/livingdesign-icons'
import ShoppingCartHeaderIcon from '../../LateralPanel/header/ShoppingCartHeaderIcon'
import GlobalSearch from '../../GlobalSearch'
import StoreSelectorHeaderButton from '../../LateralPanel/header/StoreSelectorHeaderButton'
import LoginMenu from './LoginMenu'
import LiderLogo from './LiderLogo'
import MobileAlertInfoComponent from '../../../functional-components/MobileAlertInfoComponent'
import { useSelector } from 'react-redux'
import WalStoreSelector from './WalStoreSelector'
import './MobileHeader.css'

const MobileHeader = ({
  onMenuButtonToggle,
  onMenuClose,
  isMenuOpen,
  iconSize,
  isSod,
  isWalstore,
  walStoreValue,
  categoryMenuSpinner,
}) => {
  const { headerAlertMessageConfig = {} } = useSelector((state) => state.campaignConfigReducer)
  const { enabled: shouldRenderAlertInfoComponent } = headerAlertMessageConfig
  const handleLeadingMenu = () => {
    if (categoryMenuSpinner) {
      return <Spinner color="white" size="small" />
    }

    return isMenuOpen ? <CloseIcon size={iconSize} /> : <MenuIcon size={iconSize} />
  }

  return (
    <Fragment>
      {shouldRenderAlertInfoComponent && (
        <MobileAlertInfoComponent headerAlertMessageConfig={headerAlertMessageConfig} />
      )}
      <header className="header--mobile" data-testid="mobile-header-test-id">
        <div className="header__first-lvl--mobile">
          <LiderLogo isSod={isSod} isWalstore={isWalstore} className={isWalstore ? 'header__walstore-logo' : ''} />
          {isWalstore && (
            <WalStoreSelector walStoreValue={walStoreValue} className="walstore-selector__container--mobile" />
          )}
          <div className="d-flex header__separator-right-content" data-testid="mobile-header-cart-test-id">
            {isSod && <LoginMenu />}
            <ShoppingCartHeaderIcon />
          </div>
          <div className="header__menu--mobile" role="presentation">
            <Button
              variant="primary"
              size="medium"
              data-testid="mobile-header-menu-test-id"
              leading={handleLeadingMenu()}
              onClick={onMenuButtonToggle}
            />
          </div>
        </div>
        <div className="header__second-lvl--mobile">
          <div
            className="header__search--mobile"
            role="presentation"
            data-testid="mobile-header-search-test-id"
            onClick={onMenuClose}
          >
            <GlobalSearch />
          </div>
        </div>
      </header>
      {isSod && <StoreSelectorHeaderButton />}
    </Fragment>
  )
}

MobileHeader.defaultProps = {
  isMenuOpen: false,
  iconSize: 'large',
  walStoreValue: '',
  categoryMenuSpinner: false,
}

MobileHeader.propTypes = {
  onMenuButtonToggle: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool,
  iconSize: PropTypes.oneOf(['large', 'medium', 'small']),
  isSod: PropTypes.bool.isRequired,
  isWalstore: PropTypes.bool.isRequired,
  walStoreValue: PropTypes.string,
  categoryMenuSpinner: PropTypes.bool,
}

export default MobileHeader
