import React from 'react'
import PropTypes from 'prop-types'
import texts from '../../../../common/texts.json'
import { validateSVGFile } from '../../../../Helpers/index'
import { Tag } from '@walmart-web/livingdesign-components'
import {
  FirstLevelPrimaryIcon,
  FirstLevelSecondaryIcon,
  FirstLevelSection,
  FirstLevelTitle,
  CampaignTagIcon,
} from '../../styled'
import './index.css'

const configData = window.__ENV__ || { storageBaseUrl: '' }
const FirstLevelCategories = ({ ...props }) => {
  const {
    activeCategory1,
    categories,
    showSecondLevelSection,
    showCategories,
    showPreselectedCategory,
    currentCategory,
  } = props

  if (showPreselectedCategory && activeCategory1 && !showSecondLevelSection) {
    const current = categories.find((category) => category.label === activeCategory1)
    setTimeout(() => {
      showCategories(current)
    }, 500)
  }

  return categories.map((category, index) => {
    const enhancedCategory = category
    const active =
      currentCategory && currentCategory.label
        ? category.label === currentCategory.label
        : activeCategory1 === category.label
    const applyFilter = (category.campaignTag || category.special) && category.indicatorColor !== '#FFF'
    enhancedCategory.icon = category.icon || `${configData.storageBaseUrl}/menuIcons/categoria_default.svg`
    enhancedCategory.openLevelTwoIcon =
      category?.openLevelTwoIcon || `${configData.storageBaseUrl}/menuIcons/abrir_2do_nivel.svg`
    enhancedCategory.isSVGIcon = validateSVGFile(enhancedCategory.icon)
    enhancedCategory.active = active
    enhancedCategory.applyFilter = applyFilter
    const { isNewTag } = category
    if (!category.hidden) {
      return (
        <FirstLevelSection
          onClick={() => {
            showCategories(category)
          }}
          onKeyPress={() => {
            showCategories(category)
          }}
          onMouseEnter={() => showCategories(category)}
          key={`category-${index}`}
          {...enhancedCategory}
          showSecondLevelSection={showSecondLevelSection}
        >
          <FirstLevelPrimaryIcon alt="category-primary-icon" src={enhancedCategory.icon} />
          {category.campaignTag && <CampaignTagIcon alt="category-icon" src={category.campaignTag} />}
          {!category.campaignTag && (
            <FirstLevelTitle
              className={isNewTag ? 'first-level-categories__first-level-title--new-tag' : ''}
              {...enhancedCategory}
            >
              {category.label}
            </FirstLevelTitle>
          )}
          {isNewTag && (
            <Tag variant="primary" color="spark" UNSAFE_className="first-level-categories__new-tag">
              {texts.CATEGORY_MENU.NEW_TAG}
            </Tag>
          )}
          <FirstLevelSecondaryIcon alt="category-secondary-icon" src={enhancedCategory.openLevelTwoIcon} />
        </FirstLevelSection>
      )
    }
    return null
  })
}

export default FirstLevelCategories

FirstLevelCategories.defaultProps = {
  activeCategory1: '',
}

FirstLevelCategories.propTypes = {
  activeCategory1: PropTypes.string,
  categories: PropTypes.array.isRequired,
  showPreselectedCategory: PropTypes.bool.isRequired,
  showSecondLevelSection: PropTypes.bool.isRequired,
  showCategories: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}
