import axios from 'axios'
import getConfig from '../../Helpers/getConfig'
import constants from '../constants'
import { getTenant, getFlowId } from '../../Helpers'

const handleRequest = async (requestConfig) => {
  const baseURLbff = getConfig('baseURLbff')
  const tenant = getTenant()

  const clientInstance = axios.create({
    baseURL: baseURLbff,
    timeout: constants.timeout.TWENTY_SECONDS,
  })

  try {
    const response = await clientInstance.request({
      ...requestConfig,
      withCredentials: true,
      method: 'post',
      headers: {
        tenant,
        'X-FlowId': getFlowId(),
      },
    })

    return response.data
  } catch (error) {
    return error.response ? error.response : error
  }
}

const postDeliveryAddresses = async () =>
  await handleRequest({
    url: 'addresses',
  })

const postAddAddress = async (addressInfo) =>
  await handleRequest({
    url: 'addresses/add',
    data: addressInfo,
  })

export { postDeliveryAddresses, postAddAddress }
