import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './Loading.css'
import LoadingBreadcrum from '../../LoadingBreadcrum'

const Loading = ({ onlyCarousel, isSearchPage }) => (
  <div className="app-container loading-plp" data-onlycarousel={onlyCarousel.toString()}>
    <div className="rct-page_cat">
      {!isSearchPage && <div className="loading-plp__header-banner loading-plp__loading" />}
      {!onlyCarousel && (
        <Fragment>
          {!isSearchPage && <LoadingBreadcrum />}
          <div className="d-flex mb-60 loading-plp__flex">
            <div className="col-lg-3 col-md-4 d-none d-md-block col-padding-12">
              <div className="loading-plp__category">
                <div className="loading-plp__loading loading-plp-title" />
                {[0, 1, 2, 3].map((e) => (
                  <div key={e} className="loading-plp__loading loading-plp-text" />
                ))}
              </div>
              <div className="loading-plp__category">
                <div className="loading-plp__loading loading-plp-title" />
                {[0, 1, 2, 3].map((e) => (
                  <div key={e} className="loading-plp__loading loading-plp-text" />
                ))}
              </div>
            </div>
            <div className="col-lg-9 col-padding-12">
              <div className="loading-plp__product-filter">
                {[0, 1, 2, 3].map((e) => (
                  <div key={e} className="loading-plp__loading loading-plp-filter" />
                ))}
              </div>
              <div className="loading-plp__product-grid">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((e) => (
                  <div key={e}>
                    <div className="loading-plp__loading loading-plp-product-image" />
                    <div className="loading-plp__loading loading-plp-title" />
                    {[0, 1].map((e) => (
                      <div key={e} className="loading-plp__loading loading-plp-text" />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  </div>
)

Loading.defaultProps = {
  onlyCarousel: false,
  isSearchPage: false,
}

Loading.propTypes = {
  isSearchPage: PropTypes.bool,
  onlyCarousel: PropTypes.bool,
}

export default Loading
