import React from 'react'
import PropTypes from 'prop-types'

import Price from './Price'
import texts from '../../../common/texts.json'
import SavingText from './SavingText'

const WlmPrice = ({
  price,
  packQuantity,
  discount,
  shouldDisplayText,
  priceClass,
  discountClass,
  textClass,
  children,
}) => {
  const getChildren = () => {
    if (children) {
      return children
    }
    return (
      <>
        {discount > 0 && <span className={discountClass}>{`${discount}%`}</span>}
        {shouldDisplayText && <SavingText className={textClass}>{texts.PRODUCT_PRICES.WLM_PRICE_TEXT}</SavingText>}
      </>
    )
  }
  return (
    <div className="d-flex align-items-center flex-wrap">
      <Price price={price} quantity={packQuantity} className={priceClass} />
      {getChildren()}
    </div>
  )
}

WlmPrice.propTypes = {
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
  shouldDisplayText: PropTypes.bool,
  priceClass: PropTypes.string,
  discountClass: PropTypes.string,
  textClass: PropTypes.string,
  packQuantity: PropTypes.number,
  children: PropTypes.element,
}

WlmPrice.defaultProps = {
  discount: 0,
  shouldDisplayText: false,
  priceClass: 'pdp-mobile-sales-price',
  discountClass: 'pdp-mobile-discount-percentage-card',
  textClass: 'pdp-desktop-pay',
  packQuantity: undefined,
}

export default WlmPrice
