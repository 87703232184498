import styled from 'styled-components'

export const Slider = styled.div`
  display: flex;
  justify-content: center;
  min-width: max-content;
`

export const Container = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 3;
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 0px 9px 0px #b5b5b5;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 911px) {
    top: 87px;
    bottom: unset;
    box-shadow: 0 9px 11px -10px #969696;
  }

  @media (min-width: 911px) {
    top: 87px;
    bottom: unset;
    box-shadow: 0 9px 11px -10px #969696;
  }
`
