import React from 'react'
import PropTypes from 'prop-types'
import { Truck } from '@walmart-web/livingdesign-icons'
import HeaderButton from '../HeaderButton'

const DeliveryHeaderButton = ({ leadingSize, onClick, headingText, bodyText, ...rest }) => (
  <HeaderButton
    leading={<Truck size={leadingSize} />}
    onClick={onClick}
    headingText={headingText}
    bodyText={bodyText}
    {...rest}
  />
)

DeliveryHeaderButton.defaultProps = {
  leadingSize: 'medium',
}

DeliveryHeaderButton.propTypes = {
  leadingSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
}

export default DeliveryHeaderButton
