import React from 'react'
import { useCampaignStyleConfig } from '../../../contexts/CampaignStyleConfigContext'
import { getHeaderCampaignStyle } from '../../../Helpers/getCampaignStyleConfig'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import texts from '../../../common/texts.json'

const LiderLogo = ({ isSod, isWalstore, className }) => {
  const { campaignStyle } = useCampaignStyleConfig()
  const { logoSOD, logoCATEX, logoPDC } = getHeaderCampaignStyle(campaignStyle)
  let logoLider = logoSOD

  if (!isSod) {
    logoLider = isWalstore ? logoPDC : logoCATEX
  }

  return (
    <Link to="/">
      <img alt={texts.COMMON.ALT_WALMART_LOGO} src={logoLider} className={className} />
    </Link>
  )
}

LiderLogo.propTypes = {
  isSod: PropTypes.bool.isRequired,
  isWalstore: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

LiderLogo.defaultProps = {
  className: '',
}

export default LiderLogo
